import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, is_disabled, ...rest }) => {
    const navigate = useNavigate();

    if (is_disabled === 'Y') {
        // If user needs to change password, redirect to /change_password
        navigate('/change_password');
        return null;  // Don't render anything while navigating
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;