import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Travel_Desk.css';
import useAuth from './useAuth';
import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Travel_Desk = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [EmpCode, setEmpCode] = useState('100076'); // Default Emp Code
    const [items, setItems] = useState([]); // Cities
    const [rows, setRows] = useState([{ id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade: '', mobile: '' }]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [empCodes, setEmpCodes] = useState([]);
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [currentInput, setCurrentInput] = useState('');
    useAuth();
    const [formData, setFormData] = useState({
        
        city: '',
        
        booking_date: '',
      
        amount: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, type, files } = e.target;
        if (type === 'file') {
            setFormData(prevState => ({
                ...prevState,
                [name]: files[0], // Get the file object directly
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: e.target.value,
            }));
        }
    };

    const handleRowChange = (id, e) => {
        const { name, value } = e.target;
        setRows(prevRows =>
            prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
        );
        if (name === 'emp_code') {
            setCurrentRowId(id);
            setCurrentInput(value);
            setFilteredCodes(empCodes.filter(code => code.includes(value)));
        }
    };

    const handleSelectCode = (code) => {
        setRows(prevRows =>
            prevRows.map(row => (row.id === currentRowId ? { ...row, emp_code: code } : row))
        );
        setCurrentInput(code);
        setFilteredCodes([]);
        fetchEmployeeDetails(code);
    };

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade: '', mobile: '' }]);
    };

    const handleRemoveRow = (id) => {
        setRows(prevRows => prevRows.filter(row => row.id !== id));
    };

    const validateFormData = () => {
        const {  } = formData;
      
        return true;
    };

    

    const fetchCitiesData = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${BASE_URL}api/cities`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setItems(result.data);
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch cities data');
        }
    };

    const fetchEmployeeDetails = async (empCode) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${BASE_URL}api/users?emp_code=${empCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setRows(prevRows => {
                    const updatedRows = [...prevRows];
                    const rowIndex = updatedRows.findIndex(row => row.emp_code === empCode);
                    if (rowIndex !== -1) {
                        updatedRows[rowIndex].comp_name = result.user.comp_name;
                        updatedRows[rowIndex].dept_name = result.user.dept_name;
                        updatedRows[rowIndex].designation_name = result.user.designation_name;
                        updatedRows[rowIndex].grade = result.user.grade;
                        updatedRows[rowIndex].mobile = result.user.mobile;
                    }
                    return updatedRows;
                });
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch employee details');
        }
    };

    useEffect(() => {
        fetchCitiesData();
    }, []);

    useEffect(() => {
        if (EmpCode) {
            fetchEmployeeDetails(EmpCode);
        }
    }, [EmpCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) {
            return; // Prevent multiple submissions
        }
        setIsSubmitting(true);

        // Simulate form submission (e.g., API call or any async operation)
        setTimeout(() => {
            // Reset isSubmitting after the submission is complete
            setIsSubmitting(false);
            
        }, 2000); // Simulating a 2-second delay for the submission process
        if (validateFormData()) {
            // Create a new FormData object
            const formDataToSend = new FormData();

            // Append all form fields to FormData
            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }

            // Append rows data with incrementing keys
            rows.forEach((row, index) => {
                formDataToSend.append(`rows[${index}][emp_code]`, row.emp_code);
                formDataToSend.append(`rows[${index}][comp_name]`, row.comp_name);
                formDataToSend.append(`rows[${index}][dept_name]`, row.dept_name);
                formDataToSend.append(`rows[${index}][designation_name]`, row.designation_name);
                formDataToSend.append(`rows[${index}][grade]`, row.grade);
                formDataToSend.append(`rows[${index}][mobile]`, row.mobile);
            });

            // Append the receipt file
            const receiptFileInput = document.querySelector('input[name="receipt"]');
            

            console.log('Form submitted:', formDataToSend);

            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${BASE_URL}api/postHotelInfo`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Do not set 'Content-Type' for FormData
                    },
                    body: formDataToSend, // Directly use FormData as body
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log('Response from server:', result);
                navigate("/manage_hotel_info");

                setShowPopup(true);
                // Handle successful response (e.g., show a success message)
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form: ' + error.message);
            }
        }
    };


    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Hotel Booking Info</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Hotel Booking Info</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} encType="multipart/form-data" method="POST">
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="hotel_name">Hotel Name <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="hotel_name"
                                                        value={formData.hotel_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="city">City <span style={{ color: 'red' }}>*</span></label>
                                                    <select
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    >
                                                        <option value="">Select a city</option>
                                                        {items.map(city => (
                                                            <option key={city.id} value={city.city_name}>
                                                                {city.city_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="check_in">Check in <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="date"
                                                        name="check_in"
                                                        value={formData.check_in}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="check_out">Check out<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="date"
                                                        name="check_out"
                                                        value={formData.check_out}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                            <button type="button" onClick={handleAddRow} className="btn btn-primary">+</button>
                                            <table className="expense-table">
                                                <thead>
                                                    <tr className="header-row">
                                                        <th>Emp Code<span style={{ color: 'red' }}>*</span></th>
                                                        <th>Name</th>
                                                        <th>Dept</th>
                                                        <th>Designation</th>
                                                        <th>Grade</th>
                                                        <th>Mobile</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rows.map(row => (
                                                        <tr key={row.id}>
                                                            <td data-label="Emp Code">
                                                                <input
                                                                    type="text"
                                                                    name="emp_code"
                                                                    value={row.emp_code}
                                                                    onChange={(e) => {
                                                                        handleRowChange(row.id, e);
                                                                        setEmpCode(e.target.value); // Update EmpCode state to trigger API fetch
                                                                    }}
                                                                    className="form-control input-font"
                                                                    placeholder="Emp Code"
                                                                    required
                                                                />
                                                                {filteredCodes.length > 0 && (
                                                                    <ul className="suggestions">
                                                                        {filteredCodes.map((code) => (
                                                                            <li key={code} onClick={() => handleSelectCode(code)}>
                                                                                {code}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </td>
                                                            <td data-label="Name">
                                                                <input
                                                                    type="text"
                                                                    name="comp_name"
                                                                    value={row.comp_name}
                                                                    onChange={(e) => handleRowChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="Name"
                                                                    readOnly
                                                                />
                                                            </td>
                                                            <td data-label="Dept">
                                                                <input
                                                                    type="text"
                                                                    name="dept_name"
                                                                    value={row.dept_name}
                                                                    onChange={(e) => handleRowChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="Dept"
                                                                    readOnly
                                                                />
                                                            </td>
                                                            <td data-label="Designation">
                                                                <input
                                                                    type="text"
                                                                    name="designation_name"
                                                                    value={row.designation_name}
                                                                    onChange={(e) => handleRowChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="Designation"
                                                                    readOnly
                                                                />
                                                            </td>
                                                            <td data-label="Grade">
                                                                <input
                                                                    type="text"
                                                                    name="grade"
                                                                    value={row.grade}
                                                                    onChange={(e) => handleRowChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="Grade"
                                                                    readOnly
                                                                />
                                                            </td>
                                                            <td data-label="Mobile">
                                                                <input
                                                                    type="text"
                                                                    name="mobile"
                                                                    value={row.mobile}
                                                                    onChange={(e) => handleRowChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="Mobile"
                                                                    readOnly
                                                                />
                                                            </td>
                                                            <td data-label="Actions">
                                                                <button type="button" onClick={() => handleRemoveRow(row.id)} className="btn btn-danger">×</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group" style={{ marginTop: '20px' }}>
                                                    <label htmlFor="amount">Amount<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="number"
                                                        name="amount"
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group" style={{ marginTop: '20px' }}>
                                                    <label htmlFor="receipt">Receipt<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="file"
                                                        name="receipt"
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group" style={{ marginTop: '20px' }}>
                                                    <label htmlFor="booking_date">Booking date<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="date"
                                                        name="booking_date"
                                                        value={formData.booking_date}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer" style={{ textAlign: 'center' }}>
                                        <button type="submit" className="btn btn-success pull-right">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
                <ToastContainer />
            </div>
        </div>
    );
};

export default Travel_Desk;
