import React, { useEffect } from 'react';

const ErrorPage = () => {


  return (
    <div className="container bootstrap snippets bootdey" style={styles.container}>
      <div className="row" style={styles.row}>
        <div className="col-md-12">
          <div className="pull-right" style={{ marginTop: '10px' }}>
            <div className="col-md-10 col-md-offset-1 pull-right" style={styles.content}>
              <img className="img-error" src="https://st3.depositphotos.com/8615356/13839/v/450/depositphotos_138392822-stock-illustration-page-not-found-error-404.jpg" alt="Error" />
              <br/>
              <h2>404 Not Found</h2>
              <br/>
              <p>Sorry, an error has occurred. Requested page not found!</p>
              <br/>
              <div className="error-actions">
                <a href="/" className="btn btn-primary btn-lg">
                  <span className="glyphicon glyphicon-arrow-left"></span>
                  Back Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
};

export default ErrorPage;
