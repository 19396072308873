import React from 'react';
 // Import CSS specific to Footer

const Footer = () => {
    return (
        <footer className="main-footer">
                    <strong>
					© 2024 All rights reserved VDC</strong> 
                    <span style={{float:'right'}}>For Support Drop an email to it@vijayadiagnostic.in</span> 
        </footer>
    );
};

export default Footer;