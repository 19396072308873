import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import useAuth from './useAuth';
import './EditAdvanceForm.css';
//import { BASE_URL } from "./Constants.js";
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EditAdvanceForm = () => {
    const { id } = useParams();
    const [showCustomInput, setShowCustomInput] = useState(false);
    const [RptMgr, setRptMngr] = useState('');
    const [purposes, setPurposes] = useState([]);
    const [ReportManagerName, setReportManagerName] = useState('');
    const [AccountManagerName, setAccountManagerName] = useState('');
    useAuth();
    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        // Get hours and minutes
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? String(hours).padStart(2, '0') : '12'; // Handle 0 as 12

        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    };
    const [EmpCode, setEmpCode] = useState('');
    const [items, setItems] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        comp_name: '',
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        bank_details: '',
        purpose: '',
        mode_of_travel: '',
        travel_advance: '',
        travel_advance_amount: '',
        days: '',
        city: '',
        rpt_mgr_status: '',
        manager_approved_amount: '',
        account_status: '',
        accounts_approved_amount: '',
        accounts_remarks: '',
        manager_remarks: '',
        utr: '',
        utr_date: '',
        created_on: '',
        manager_approved_date: '',
        accounts_approved_date: '',
        accounts_manager: '',
        hotel_tickets: '',
        travel_tickets: '',
        mobile: '',
        advance_flag: '',
        bank_acnum: '',
        bank_name: '',
        ifsc_code: '',
    });
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();

    const validateFormData = () => {
        const { comp_name, emp_code, grade } = formData;
        if (!comp_name | !emp_code | !grade) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;



        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value
        }));
        if (name === 'purpose') {
            setShowCustomInput(value === 'Others');
        }

        if (name === 'status_select') {
            setFormData(prevData => {
                let newData = { ...prevData, [name]: value };

                // Based on the selected request type, set the corresponding status to 'Pending'
                if (value === 'request_for_manager') {
                    newData.rpt_mgr_status = 'Pending';
                } else if (value === 'request_for_accounts_mngr') {
                    newData.account_status = 'Pending';
                }

                return newData;
            });
        }

        // Handle other field changes (rpt_mgr_status, audit_status, account_status, etc.)
        else if (name === 'rpt_mgr_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } else if (name === 'account_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const validateDays = () => {
        const { days } = formData;
        console.log("Days input:", days); // Log the input value

        const regex = /^(1|1\.5|2|2\.5|3|3\.5|4|4\.5|5|5\.5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10)$/; // Updated regex
        if (days && !regex.test(days)) {
            toast.error('Please enter a valid number (1, 1.5, 2, 2.5, etc.)');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!validateFormData()) return;

        if (!validateDays()) {
            return; // Prevent submission if validation fails
        }
        if (/^[CDEF]\d*$/.test(formData.grade) && formData.hotel_tickets === 'TravelDesk' && formData.travel_tickets === 'TravelDesk') {
            toast.error('As Per Your Band, Cannot Submit if both are TravelDesk.');
            return; // Prevent submission if both are TravelDesk
        }

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });
        const dataToSubmit = {
            ...formData
        };
        try {
            const token = localStorage.getItem('authToken');
            console.log("check tokens", token);
            const response = await fetch(`${API_BASE_URL}updateAdvanceForm/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSubmit),
            });

            const result = await response.json();
            console.log("advanceformdata", result.data);
            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({
                    comp_name: '',
                    emp_code: ''
                });
                navigate('/own_advance');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}getadvance?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("Profile data3:", result.advance.id);

                if (result.status) {
                    setEmpCode(result.advance.emp_code || '');
                    setReportManagerName(result.reportManagerName.comp_name || '');
                    setAccountManagerName(result.accountsManagerName.comp_name || '');
                    setShowCustomInput(result.advance.customPurpose || '');

                    const report_mngr = result.advance.report_mngr || '';
                    setFormData({
                        comp_name: result.advance.comp_name || '',
                        emp_code: result.advance.emp_code || '',
                        trans_code: result.advance.trans_code || '',
                        dept_name: result.advance.dept_name || '',
                        designation_name: result.advance.designation_name || '',
                        purpose: result.advance.purpose || '',
                        customPurpose: result.advance.customPurpose || '',
                        mode_of_travel: result.advance.mode_of_travel || '',
                        travel_advance_amount: result.advance.travel_advance_amount || '',
                        days: result.advance.days || '',
                        city: result.advance.city || '',
                        travel_advance: result.advance.travel_advance || '',
                        bank_details: result.advance.bank_details || '',
                        grade: result.advance.grade || '',
                        mobile: result.advance.mobile || '',
                        rpt_mgr_status: result.advance.rpt_mgr_status || '',
                        account_status: result.advance.account_status || '',
                        report_mngr: report_mngr,
                        manager_remarks: result.advance.manager_remarks || '',
                        manager_approved_amount: result.advance.manager_approved_amount || '',
                        accounts_remarks: result.advance.accounts_remarks || '',
                        accounts_approved_amount: result.advance.accounts_approved_amount || '',
                        guesthouse: result.advance.guesthouse || '',
                        utr: result.advance.utr || '',
                        utr_date: result.advance.utr_date || '',
                        created_on: result.advance.created_on || '',
                        manager_approved_date: result.advance.manager_approved_date || '',
                        accounts_approved_date: result.advance.accounts_approved_date || '',
                        accounts_manager: result.advance.accounts_manager || '',
                        hotel_tickets: result.advance.hotel_tickets || '',
                        travel_tickets: result.advance.travel_tickets || '',
                        bank_acnum: result.advance.bank_acnum || '',
                        bank_name: result.advance.bank_name || '',
                        ifsc_code: result.advance.ifsc_code || '',
                        advance_flag: 1,
                    });
                    //fetchReportingData(report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("get all cities data:", result);

                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    const currentDateTime = formatDateTime(new Date());

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');

                if (!token) return;

                const response = await fetch(`${API_BASE_URL}purposes`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setPurposes(result.data); // Set the fetched purposes
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);


    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>
                                        Tour/Travel - Advance Form {formData.trans_code && `(${formData.trans_code})`}
                                    </h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="/own_advance">Advance Details</a></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Tour/Travel Advance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                {loading ? (
                                    <Loader /> // Show loader if loading is true
                                ) : (
                                    <>

                                        <div className="card-body">
                                            <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                                <div className="box-body">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Name</label>
                                                                <input
                                                                    type="text"
                                                                    name="comp_name"
                                                                    id="name"
                                                                    value={formData.comp_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Name"
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="emp_code"
                                                                    id="name"
                                                                    value={formData.emp_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee No/Consultant"
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Department</label>
                                                                <input
                                                                    type="text"
                                                                    name="dept_name"
                                                                    id="name"
                                                                    value={formData.dept_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Designation</label>
                                                                <input
                                                                    type="text"
                                                                    name="designation_name"
                                                                    id="name"
                                                                    value={formData.designation_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>



                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Employee Band as per Category</label>
                                                                <input
                                                                    type="text"
                                                                    name="grade"
                                                                    id="name"
                                                                    value={formData.grade}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>

                                                        {formData.report_mngr && ( // Render input based on the condition
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Reporting To
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="report_mngr"
                                                                        id="name"
                                                                        value={formData.report_mngr}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Bank Ac Num </label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_acnum"
                                                                    id="name"
                                                                    value={formData.bank_acnum}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="bank_name">Bank Name </label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_name"
                                                                    id="bank_name"
                                                                    value={formData.bank_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="ifsc_code">IFSC Code </label>
                                                                <input
                                                                    type="text"
                                                                    name="ifsc_code"
                                                                    id="ifsc_code"
                                                                    value={formData.ifsc_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="mode_of_travel">Mode of Travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="mode_of_travel"
                                                                    id="mode_of_travel"
                                                                    value={formData.mode_of_travel}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select</option>
                                                                    {(formData.grade && ['E', 'F'].includes(formData.grade[0])) ? (
                                                                        <>
                                                                            <option value='Air'>Air</option>
                                                                            <option value='Train'>Train</option>
                                                                            <option value='Bus'>Bus</option>
                                                                            <option value='Local_Taxi'>Local Taxi</option>
                                                                            <option value='Local_Auto'>Local Auto</option>
                                                                            <option value='Own_Bike'>Own Bike</option>
                                                                            <option value='Own_Car'>Own Car</option>
                                                                            <option value='Company_Car'>Company Car</option>
                                                                        </>

                                                                    ) : (
                                                                        <>
                                                                            <option value='Air'>Air</option>
                                                                            <option value='Train'>Train</option>
                                                                            <option value='Bus'>Bus</option>
                                                                            <option value='Local_Taxi'>Local Taxi</option>
                                                                            <option value='Local_Auto'>Local Auto</option>
                                                                            <option value='Own_Bike'>Own Bike</option>
                                                                            {/* <option value='Own_Car'>Own Car</option> */}
                                                                            <option value='Company_Car'>Company Car</option>
                                                                        </>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Travel Advance requisitioned
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="number"
                                                                    name="travel_advance_amount"
                                                                    id="name"
                                                                    value={formData.travel_advance_amount}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    min="1"   // Disallow zero and negative values
                                                                    step="any"    // Allows decimals, but restricts values to be above 0

                                                                />
                                                            </div>
                                                        </div>
                                                        {!formData.customPurpose && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="purpose">
                                                                        Purpose <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    {loading ? (
                                                                        <p>Loading purposes...</p>
                                                                    ) : error ? (
                                                                        <p>{error}</p>
                                                                    ) : (
                                                                        <select
                                                                            name="purpose"
                                                                            id="purpose"
                                                                            value={formData.purpose}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                // Show custom input if "Others" is selected
                                                                                setShowCustomInput(e.target.value === "Others");
                                                                            }}
                                                                            className="form-control input-font"
                                                                            required
                                                                        >
                                                                            <option value="">Select a purpose</option>
                                                                            {purposes.map(item => (
                                                                                <option key={item.id} value={item.purpose}>
                                                                                    {item.purpose}
                                                                                </option>
                                                                            ))}
                                                                            <option value="Others">Others</option>
                                                                        </select>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* Show Specify Purpose input only if purpose is "Others" or customPurpose is set */}
                                                        {(formData.purpose === 'Others' || formData.customPurpose) && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="customPurpose">
                                                                        Specify Purpose <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <textarea
                                                                        name="customPurpose"
                                                                        id="customPurpose"
                                                                        placeholder="Specify your purpose"
                                                                        value={formData.customPurpose}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        rows={1} // Adjust the number of rows as needed
                                                                    // Optional: restrict to 12 characters
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="col-md-4">
                                                            <div className="form-group" style={{ display: 'none' }}>
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Bank details for transfer
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_details"
                                                                    id="name"
                                                                    value={formData.bank_details}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Bank details for transfer "
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="travelAdvance">Has earlier travel advance settled <span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                    name="travel_advance"
                                                                    id="travelAdvance"
                                                                    value={formData.travel_advance}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="days">Days of travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="days"
                                                                    id="days"
                                                                    value={formData.days}
                                                                    onChange={handleChange}
                                                                    onBlur={validateDays}
                                                                    className="form-control input-font"
                                                                    placeholder="1,1.5,2,2.5,etc"
                                                                    required
                                                                    min="1" // Prevents entering 0 or negative numbers
                                                                    step="0.1"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="city">Place to travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="city"
                                                                    id="city"
                                                                    value={formData.city}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select a city</option>
                                                                    {items.map((city) => (
                                                                        <option key={city.id} value={city.city_name}>
                                                                            {city.city_name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="hotel_tickets">HotelBooked by
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="hotel_tickets"
                                                                    id="hotel_tickets"
                                                                    value={formData.hotel_tickets}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select a Hotel-Ticket</option>
                                                                    <option value="Self">Self</option>
                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="travel_tickets">HotelBooked by
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="travel_tickets"
                                                                    id="travel_tickets"
                                                                    value={formData.travel_tickets}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select a Travel-Ticket</option>
                                                                    <option value="Self">Self</option>
                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="guesthouse">Select City Guest-House
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="guesthouse"
                                                                    id="guesthouse"
                                                                    value={formData.guesthouse}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select a Guest-House</option>
                                                                    <option value="Hyderabad">Hyderabad</option>
                                                                    <option value="Vishakapatnam">Vishakapatnam</option>
                                                                    <option value="Rajuhmundry">Rajuhmundry</option>
                                                                    <option value="Gurgaon">Gurgaon</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {formData.mobile && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                                        <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobile"
                                                                        id="name"
                                                                        value={formData.mobile}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"

                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                    
                                                    <div className="row">
                                                        {(formData.rpt_mgr_status === 'Approved') && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">Reporting Manager Status
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <select
                                                                        name="rpt_mgr_status"
                                                                        id="rpt_mgr_status"
                                                                        value={formData.rpt_mgr_status}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        readOnly
                                                                        disabled
                                                                    >

                                                                        <option value="Pending">Pending</option>
                                                                        <option value="Approved">Approved</option>
                                                                        <option value="Rejected">Rejected</option>
                                                                    </select>
                                                                </div>
                                                            </div>)}
                                                        {(formData.manager_approved_amount && formData.rpt_mgr_status === 'Approved') && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Reporting Manager Approved Amount
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="manager_approved_amount"
                                                                        id="name"
                                                                        value={formData.manager_approved_amount}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Advance Approved Amount"
                                                                        readOnly

                                                                    />
                                                                </div>
                                                            </div>)}
                                                        {(formData.manager_remarks && formData.rpt_mgr_status === 'Approved' || formData.rpt_mgr_status === 'Rejected') && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="manager_remarks">Reporting Manager Remarks
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>

                                                                    <textarea
                                                                        id="manager_remarks"
                                                                        value={formData.manager_remarks}
                                                                        onChange={handleChange}
                                                                        className="form-control"
                                                                        placeholder="manager remarks"
                                                                        readOnly></textarea>
                                                                </div>
                                                            </div>)}</div>
                                                    <div className="row">{(formData.account_status === 'Approved' && formData.rpt_mgr_status === 'Approved') && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="account_status">Finance Status
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="account_status"
                                                                id="account_status"
                                                                value={formData.account_status}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                readOnly
                                                                disabled
                                                            >

                                                                <option value="Pending">Pending</option>
                                                                <option value="Approved">Approved</option>
                                                                <option value="Rejected">Rejected</option>
                                                            </select>
                                                        </div>
                                                    </div>)}
                                                        {(formData.accounts_approved_amount && formData.rpt_mgr_status === 'Approved') && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Finance Approved Amount
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="accounts_approved_amount"
                                                                        id="name"
                                                                        value={formData.accounts_approved_amount}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Advance Approved Amount"
                                                                        readOnly

                                                                    />
                                                                </div>
                                                            </div>)}{(formData.accounts_remarks && formData.rpt_mgr_status === 'Approved') && (
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '12px' }} htmlFor="accounts_remarks">Finance Remarks
                                                                            <span style={{ color: 'red' }}>*</span>
                                                                        </label>

                                                                        <textarea
                                                                            id="accounts_remarks"
                                                                            value={formData.accounts_remarks}
                                                                            onChange={handleChange}
                                                                            className="form-control"
                                                                            placeholder="manager remarks"
                                                                            readOnly></textarea>
                                                                    </div>
                                                                </div>)}</div>
                                                    <div className="row">{(formData.utr && formData.rpt_mgr_status === 'Approved') && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="utr">UTR
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="utr"
                                                                id="name"
                                                                value={formData.utr}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Advance Approved Amount"
                                                                readOnly

                                                            />
                                                        </div>
                                                    </div>)}{(formData.utr_date > 0 && formData.rpt_mgr_status === 'Approved') && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="utr_date">UTR date
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="utr_date"
                                                                id="utr_date"
                                                                value={formData.utr_date}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Advance Approved Amount"
                                                                readOnly

                                                            />
                                                        </div>
                                                    </div>)}


                                                        {(formData.rpt_mgr_status === 'Rejected' || formData.account_status === 'Rejected') && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="status_select">
                                                                        Request Type

                                                                        <span style={{ color: 'red' }}>*</span>
                                                                        (<small style={{ color: '#888', fontStyle: 'italic' }}>
                                                                            For Requesting Again to the Reporting Manager or Hotel Manager make this "Pending".
                                                                        </small>)
                                                                    </label>

                                                                    <select
                                                                        name="status_select"
                                                                        id="status_select"
                                                                        value={formData.status_select || ''}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        required
                                                                    >
                                                                        <option value="">Select Request</option>

                                                                        {/* Conditionally enable only the appropriate option */}
                                                                        {formData.rpt_mgr_status === 'Rejected' && (
                                                                            <option value="request_for_manager">Request For Manager</option>
                                                                        )}
                                                                        {formData.account_status === 'Rejected' && (
                                                                            <option value="request_for_accounts_mngr">Request For Finance Manager</option>
                                                                        )}

                                                                    </select>
                                                                </div>



                                                            </div>

                                                        )}


                                                        {/* Based on selected Request Type, show the corresponding dropdowns */}
                                                        {formData.status_select && (
                                                            <div className="col-md-4">
                                                                {formData.status_select === 'request_for_manager' && (
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">
                                                                            Request For Manager
                                                                            <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <select
                                                                            name="rpt_mgr_status"
                                                                            id="rpt_mgr_status"
                                                                            value={formData.rpt_mgr_status || ''}
                                                                            onChange={handleChange}
                                                                            className="form-control input-font"
                                                                            required
                                                                        >
                                                                            <option value="Pending">Pending</option>
                                                                        </select>
                                                                    </div>
                                                                )}

                                                                {formData.status_select === 'request_for_accounts_mngr' && (
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '12px' }} htmlFor="account_status">
                                                                            Request For Finance Manager
                                                                            <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <select
                                                                            name="account_status"
                                                                            id="account_status"
                                                                            value={formData.account_status || ''}
                                                                            onChange={handleChange}
                                                                            className="form-control input-font"
                                                                            required
                                                                        >
                                                                            <option value="Pending">Pending</option>
                                                                        </select>
                                                                    </div>
                                                                )}


                                                            </div>
                                                        )}

                                                    </div>
                                                </div>

                                                <div className="box-footer" style={{ textAlign: 'center' }}>
                                                    {formData.account_status !== 'Approved' && (
                                                        <button type="submit" className="btn btn-success pull-right">Submit</button>
                                                    )}


                                                </div>

                                            </form>




                                            <div className="col-sm-12 row" style={{ width: '100%' }}>
                                                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-4">
                                                    <b>Prepared by </b>
                                                    <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                        : {formData.created_on}
                                                    </p>
                                                </div>
                                                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-4">
                                                    {formData.rpt_mgr_status === 'Approved' && (
                                                        <>
                                                            <b>Approved by</b>
                                                            <p>
                                                                <span>
                                                                    {formData.report_mngr} ({ReportManagerName})<br />
                                                                    Date: {formData.manager_approved_date}
                                                                </span>
                                                            </p>
                                                        </>
                                                    )}
                                                </div>

                                                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-4">
                                                    {formData.account_status === 'Approved' && (
                                                        <>
                                                            <b>For Finance &amp; Accounts</b>
                                                            <p>
                                                                {formData.accounts_manager} ({AccountManagerName})<br />
                                                                Date: {formData.accounts_approved_date}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>

                                            </div>

                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>

            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default EditAdvanceForm;
