import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Manage_User.css';
//mport { BASE_URL } from "./Constants.js";
import useAuth from './useAuth';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const Manage_Reports = () => {
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [cities, setCities] = useState([]);
    const [items, setItems] = useState([]);
    const [EmpCode, setEmpCode] = useState('');
    const [Reportee, setReportee] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [RptMgr, setRptMgr] = useState('');
    const [error, setError] = useState('');
    const [startDate, setStartDate] = useState(null);  // For date picker
    const [endDate, setEndDate] = useState(null);      // For date picker
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();
    useAuth();
    useEffect(() => {
        const fetchReporteeData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getreportee`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setReportee(result.data);  // Set the reportee data
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Error: ' + error.message);
            }
        };
        fetchReporteeData();
    }, []);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');
                    setRptMgr(result.profile.report_mngr || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);

                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("get all cities data:", result);

                if (result.status) {
                    setCities(result.data);  // Set the cities from API response
                    setFilteredCities(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    useEffect(() => {
        const fetchItems = async (EmpCode) => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token || !EmpCode) return;

                const response = await fetch(`${API_BASE_URL}getadvance?report_mngr=${encodeURIComponent(EmpCode)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setItems(result.advance);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
               // setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems(EmpCode);
        }
    }, [EmpCode, navigate]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    

    const handleCheckboxChange1 = (emp_code) => {
        setSelectedEmployee((prevSelectedEmployee) => {
            if (prevSelectedEmployee.includes(emp_code)) {
                return prevSelectedEmployee.filter(emp => emp !== emp_code);  // Remove if already selected
            } else {
                return [...prevSelectedEmployee, emp_code];  // Add if not selected
            }
        });
    };
// Handle checkbox changes to update the selected cities
const handleCheckboxChange = (cityName) => {
    setSelectedCities(prevSelectedCities => {
        if (prevSelectedCities.includes(cityName)) {
            return prevSelectedCities.filter(city => city !== cityName);  // Remove if already selected
        } else {
            return [...prevSelectedCities, cityName];  // Add if not selected
        }
    });
};
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const toggleDropdown1 = () => {
        setIsOpen1(prevState => !prevState);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query);

        // Filter based on both comp_name and emp_code
        const filtered = Reportee.filter((employee) =>
            employee.comp_name.toLowerCase().includes(query) || // Search by comp_name
            employee.emp_code.toLowerCase().includes(query)       // Search by emp_code
        );
        setReportee(filtered);  // Update the reportee list as per the search query
    };

    // Handle search query changes and filter cities
    const handleSearchChange2 = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query);

        // Filter cities based on search query
        const filtered = cities.filter(city => city.city_name.toLowerCase().includes(query));
        setFilteredCities(filtered);
    };

    const handleSearchChange1 = (e) => {
        setSearchQuery(e.target.value);
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            CompleteName: item.comp_name,
            Employeecode: item.emp_code,
            TransCode: item.trans_code,
            ReportingManager: item.report_mngr,
            Days: item.days,
            TravelAdvanceAmount: item.travel_advance_amount,
            ReportingManagerStatus: item.rpt_mgr_status,
            FinanceStatus: item.account_status,
            City: item.city,
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Advance');
        XLSX.writeFile(workbook, 'Reports.xlsx');
    };

    const handleItemsPerPageChange = e => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };



    useEffect(() => {
        const filtered = items.filter(item => {
            const matchesSearch = item.comp_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.trans_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.emp_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.accounts_approved_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.rpt_mgr_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.account_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.manager_approved_amount.toLowerCase().includes(searchQuery.toLowerCase());

            const matchesEmployee = selectedEmployee.length === 0 || selectedEmployee.includes(item.emp_code);  // Filter based on selected employees
            const matchesCity = selectedCities.length === 0 || selectedCities.includes(item.city);
            // Date range matching logic
            const matchesDateRange = (
                (!startDate || new Date(item.created_on).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0)) &&
                (!endDate || new Date(item.created_on).setHours(23, 59, 59, 999) <= new Date(endDate).setHours(23, 59, 59, 999))
            );

            return matchesSearch && matchesCity && matchesDateRange && matchesEmployee;
        });

        setFilteredItems(filtered);
        setCurrentPage(1);  // Reset to the first page when data or filters change
    }, [searchQuery, items, selectedEmployee, selectedCities, startDate, endDate]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSort = (column) => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Manager Reports</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                    <div className="card-header">
                                            <button
                                                className="btn btn-success"
                                                style={{ float: 'right' }}
                                                onClick={exportToExcel}
                                            >
                                                Export to Excel
                                            </button>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {/* Items Per Page Selector */}
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <select
                                                            className="form-control custom-select-sm"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            selectsStart
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            placeholderText="Select Start Date"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        
                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={(date) => setEndDate(date)}
                                                            selectsEnd
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            minDate={startDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            placeholderText="Select End Date"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 p-0">
                                                    <div className="form-group">
                                                      
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-secondary dropdown-toggle form-control"
                                                                type="button"
                                                                id="dropdownMenuButton"
                                                                aria-expanded="false"
                                                                onClick={toggleDropdown}
                                                            >
                                                                {selectedEmployee.length === 0
                                                                    ? 'Select Employees'
                                                                    : `${selectedEmployee.length} Employees Selected`}
                                                            </button>
                                                            {isOpen && (
                                                                <div
                                                                    className="dropdown-menu show"
                                                                    style={{
                                                                        maxHeight: '300px',
                                                                        overflowY: 'auto',
                                                                        width: '100%',
                                                                    }}
                                                                    aria-labelledby="dropdownMenuButton"
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search Employees"
                                                                        value={searchQuery1}
                                                                        onChange={handleSearchChange}
                                                                        style={{ marginBottom: '10px' }}
                                                                    />
                                                                    {Reportee.length > 0 ? (
                                                                        Reportee.filter(item =>
                                                                            item.comp_name.toLowerCase().includes(searchQuery1.toLowerCase()) ||   // Search by comp_name
                                                                            item.emp_code.toLowerCase().includes(searchQuery1.toLowerCase())    
                                                                        ).map((reportee) => (
                                                                            <div key={reportee.emp_code} className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id={`emp-${reportee.emp_code}`}
                                                                                    value={reportee.emp_code}
                                                                                    checked={selectedEmployee.includes(reportee.emp_code)}
                                                                                    onChange={() => handleCheckboxChange1(reportee.emp_code)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`emp-${reportee.emp_code}`}>
                                                                                    {`${reportee.comp_name} (${reportee.emp_code})`}
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div>Loading employees...</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 p-0">
                                                    <div className="form-group">
                                                       
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-secondary dropdown-toggle form-control"
                                                                type="button"
                                                                id="dropdownMenuButton"
                                                                aria-expanded="false"
                                                                onClick={toggleDropdown1}
                                                            >
                                                                {selectedCities.length === 0
                                                                    ? 'Select Cities'
                                                                    : `${selectedCities.length} Cities Selected`}
                                                            </button>

                                                            {isOpen1 && (
                                                                <div className="dropdown-menu show" style={{
                                                                    maxHeight: '300px', // Max height to show only 10 items
                                                                    overflowY: 'auto', // Enables scroll if items exceed 10
                                                                    width: '100%'
                                                                }} aria-labelledby="dropdownMenuButton">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search Cities"
                                                                        value={searchQuery1}
                                                                        onChange={handleSearchChange2}
                                                                        style={{ marginBottom: '10px' }}
                                                                    />
                                                                    {filteredCities.length > 0 ? (
                                                                        filteredCities.map((city) => (
                                                                            <div key={city.id} className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id={`city-${city.id}`}
                                                                                    value={city.city_name}
                                                                                    checked={selectedCities.includes(city.city_name)}
                                                                                    onChange={() => handleCheckboxChange(city.city_name)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`city-${city.id}`}>
                                                                                    {city.city_name}
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div>Loading cities...</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        
                                                     
                                                            <input
                                                                type="search"
                                                                value={searchQuery}
                                                                onChange={handleSearchChange1}
                                                                className="form-control form-control-sm"
                                                                placeholder="Search"
                                                                aria-controls="example1"
                                                            />
                                                     
                                                    </div>
                                                </div>
                                            </div>

                                            {loading && <Loader />}
                                            {error && <div className="alert alert-danger">{error}</div>}

                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th onClick={() => handleSort('comp_name')}>Employee Name</th>
                                                            <th onClick={() => handleSort('trans_code')}>Trans Code</th>
                                                            <th onClick={() => handleSort('emp_code')}>Employee Code</th>
                                                            <th onClick={() => handleSort('days')}>Days</th>
                                                            <th onClick={() => handleSort('city')}>City</th>
                                                            <th onClick={() => handleSort('city')}>Travel Amount</th>
                                                            <th onClick={() => handleSort('report_mngr')}>Reporting Manager</th>
                                                            <th onClick={() => handleSort('rpt_mgr_status')}>Reporting Manager Status</th>
                                                            <th onClick={() => handleSort('accounts_status')}>Finance Status</th>
                                                            <th onClick={() => handleSort('created_on')}>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentItems.length > 0 ? (
                                                            currentItems.map(item => (
                                                                <tr key={item.trans_code}>
                                                                    <td>{item.comp_name}</td>
                                                                    <td>{item.trans_code}</td>
                                                                    <td>{item.emp_code}</td>
                                                                    <td>{item.days}</td>
                                                                    <td>{item.city}</td>
                                                                    <td>
                                                                        {item.accounts_approved_amount ? item.accounts_approved_amount : item.manager_approved_amount}
                                                                    </td>
                                                                    <td>{item.report_mngr}</td>
                                                                    <td style={{ color: item.rpt_mgr_status === 'Pending' ? 'orange' : item.rpt_mgr_status === 'Rejected' ? 'red' : item.rpt_mgr_status === 'Approved' ? 'green' : 'inherit' }}>
                                                                        {item.rpt_mgr_status}
                                                                    </td>
                                                                    <td style={{ color: item.account_status === 'Pending' ? 'orange' : item.account_status === 'Approved' ? 'green' : item.account_status === 'Rejected' ? 'red' : 'inherit' }}>
                                                                        {item.account_status}
                                                                    </td>
                                                                    <td>{item.created_on}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="10">No records found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <nav>
                                        <ul className='pagination' style={{ float: "right" }}>
                                            <li className='page-item'>
                                                <button
                                                    className='page-link'
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}>
                                                    Previous
                                                </button>
                                            </li>
                                            {[...Array(totalPages).keys()].map(pageNumber => (
                                                <li key={pageNumber} className={`page-item ${pageNumber + 1 === currentPage ? "active" : ""}`}>
                                                    <button className='page-link' onClick={() => handlePageChange(pageNumber + 1)}>
                                                        {pageNumber + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className='page-item'>
                                                <button
                                                    className='page-link'
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}>
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className='mb-3'>
                                        Showing {startItem} to {endItem} of {filteredItems.length} entries
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Manage_Reports;
