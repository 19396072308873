import { useEffect } from "react";
//import { BASE_URL } from "./Constants.js";
import { token } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//const token = localStorage.getItem("authToken");
const Statelist = ({ onStatesFetched }) => {
	useEffect(() => {
		const fetchStates = async () => {
			try {
				const response = await fetch(`${API_BASE_URL}states`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				const data = await response.json();
				//console.log("Full API Response: ", data); // Log the full API response

				// Check if custom_data exists and pass it to the parent
				if (data && data.data) {
					onStatesFetched(data.data);
				} else {
					onStatesFetched([]); // Handle case where custom_data is missing
				}
			} catch (error) {
				console.error("Fetch error: ", error);
				onStatesFetched([]); // Pass an empty array if there's an error
			}
		};

		fetchStates();
	}, [onStatesFetched]);

	return null;
};

export default Statelist;
