import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
//import { BASE_URL } from "./Constants.js";
import useAuth from './useAuth';
import * as XLSX from "xlsx";
import './Loader.css';
import Pagination from './Pagination.js';
import "./Manage_User.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const Loader = () => (
// 	<div style={{ textAlign: 'center', marginTop: '20px' }}>
// 		<div className="spinner-border" role="status">
// 			<span className="sr-only">Loading...</span>
// 		</div>
// 		<p>Loading...</p>
// 	</div>
// );


const Loader = () => (
	<div className="loader-container1">
		<div className="spinner1"></div>
		<p>Loading...</p>
	</div>
);

const ManageHotelRequest = () => {
	const [EmpCode, setEmpCode] = useState('');
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [responseMessage, setResponseMessage] = useState('');
	const [error, setError] = useState("");
	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [cities, setCities] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState("id");
	const [sortOrder, setSortOrder] = useState("desc");
	const navigate = useNavigate();
	useAuth();
	const token = localStorage.getItem("authToken") || null;

	useEffect(() => {
		const fetchProfileData = async () => {
			try {
				const token = localStorage.getItem('authToken');
				const response = await fetch(`${API_BASE_URL}profile`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const result = await response.json();

				if (result.status) {
					setEmpCode(result.profile.emp_code || '');


				} else {
					setResponseMessage('Error: ' + result.message);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				setResponseMessage('Error: ' + error.message);
			}
		};

		fetchProfileData();
	}, []);
	useEffect(() => {
		const fetchItems = async (EmpCode) => {
			try {
				const token = localStorage.getItem('authToken');
				console.log("hi", token);
				if (!token) return;

				const response = await fetch(`${API_BASE_URL}getHotelRequest?emp_code=${encodeURIComponent(EmpCode)}`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json'
					}
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const result = await response.json();
				if (result.status) {
					setItems(result.hotel_request);
				} else {
					setError(result.message);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				setError('Failed to fetch items');
			} finally {
				setLoading(false);
			}
		};

		if (EmpCode) {
			fetchItems(EmpCode);
		}
	}, [EmpCode, navigate]);


	useEffect(() => {
		const fetchCitiesData = async () => {
			try {
				const token = localStorage.getItem('authToken');
				const response = await fetch(`${API_BASE_URL}cities`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const result = await response.json();
				if (result.status) {
					setCities(result.data);
				} else {
					setError(result.message);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				setError('Failed to fetch cities data');
			}
		};

		fetchCitiesData();
	}, []);

	useEffect(() => {
		if (!Array.isArray(items)) {
			setFilteredItems([]);
			return;
		}

		const lowerCaseQuery = searchQuery.toLowerCase();


		const filtered = items.filter(item => {
			// Define search matches for different properties
			const CityMatch = cities.find(city => city.id === item.city)?.city_name.toLowerCase().includes(searchQuery.toLowerCase()) || false;
			const HotelMatch = item.hotel_name ? item.hotel_name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
			const check_inMatch = item.check_in ? item.check_in.toLowerCase().includes(searchQuery.toLowerCase()) : false;
			const check_outMatch = item.check_out ? item.check_out.toLowerCase().includes(searchQuery.toLowerCase()) : false;
			const TravelDateMatch = item.travel_date ? item.travel_date.toLowerCase().includes(searchQuery.toLowerCase()) : false;
			const empCodeMatch = item.related_details.map(detail => detail.emp_code).join(', ').toLowerCase().includes(searchQuery.toLowerCase());
			const reportMngr = item.report_mngr ? item.report_mngr.toLowerCase().includes(searchQuery.toLowerCase()) : false;
			const hotelManager = item.hotel_manager ? item.hotel_manager.toLowerCase().includes(searchQuery.toLowerCase()) : false;

			// Add custom property matches
			const nameMatch = item.comp_name?.toLowerCase().includes(searchQuery.toLowerCase()) || false;

			const transMatch = item.trans_code?.toLowerCase().includes(searchQuery.toLowerCase()) || false;
			const daysMatch = item.days?.toLowerCase().includes(searchQuery.toLowerCase()) || false;
			const amountMatch = item.travel_advance_amount?.toLowerCase().includes(searchQuery.toLowerCase()) || false;
			const codeMatch = item.emp_code?.toLowerCase().includes(searchQuery.toLowerCase()) || false;
			const rptMgrStatusMatch = item.rpt_mgr_status?.toLowerCase().includes(searchQuery.toLowerCase()) || false;
			const accountStatusMatch = item.account_status?.toLowerCase().includes(searchQuery.toLowerCase()) || false;

			// Combine all matches into a single variable
			const matchesSearch = nameMatch || transMatch || hotelManager || reportMngr || check_inMatch || check_outMatch || daysMatch || amountMatch || codeMatch || rptMgrStatusMatch || accountStatusMatch || HotelMatch || CityMatch || TravelDateMatch || empCodeMatch;

			// Filter based on URL path

			if (location.pathname === '/manage_hotel_request/all') {
				return matchesSearch; // Show all matching items
			} else if (location.pathname === '/manage_hotel_request/pending') {
				return item.htl_mgr_status === 'Pending' && matchesSearch; // Show only pending items
			} else if (location.pathname === '/manage_hotel_request/approved') {
				return item.htl_mgr_status === 'Approved' && matchesSearch; // Show only approved items
			} else if (location.pathname === '/manage_hotel_request/rejected') {
				return item.rpt_mgr_status === 'Rejected' && matchesSearch; // Show only rejected items
			} else if (location.pathname === '/manage_hotel_request') {
				return matchesSearch;
			}

			return false; // Default case
		});

		const sorted = filtered.sort((a, b) => {
			if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
			if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
			return 0;
		});

		setFilteredItems(sorted);
		setCurrentPage(1);
	}, [searchQuery, items, sortColumn, sortOrder, cities]);

	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const startItem = indexOfFirstItem + 1;
	const endItem = Math.min(indexOfLastItem, filteredItems.length);

	const handleSearchChange = e => {
		setSearchQuery(e.target.value);
	};

	const handlePageChange = pageNumber => {
		setCurrentPage(pageNumber);
	};

	const handleItemsPerPageChange = e => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handleSort = (column) => {
		if (column === "id") {
			if (sortColumn === column) {
				setSortOrder(sortOrder === "asc" ? "desc" : "asc");
			} else {
				setSortColumn(column);
				setSortOrder("asc");
			}
		}
	};

	const exportToExcel = () => {
		const filteredStateNames = filteredItems.map(item => ({
			HotelName: item.hotel_name,
			City: cities.find(city => city.id === item.city)?.city_name || 'Unknown City',
			Check_In: item.check_in,
			TransCode: item.trans_code,
			ReportingManager: item.report_mngr,
			ReportingManagerStatus: item.rpt_mgr_status,
			HotelManager: item.hotel_manager,
			HotelManagerStatus: item.htl_mgr_status,
			Check_Out: item.check_out,
			Emp_Code: item.related_details && item.related_details.length > 0
				? [item.emp_code, ...item.related_details.map(detail => detail.emp_code)].join(', ')
				: item.emp_code || 'N/A',
		}));
		const worksheet = XLSX.utils.json_to_sheet(filteredStateNames);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "HotelRequest");
		XLSX.writeFile(workbook, "HotelRequest.xlsx");
	};

	const handleDelete = async (id, e) => {
		const token = localStorage.getItem('authToken');
		e.preventDefault(); // Prevent default behavior of the anchor tag

		// Show confirmation dialog
		const confirmed = window.confirm('Are you sure you want to delete this item?');
		if (!confirmed) return; // Exit if user cancels

		try {
			// Send DELETE request to API with the item id
			const response = await fetch(`${API_BASE_URL}delete_hotel_request/${id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
					// Add other headers if necessary (e.g., Authorization)
				},
			});

			if (!response.ok) {
				throw new Error('Failed to delete the item');
			}

			// Optional: Display a success message or perform other actions after successful deletion
			alert('Item deleted successfully');
			window.location.reload();
			// Navigate to another page after successful deletion
			navigate(`/manage_hotel_request`);
		} catch (error) {
			console.error('Error deleting item:', error);
			alert('Failed to delete the item.');
		}
	};


	return (
		<>
			<div className='hold-transition sidebar-mini layout-fixed' style={{ fontSize: '12px' }}>
				<div className='wrapper'>
					<Header />
					<Sidebar />
					<div className='content-wrapper'>
						<div className='content-header'>
							<div className='container-fluid'>
								<div className='row mb-2'>
									<div className='col-sm-6'>
										<h1 className='m-0' style={{ fontSize: '20px' }}>Hotel Request Details</h1>
									</div>
									<div className='col-sm-6'>
										<ol className='breadcrumb float-sm-right'>
											<li className='breadcrumb-item' style={{ fontSize: '12px' }}>
												<Link to='/dashboard'>Home</Link>
											</li>
											<li className='breadcrumb-item active' style={{ fontSize: '12px' }}>Hotel Request Details</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
						<section className='content'>
							<div className='container-fluid'>
								<div className='row'>
									<section className='col-lg-12 connectedSortable'>
										<div className='card'>
											<div className='card-header'>
												<span style={{ float: "right" }}>
													<Link to='/hotel_request'>
														<button type='button' className='btn btn-success'>Add New</button>
													</Link>
													<button type='button' className='btn btn-secondary ml-2' onClick={exportToExcel}>Export to Excel</button>
												</span>
											</div>
											<div className='card-body'>
												{loading ? (
													<Loader />
												) : (
													<>
														<div id='example1_filter' style={{ float: "right" }} className='dataTables_filter'>
															<label>
																<input
																	type='search'
																	value={searchQuery}
																	onChange={handleSearchChange}
																	className='form-control form-control-sm'
																	placeholder='Search'
																	aria-controls='example1'
																/>
															</label>
														</div>

														<div className='form-group'>
															<select
																className='form-control custom-select-sm'
																value={itemsPerPage}
																onChange={handleItemsPerPageChange}>
																<option value={10}>10</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>
														</div>
														<div className="table-responsive">
															<table className='table table-bordered table-striped' style={{ fontSize: '13px' }}>
																<thead>
																	<tr>
																		<th onClick={() => handleSort("id")}>
																			ID {sortColumn === "id" && (sortOrder === "asc" ? "▲" : "▼")}
																		</th>
																		<th>Trans Code</th>
																		
																		<th>City</th>
																		<th>Check - In</th>
																		<th>Check - Out</th>
																		<th>Emp Code</th>
																		<th>Reporting Manager</th>
																		<th>Report Manager Status</th>
																		<th>Hotel Manager</th>
																		<th>Hotel Manager Status</th>
																		<th>Remarks</th>
																		<th>Action</th>
																	</tr>
																</thead>
																<tbody>
																	{currentItems.length > 0 ? (
																		currentItems.map((item, index) => {
																			const City = cities.find(city => city.id === item.city)?.city_name || 'Unknown City';


																			return (
																				<tr key={index}>
																					<td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
																					<td>{item.trans_code}</td>
																					
																					<td>{City}</td>

																					<td>{item.check_in}</td>
																					<td>{item.check_out}</td>
																					<td>
																						{item.related_details && item.related_details.length > 0
																							? [
																								item.emp_code,
																								...item.related_details.map(detail => detail.emp_code)
																							]
																								.filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
																								.join(', ')
																							: item.emp_code || 'N/A'}
																					</td>
																					<td>{item.report_mngr}</td>
																					<td style={{
																						color: item.rpt_mgr_status === 'Pending' ? 'orange' :
																							item.rpt_mgr_status === 'Rejected' ? 'red' :
																								item.rpt_mgr_status === 'Approved' ? 'green' :
																									'inherit'
																					}}>
																						{item.rpt_mgr_status}
																					</td>
																					<td>{item.hotel_manager ? item.hotel_manager : "Need to Approval by Travel Manager To Display"}</td>
																					<td style={{
																						color: item.htl_mgr_status === 'Pending' ? 'orange' :
																							item.htl_mgr_status === 'Rejected' ? 'red' :
																								item.htl_mgr_status === 'Approved' ? 'green' :
																									'inherit'
																					}}>
																						{item.htl_mgr_status}
																					</td>
																					<td>{item.hotel_name.toUpperCase()}</td>
																					<td>
																						<Link to={`/get_hotel_request_form/${item.id}`}>
																							<i className="fa fa-edit" style={{ fontSize: '12px', color: 'green', marginRight: '10px' }}></i>
																						</Link>
																						{/* {item.htl_mgr_status === 'Pending' && (<a href="#" onClick={(e) => handleDelete(item.id, e)}>
                                                                                        <i className="fa fa-times" style={{ fontSize: '12px', color: 'red' }}></i>
                                                                                    </a>)} */}
																					</td>
																				</tr>
																			);
																		})
																	) : (
																		<tr>
																			<td colSpan='12'><center>No data available</center></td>
																		</tr>
																	)}
																</tbody>
															</table>
														</div>
													</>
												)}
											</div>
										</div>
										<Pagination
											currentPage={currentPage}
											totalPages={totalPages}
											handlePageChange={handlePageChange}
										/>
										<div className='mb-3'>
											Showing {startItem} to {endItem} of {filteredItems.length} entries
										</div>
									</section>
								</div>
							</div>
						</section>
					</div>
					<Footer />
					<aside className='control-sidebar control-sidebar-dark'></aside>
				</div>
			</div>
		</>
	);
};

export default ManageHotelRequest;
