import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
import * as XLSX from 'xlsx';
//import { BASE_URL } from "./Constants.js";
import useAuth from './useAuth';
import "./Manage_User.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Loader component
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const ManagerTravelReports = () => {
    const [EmpCode, setEmpCode] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCities, setSelectedCities] = useState([]);
    const [error, setError] = useState("");
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [searchQuery1, setSearchQuery1] = useState('');
    const [Reportee, setReportee] = useState([]);
    const [cities, setCities] = useState([]); // For dropdown filter
    const [allCities, setAllCities] = useState([]); // Keep original list of all cities
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("sysno");
    const [sortOrder, setSortOrder] = useState("desc");
    const [startDate, setStartDate] = useState(null);  // For date picker
    const [endDate, setEndDate] = useState(null);      // For date picker
    const navigate = useNavigate();
    const token = localStorage.getItem("authToken") || null;
    useAuth();

    useEffect(() => {
        const fetchReporteeData = async () => {
            setLoadingEmployees(true);
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getemployee`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {

                    setTimeout(() => {
                        // Simulated data fetch
                        setReportee(result.data);  // Set the reportee data
                        setLoadingEmployees(false);
                    }, 3000); // 5 seconds delay
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Error: ' + error.message);
            }
        };
        fetchReporteeData();
    }, []);

    // Fetch profile data (EmpCode, is_traveldesk)
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };
        fetchProfileData();
    }, [token]);

    // Fetch cities data
    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setCities(result.data); // Set filtered cities for dropdown
                    setAllCities(result.data); // Keep the full list of cities
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch cities data');
            }
        };
        fetchCitiesData();
    }, [token]);

    // Fetch travel request items
    useEffect(() => {
        const fetchItems = async (EmpCode) => {
            if (!EmpCode) return;

            try {
                const response = await fetch(`${API_BASE_URL}getTravelRequest?report_mngr=${encodeURIComponent(EmpCode)}`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setItems(result.travel_request);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems(EmpCode);
        }
    }, [EmpCode, token]);

    // Handle checkbox changes for cities
    const handleCheckboxChange = (cityName) => {
        setSelectedCities(prevSelectedCities => {
            if (prevSelectedCities.includes(cityName)) {
                return prevSelectedCities.filter(city => city !== cityName);  // Remove if already selected
            } else {
                return [...prevSelectedCities, cityName];  // Add if not selected
            }
        });
    };

    useEffect(() => {
        if (!Array.isArray(items)) return;

        const lowerCaseQuery = searchQuery.toLowerCase();

        const filtered = items.filter(item => {
            // Safeguard: Ensure `item` properties are valid strings before calling `.toLowerCase()`
            const fromCityName = allCities.find(city => city.id === item.from_city)?.city_name || '';
            const toCityName = allCities.find(city => city.id === item.to_city)?.city_name || '';
            const travelDate = item.travel_date ? item.travel_date.toLowerCase() : '';
            const empCode = item.emp_code ? item.emp_code.toLowerCase() : '';
            const reportMngr = item.emp_code ? item.report_mngr.toLowerCase() : '';
            const rpt_Mgr_Status = item.rpt_mgr_status ? item.rpt_mgr_status.toLowerCase() : '';
            const travel_Manager = item.travel_manager ? item.travel_manager.toLowerCase() : '';
            const travel_mngr_status = item.travel_mngr_status ? item.travel_mngr_status.toLowerCase() : '';

            // Safeguard: Ensure values are strings before calling `.toLowerCase()`
            const compName = item.comp_name ? item.comp_name.toLowerCase() : '';
            const createdOn = item.created_on ? item.created_on.toLowerCase() : '';
            const transCode = item.trans_code ? item.trans_code.toLowerCase() : '';
            const accountsApprovedAmount = item.accounts_approved_amount ? item.accounts_approved_amount.toLowerCase() : '';
            const managerApprovedAmount = item.manager_approved_amount ? item.manager_approved_amount.toLowerCase() : '';

            // Perform the filtering logic
            const matchesSearch = compName.includes(lowerCaseQuery) ||
                travel_mngr_status.includes(lowerCaseQuery) ||
                transCode.includes(lowerCaseQuery) ||
                createdOn.includes(lowerCaseQuery) ||
                travel_Manager.includes(lowerCaseQuery) ||
                rpt_Mgr_Status.includes(lowerCaseQuery) ||
                reportMngr.includes(lowerCaseQuery) ||
                empCode.includes(lowerCaseQuery) ||
                travelDate.includes(lowerCaseQuery) ||
                fromCityName.toLowerCase().includes(lowerCaseQuery) ||
                toCityName.toLowerCase().includes(lowerCaseQuery) ||
                accountsApprovedAmount.includes(lowerCaseQuery) ||
                managerApprovedAmount.includes(lowerCaseQuery);
            const matchesEmployee = selectedEmployee.length === 0 || selectedEmployee.includes(item.emp_code);
            const matchesCity = selectedCities.length === 0 || selectedCities.includes(fromCityName) || selectedCities.includes(toCityName);
            const matchesDateRange = (
				(!startDate || new Date(item.created_on).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0)) &&
				(!endDate || new Date(item.created_on).setHours(23, 59, 59, 999) <= new Date(endDate).setHours(23, 59, 59, 999))
			);
            return matchesSearch && matchesCity && matchesDateRange && matchesEmployee;
        });

        // Sort the filtered items
        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1);  // Reset to first page when filter changes
    }, [searchQuery, selectedCities, selectedEmployee, items, allCities, startDate, endDate, sortColumn, sortOrder]);


    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            fromCity: allCities.find(city => city.id === item.from_city)?.city_name || '',
            toCity: allCities.find(city => city.id === item.to_city)?.city_name || '',
            CompleteName: item.comp_name,
            Employeecode: item.emp_code,

           
            ReportingManager: item.report_mngr,
         
            TravelManager:item.travel_manager,
            ReportingManagerStatus: item.rpt_mgr_status,
            TravelMngrStatus: item.travel_mngr_status,
            Date: item.created_on
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Advance');
        XLSX.writeFile(workbook, 'Travel Reports.xlsx');
    };

    const handleSearchChange1 = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query);

        // Filter cities based on the search query in the dropdown
        const filtered = allCities.filter(city => city.city_name.toLowerCase().includes(query));
        setCities(filtered);
    };

    const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = e => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (column === "id") {
            if (sortColumn === column) {
                setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            } else {
                setSortColumn(column);
                setSortOrder("asc");
            }
        }
    };

    const handleCheckboxChange1 = (emp_code) => {
        setSelectedEmployee((prevSelectedEmployee) => {
            if (prevSelectedEmployee.includes(emp_code)) {
                return prevSelectedEmployee.filter(emp => emp !== emp_code);  // Remove if already selected
            } else {
                return [...prevSelectedEmployee, emp_code];  // Add if not selected
            }
        });
    };

    const handleSearchChange2 = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query);

        const filtered = Reportee.filter((employee) =>
            employee.comp_name.toLowerCase().includes(query) || // Search by comp_name
            employee.emp_code.toLowerCase().includes(query) 
        );
        setReportee(filtered);  // Update the reportee list as per the search query
    };

    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };
    const toggleDropdown1 = () => {
        setIsOpen1(prevState => !prevState);
    };

    return (
        <>
            <div className='hold-transition sidebar-mini layout-fixed' style={{ fontSize: '12px' }}>
                <div className='wrapper'>
                    <Header />
                    <Sidebar />
                    <div className='content-wrapper'>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4 className="m-0" style={{ fontSize: '20px' }}>Travel Reports</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='content'>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <section className='col-lg-12 connectedSortable'>
                                        <div className='card'>
                                            <div className="card-header">
                                                <button
                                                    className="btn btn-success"
                                                    style={{ float: 'right' }}
                                                    onClick={exportToExcel}
                                                >
                                                    Export to Excel
                                                </button>
                                            </div>
                                            <div className='card-body'>
                                                {loading ? (
                                                    <Loader />
                                                ) : (
                                                    <>

                                                        {/* Cities Dropdown */}
                                                        <div className="row">
                                                            {/* Items Per Page Selector */}
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <select
                                                                        className="form-control custom-select-sm"
                                                                        value={itemsPerPage}
                                                                        onChange={handleItemsPerPageChange}
                                                                    >
                                                                        <option value={10}>10</option>
                                                                        <option value={50}>50</option>
                                                                        <option value={100}>100</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                           
                                                            <div className="col-md-2">
                                                                <div className="form-group">

                                                                    <DatePicker
                                                                        selected={startDate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                        selectsStart
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        dateFormat="yyyy-MM-dd"
                                                                        className="form-control"
                                                                        placeholderText="Select Start Date"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2">
                                                                <div className="form-group">

                                                                    <DatePicker
                                                                        selected={endDate}
                                                                        onChange={(date) => setEndDate(date)}
                                                                        selectsEnd
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        minDate={startDate}
                                                                        dateFormat="yyyy-MM-dd"
                                                                        className="form-control"
                                                                        placeholderText="Select End Date"
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <div className="dropdown">
                                                                        <button
                                                                            className="btn btn-secondary dropdown-toggle form-control"
                                                                            type="button"
                                                                            id="dropdownMenuButton1"
                                                                            aria-expanded="false"
                                                                            onClick={toggleDropdown}
                                                                        >
                                                                            {selectedCities.length === 0
                                                                                ? 'Select Cities'
                                                                                : `${selectedCities.length} Cities Selected`}
                                                                        </button>

                                                                        {/* Dropdown Menu */}
                                                                        {isOpen && (
                                                                            <div
                                                                                className="dropdown-menu show"
                                                                                style={{
                                                                                    maxHeight: '300px', // Max height to show only 10 items
                                                                                    overflowY: 'auto', // Enables scroll if items exceed 10
                                                                                    width: '100%',
                                                                                }}
                                                                                aria-labelledby="dropdownMenuButton1"
                                                                            >
                                                                                {/* Search Cities Input */}
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Search Cities"
                                                                                    value={searchQuery1}
                                                                                    onChange={handleSearchChange1}
                                                                                    style={{ marginBottom: '10px' }}
                                                                                />

                                                                                {/* Cities List */}
                                                                                {cities.length > 0 ? (
                                                                                    cities.map((city) => (
                                                                                        <div key={city.id} className="form-check">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id={`city-${city.id}`}
                                                                                                value={city.city_name}
                                                                                                checked={selectedCities.includes(city.city_name)}
                                                                                                onChange={() => handleCheckboxChange(city.city_name)}
                                                                                            />
                                                                                            <label className="form-check-label" htmlFor={`city-${city.id}`}>
                                                                                                {city.city_name}
                                                                                            </label>
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div>Loading cities...</div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 p-0">
                                                                <div className="form-group">

                                                                    <div className="dropdown">
                                                                        <button
                                                                            className="btn btn-secondary dropdown-toggle form-control"
                                                                            type="button"
                                                                            id="dropdownMenuButton"
                                                                            aria-expanded="false"
                                                                            onClick={toggleDropdown1}
                                                                        >
                                                                            {selectedEmployee.length === 0
                                                                                ? 'Select Employees'
                                                                                : `${selectedEmployee.length} Employees Selected`}
                                                                        </button>
                                                                        {isOpen1 && (
                                                                            <div
                                                                                className="dropdown-menu show"
                                                                                style={{
                                                                                    maxHeight: '300px',
                                                                                    overflowY: 'auto',
                                                                                    width: '100%',
                                                                                }}
                                                                                aria-labelledby="dropdownMenuButton"
                                                                            >
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Search Employees"
                                                                                    value={searchQuery1}
                                                                                    onChange={handleSearchChange2}
                                                                                    style={{ marginBottom: '10px' }}
                                                                                />
                                                                                {Reportee.length > 0 ? (
                                                                                    Reportee.filter(item =>
                                                                                        item.comp_name.toLowerCase().includes(searchQuery1.toLowerCase())|| 
                                                                                        item.emp_code.toLowerCase().includes(searchQuery1.toLowerCase())
                                                                                    ).map((reportee) => (
                                                                                        <div key={reportee.emp_code} className="form-check">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id={`emp-${reportee.emp_code}`}
                                                                                                value={reportee.emp_code}
                                                                                                checked={selectedEmployee.includes(reportee.emp_code)}
                                                                                                onChange={() => handleCheckboxChange1(reportee.emp_code)}
                                                                                            />
                                                                                            <label className="form-check-label" htmlFor={`emp-${reportee.emp_code}`}>
                                                                                                {`${reportee.comp_name} (${reportee.emp_code})`}
                                                                                            </label>
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div>Loading employees...</div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                             {/* Search Input */}
                                                             <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <div id="example1_filter" className="dataTables_filter">
                                                                        <label>
                                                                            <input
                                                                                type="search"
                                                                                value={searchQuery}
                                                                                onChange={handleSearchChange}
                                                                                className="form-control form-control-sm"
                                                                                placeholder="Search"
                                                                                aria-controls="example1"
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-responsive">
                                                            <table className='table table-bordered table-striped'>
                                                                <thead>
                                                                    <tr>
                                                                        <th onClick={() => handleSort("id")}>
                                                                            ID {sortColumn === "id" && (sortOrder === "asc" ? "▲" : "▼")}
                                                                        </th>
                                                                        <th>Trans Code</th>
                                                                        <th>From City</th>
                                                                        <th>To City</th>
                                                                        <th>Travel Date</th>
                                                                        <th>Emp Code</th>
                                                                        <th>Reporting Manager</th>
                                                                        <th>Report Manager Status</th>
                                                                        <th>Travel Manager</th>
                                                                        <th>Travel Manager Status</th>
                                                                        <th>Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {currentItems.length > 0 ? (
                                                                        currentItems.map((item, index) => {
                                                                            const fromCity = allCities.find(city => city.id === item.from_city)?.city_name || 'Unknown City';
                                                                            const toCity = allCities.find(city => city.id === item.to_city)?.city_name || 'Unknown City';
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                                    <td>{item.trans_code}</td>
                                                                                    <td>{fromCity}</td>
                                                                                    <td>{toCity}</td>
                                                                                    <td>{item.travel_date}</td>
                                                                                    <td>{item.emp_code}</td>
                                                                                    <td>{item.report_mngr}</td>
                                                                                    <td style={{
                                                                                        color: item.rpt_mgr_status === 'Pending' ? 'orange' :
                                                                                            item.rpt_mgr_status === 'Rejected' ? 'red' :
                                                                                                item.rpt_mgr_status === 'Approved' ? 'green' :
                                                                                                    'inherit'
                                                                                    }}>
                                                                                        {item.rpt_mgr_status}
                                                                                    </td>
                                                                                    <td>{item.travel_manager ? item.travel_manager : "Need to Approval by Travel Manager To Display"}</td>
                                                                                    <td style={{
                                                                                        color: item.travel_mngr_status === 'Pending' ? 'orange' :
                                                                                            item.travel_mngr_status === 'Rejected' ? 'red' :
                                                                                                item.travel_mngr_status === 'Approved' ? 'green' :
                                                                                                    'inherit'
                                                                                    }}>
                                                                                        {item.travel_mngr_status}
                                                                                    </td>
                                                                                    <td>{item.created_on}</td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan='10'><center>No data available</center></td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <nav>
                                            <ul className='pagination' style={{ float: "right" }}>
                                                <li className='page-item'>
                                                    <button
                                                        className='page-link'
                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                        disabled={currentPage === 1}>
                                                        Previous
                                                    </button>
                                                </li>
                                                {[...Array(totalPages).keys()].map(pageNumber => (
                                                    <li key={pageNumber} className={`page-item ${pageNumber + 1 === currentPage ? "active" : ""}`}>
                                                        <button className='page-link' onClick={() => handlePageChange(pageNumber + 1)}>
                                                            {pageNumber + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                                <li className='page-item'>
                                                    <button
                                                        className='page-link'
                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                        disabled={currentPage === totalPages}>
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                        <div className='mb-3'>
                                            Showing {startItem} to {endItem} of {filteredItems.length} entries
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default ManagerTravelReports;
