import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import useAuth from './useAuth';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Select from 'react-select';
import Footer from './Footer.js';
import './Loader.css';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const Loader = () => (
//     <div style={{ textAlign: 'center', marginTop: '20px' }}>
//         <div className="spinner-border" role="status">
//             <span className="sr-only">Loading...</span>
//         </div>
//         <p>Loading...</p>
//     </div>
// );

const Loader = () => (
    <div className="loader-container1">
        <div className="spinner1"></div>
        <p>Loading...</p>
    </div>
);



const AddAdvanceForm = () => {
    const [RptMgr, setRptMngr] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCustomInput, setShowCustomInput] = useState(false);
    useAuth();
    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        // Get hours and minutes
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? String(hours).padStart(2, '0') : '12'; // Handle 0 as 12

        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    };
    const [EmpCode, setEmpCode] = useState('');
    const [items, setItems] = useState([]);
    const [purposes, setPurposes] = useState([]);

    const [AdvanceStatus, setAdvanceStatus] = useState([]);
    const [error, setError] = useState('');
    const [error1, setError1] = useState('');
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        comp_name: '',
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        password: '', // Do not display the password
        purpose: '',
        customPurpose: '',
        mode_of_travel: '',
        travel_advance: '',
        travel_advance_amount: '',
        days: '',
        city: '',
        hotel_tickets: '',
        travel_tickets: '',
        to_city: '',
        guesthouse: '',
        bank_acnum: '',
        bank_name: '',
        ifsc_code: '',

    });
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();

    const validateFormData = () => {
        const { comp_name, emp_code, grade } = formData;
        if (!comp_name | !emp_code | !grade) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };
    const cityOptions = items.map(city => ({
        value: city.city_name,
        label: city.city_name
    }));
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (name === 'purpose') {
            setShowCustomInput(value === 'Others'); // Show custom input if "Others" is selected
        }

        const newValue = name === 'bank_details' ? value.toUpperCase() : value;

        setFormData(prevState => ({
            ...prevState,
            [name]: newValue,
        }));
    };
    const validateDays = () => {
        const { days } = formData;
        console.log("Days input:", days); // Log the input value

        const regex = /^(1(\.5)?|2(\.5)?|3(\.5)?|4(\.5)?|5(\.5)?|6(\.5)?|7(\.5)?|8(\.5)?|9(\.5)?|10(\.5)?|11(\.5)?|12(\.5)?|13(\.5)?|14(\.5)?|15(\.5)?|16(\.5)?|17(\.5)?|18(\.5)?|19(\.5)?|20(\.5)?|21(\.5)?|22(\.5)?|23(\.5)?|24(\.5)?|25(\.5)?|26(\.5)?|27(\.5)?|28(\.5)?|29(\.5)?|30(\.5)?)$/; // Updated regex
        if (days && !regex.test(days)) {
            toast.error('Please enter a valid number (1, 1.5, 2, 2.5, etc.)');
            return false;
        } else {
            // setError('');
            return true;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) {
            return; // Prevent multiple submissions
        }
        // Validation: Ensure customPurpose is filled if "Others" is selected
        if (showCustomInput && !formData.customPurpose) {
            setError1('Please specify the purpose.');
            return; // Prevent form submission
        }
        setIsSubmitting(true);

        // Simulate form submission (e.g., API call or any async operation)
        setTimeout(() => {
            // Reset isSubmitting after the submission is complete
            setIsSubmitting(false);

        }, 1000); // Simulating a 2-second delay for the submission process
        if (!validateFormData()) return;
        if (!validateDays()) {
            return; // Prevent submission if validation fails
        }

        // if (AdvanceStatus == 'Pending') {
        //     toast.error('Cannot add new Advance Form without settling the amount.');
        //     return; // Prevent submission if both are TravelDesk
        // }

        if (/^[CDEF]\d*$/.test(formData.grade) && formData.hotel_tickets === 'TravelDesk' && formData.travel_tickets === 'TravelDesk') {
            toast.error('Cannot Submit if both are TravelDesk.');
            return; // Prevent submission if both are TravelDesk
        }

        const formDataToSubmit = new FormData();

        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });



        try {
            const token = localStorage.getItem('authToken');

            const response = await fetch(`${API_BASE_URL}postadvance`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSubmit
            });

            const result = await response.json();
            console.log("advanceformdata", result.data);
            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({
                    comp_name: '',
                    emp_code: ''
                });
                navigate('/own_advance');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            setLoading(true); // Set loading to true at the start
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data1:", result);

                if (result.status) {
                    const { profile, travel_advance } = result;

                    setEmpCode(profile.emp_code || '');
                    setAdvanceStatus(travel_advance?.advance_status || ''); // Safely access advance_status

                    const report_mngr = profile.report_mngr || '';
                    setFormData({
                        comp_name: profile.comp_name || '',
                        emp_code: profile.emp_code || '',
                        dept_name: profile.dept_name || '',
                        designation_name: profile.designation_name || '',
                        grade: profile.grade || '',
                        travel_advance: profile.travel_advance || '',
                        travel_advance_amount: profile.travel_advance_amount || '',
                        bank_details: profile.bank_details || '',
                        days: profile.days || '',
                        // city: profile.city || '',
                        purpose: profile.purpose || '',
                        mode_of_travel: profile.mode_of_travel || '',
                        mobile: profile.mobile || '',
                        report_mngr: report_mngr,
                        password: '', // Do not display the password
                        hotel_tickets: profile.hotel_tickets || '',
                        travel_tickets: profile.travel_tickets || '',
                        bank_acnum: profile.bank_acnum || '',
                        bank_name: profile.bank_name || '',
                        ifsc_code: profile.ifsc_code || '',
                        location_name: profile.location_name || '',
                        travel_advance_data: travel_advance || {}, // Default to an empty object if null
                    });
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        fetchProfileData();
    }, []);


    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("get all cities data:", result);

                if (result.status) {
                    setItems(result.data);
                } else {
                    // setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    const currentDateTime = formatDateTime(new Date());

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');

                if (!token) return;

                const response = await fetch(`${API_BASE_URL}purposes`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setPurposes(result.data); // Set the fetched purposes
                } else {
                    // setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);

    const handleSelectChange = (selectedOption) => {
        setFormData({
            ...formData,
            city: selectedOption ? selectedOption.value : ''
        });
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">

                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Tour/Travel Advance Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/own_advance">Advance Details</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel Advance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">

                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                {loading ? (
                                    <Loader /> // Show loader if loading is true
                                ) : (
                                    <>

                                        <div className="card-body">
                                            <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                                <div className="box-body">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Name </label>
                                                                <input
                                                                    type="text"
                                                                    name="comp_name"
                                                                    id="name"
                                                                    value={formData.comp_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Name"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="emp_code"
                                                                    id="name"
                                                                    value={formData.emp_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee No/Consultant"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Department</label>
                                                                <input
                                                                    type="text"
                                                                    name="dept_name"
                                                                    id="name"
                                                                    value={formData.dept_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Designation </label>
                                                                <input
                                                                    type="text"
                                                                    name="designation_name"
                                                                    id="name"
                                                                    value={formData.designation_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Employee Band as per Category  </label>
                                                                <input
                                                                    type="text"
                                                                    name="grade"
                                                                    id="name"
                                                                    value={formData.grade}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Reporting To
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="report_mngr"
                                                                    id="name"
                                                                    value={formData.report_mngr}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Days of travel"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        

                                                       


                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Bank Ac Num </label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_acnum"
                                                                    id="name"
                                                                    value={formData.bank_acnum}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="bank_name">Bank Name </label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_name"
                                                                    id="bank_name"
                                                                    value={formData.bank_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="ifsc_code">IFSC Code </label>
                                                                <input
                                                                    type="text"
                                                                    name="ifsc_code"
                                                                    id="ifsc_code"
                                                                    value={formData.ifsc_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="row">
                                                    <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                                </label>
                                                                <input

                                                                    type="text"
                                                                    name="mobile"
                                                                    id="name"
                                                                    value={formData.mobile}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Days of travel"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                    <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Location 
                                                                </label>
                                                                <input

                                                                    type="text"
                                                                    name="mobile"
                                                                    id="name"
                                                                    value={formData.location_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Days of travel"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="mode_of_travel">Mode of Travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="mode_of_travel"
                                                                    id="mode_of_travel"
                                                                    value={formData.mode_of_travel}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select</option>
                                                                    {(formData.grade && ['E', 'F'].includes(formData.grade[0]) || formData.city && ['Kolkata', 'Gurgaon'].includes(formData.city)) ? (
                                                                        <>
                                                                            <option value='Air'>Air</option>
                                                                            <option value='Train'>Train</option>
                                                                            <option value='Bus'>Bus</option>
                                                                            <option value='Local_Taxi'>Local Taxi</option>
                                                                            <option value='Local_Auto'>Local Auto</option>
                                                                            <option value='Own_Bike'>Own Bike</option>
                                                                            <option value='Own_Car'>Own Car</option>
                                                                            <option value='Company_Car'>Company Car</option>
                                                                        </>

                                                                    ) : (
                                                                        <>
                                                                            
                                                                            <option value='Train'>Train</option>
                                                                            <option value='Bus'>Bus</option>
                                                                            <option value='Local_Taxi'>Local Taxi</option>
                                                                            <option value='Local_Auto'>Local Auto</option>
                                                                            <option value='Own_Bike'>Own Bike</option>
                                                                            {/* <option value='Own_Car'>Own Car</option> */}
                                                                            <option value='Company_Car'>Company Car</option>
                                                                        </>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                      
                                                        <div className="col-md-4">
                                                            <div className="form-group" style={{ display: 'none' }}>
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Bank details for transfer
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_details"
                                                                    id="name"
                                                                    value={formData.bank_details}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"


                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                    <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Travel Advance requisitioned
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="number"
                                                                    name="travel_advance_amount"
                                                                    id="name"
                                                                    value={formData.travel_advance_amount}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    min="1"   // Disallow zero and negative values
                                                                    step="any"    // Allows decimals, but restricts values to be above 0
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="travelAdvance">Has earlier travel advance settled <span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                    name="travel_advance"
                                                                    id="travelAdvance"
                                                                    value={formData.travel_advance}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    required
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="days">Days of travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="days"
                                                                    id="days"
                                                                    value={formData.days}
                                                                    onChange={handleChange}
                                                                    onBlur={validateDays}
                                                                    className="form-control input-font"
                                                                    placeholder="1,1.5,2,2.5,etc"
                                                                    required
                                                                    min="1" // Prevents entering 0 or negative numbers
                                                                    step="0.1"

                                                                />
                                                            </div>
                                                            {error && <p className="text-danger">{error}</p>}
                                                        </div>
                                                        {/* Display error message */}
                                                      
                                                    </div>
                                                    <div class="row">
                                                       
                                                    <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="city">Place to travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <Select
                                                                    name="to_city"
                                                                    options={cityOptions}
                                                                    value={cityOptions.find(option => option.value === formData.city)}
                                                                    onChange={handleSelectChange}
                                                                    // Added custom-select class
                                                                    placeholder="Search city..."
                                                                    isSearchable
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="hotel_tickets">Hotel Booked by
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="hotel_tickets"
                                                                    id="hotel_tickets"
                                                                    value={formData.hotel_tickets}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select a Hotel-Ticket</option>
                                                                    <option value="Self">Self</option>
                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="travel_tickets">Travel Ticket Booked by
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="travel_tickets"
                                                                    id="travel_tickets"
                                                                    value={formData.travel_tickets}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font custom-select"
                                                                >
                                                                    <option value="">Select Travel-Ticket</option>
                                                                    <option value="Self">Self</option>
                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                </select>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div class="row">
                                                    <div className='col-md-4'>
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="guesthouse">Select City Guest-House
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="guesthouse"
                                                                    id="guesthouse"
                                                                    value={formData.guesthouse}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font custom-select"
                                                                >
                                                                    <option value="">Select a Guest-House</option>
                                                                    <option value="Hyderabad">Hyderabad</option>
                                                                    <option value="Vishakapatnam">Vishakapatnam</option>
                                                                    <option value="Rajuhmundry">Rajuhmundry</option>
                                                                    <option value="Gurgaon">Gurgaon</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="purpose">
                                                                    Purpose <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                {loading ? (
                                                                    <p>Loading purposes...</p> // Loading state
                                                                ) : error ? (
                                                                    '' // Error message
                                                                ) : (
                                                                    <select
                                                                        name="purpose"
                                                                        id="purpose"
                                                                        value={formData.purpose}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        required
                                                                    >
                                                                        <option value="">Select a purpose</option>
                                                                        {purposes.map(item => (
                                                                            <option key={item.id} value={item.purpose}>
                                                                                {item.purpose}
                                                                            </option>
                                                                        ))}
                                                                        <option value="Others">Others</option> {/* Add "Others" option */}
                                                                    </select>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {showCustomInput && ( // Conditionally render custom input field
                                                            <div className="col-md-2">
                                                                <div className="form-group">

                                                                    <label style={{ fontSize: '12px' }} htmlFor="customPurpose">
                                                                        Specify Purpose <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="customPurpose"
                                                                        id="customPurpose"
                                                                        placeholder="Specify your purpose"
                                                                        value={formData.customPurpose}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                    />
                                                                    <span style={{ color: 'red' }}><p>{error1}</p></span>
                                                                </div>

                                                            </div>

                                                        )}
                                                        </div>
                                                    

                                                </div>

                                                <div className="box-footer" style={{ textAlign: 'center' }}>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success pull-right"
                                                        disabled={formData.travel_advance === 'no'}
                                                    >{isSubmitting ? 'Submitting...' : 'Submit'}</button>
                                                </div>

                                            </form>
                                            <br />



                                            <div className="col-sm-12 row" style={{ fontSize: '12px', width: '100%' }}>
                                                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                                    <b>Prepared by </b>
                                                    <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                        : {currentDateTime}
                                                    </p>
                                                </div>


                                            </div>

                                        </div>
                                    </>
                                )}
                            </div>

                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>

            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default AddAdvanceForm;
