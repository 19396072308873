import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './Dashboard.css'; // Import your existing CSS styles
import './Sidebar.css'; // Import the new Sidebar styles
//import { BASE_URL } from "./Constants.js";
import total_advance from "./total_advance.png";
import pending_advance from "./pending_advance.png";
import building from "./hotel.png";
import travel from "./travel-bag.png";

import './Loader.css';
import approval_advance from "./approved_advance.png";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const Loader = () => (
//     <div className="loader">
//         <div className="spinner-border" role="status">
//             <span className="sr-only">Loading...</span>
//         </div>
//         <p>Loading...</p>
//     </div>
// );

const Loader = () => (
    <div className="loader-container1">
      <div className="spinner1"></div>
      <p>Loading...</p>
    </div>
  );


const Manage_Plan = () => {
    const [totalAdvanceCount, setTotalAdvanceCount] = useState(0);
    const [activePlans, setActivePlans] = useState(0);
    const [plansNearExpiry, setPlansNearExpiry] = useState(0);
    const [expiredPlans, setExpiredPlans] = useState(0);

    const [totalHotelCount, setTotalHotelCount] = useState(0);
    const [activeHotelPlans, setActiveHotelPlans] = useState(0);
    const [planshotelNearExpiry, setPlanshotelNearExpiry] = useState(0);
    const [hotelExpiredPlans, setHotelExpiredPlans] = useState(0);

    const [totalTravelCount, setTotalTravelCount] = useState(0);
    const [activeTravelPlans, setActiveTravelPlans] = useState(0);
    const [planstravelNearExpiry, setPlanstravelNearExpiry] = useState(0);
    const [travelExpiredPlans, setTravelExpiredPlans] = useState(0);

    const [TotalSettlementCount, setTotalSettlementCount] = useState(0);
    const [ActiveSettlementPlans, setActiveSettlementPlans] = useState(0);
    const [PlansNearSettlementExpiry, setPlansNearSettlementExpiry] = useState(0);
    const [ExpiredSettlementPlans, setExpiredSettlementPlans] = useState(0);

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getadvancecount`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (data.status) {
                    setTotalAdvanceCount(data.totalCount);
                    setActivePlans(data.approvedCount);
                    setPlansNearExpiry(data.pendingCount);
                    setExpiredPlans(data.rejectedCount);
                } else {
                    console.error('Error fetching count data:', data.error);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCounts();
    }, []);

    useEffect(() => {
        const fetchTravelCounts = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getTravelcount`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (data.status) {
                    setTotalTravelCount(data.totalCount);
                    setActiveTravelPlans(data.approvedCount);
                    setPlanstravelNearExpiry(data.pendingCount);
                    setTravelExpiredPlans(data.rejectedCount);
                } else {
                    console.error('Error fetching count data:', data.error);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTravelCounts();
    }, []);

    useEffect(() => {
        const fetchHotelCounts = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getHotelcount`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (data.status) {
                    setTotalHotelCount(data.totalCount);
                    setActiveHotelPlans(data.approvedCount);
                    setPlanshotelNearExpiry(data.pendingCount);
                    setHotelExpiredPlans(data.rejectedCount);
                } else {
                    console.error('Error fetching count data:', data.error);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHotelCounts();
    }, []);

    useEffect(() => {
        const fetchSettlementCounts = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlementcount`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (data.status) {
                    setTotalSettlementCount(data.totalCount);
                    setActiveSettlementPlans(data.approvedCount);
                    setPlansNearSettlementExpiry(data.pendingCount);
                    setExpiredSettlementPlans(data.rejectedCount);
                } else {
                    console.error('Error fetching count data:', data.error);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSettlementCounts();
    }, []);

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0 dashboard-title" style={{ fontSize: '20px' }}>Welcome To Travel Portal</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Dashboard</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* Total Advance */}
                                    <div className="col-md-3">
                                        <div className="dashboard-tile">
                                            <div className="dashboard-image-container">
                                                <img src={total_advance} alt="Total Advance" className="dashboard-image" />
                                            </div>
                                            <div className="dashboard-info">
                                                <p className="tile-title"> Advance Transactions </p>
                                                <div className="tile-icons">
                                                    <div className="icon-container total" title={`Total Transactions: ${totalAdvanceCount}`}>
                                                    <a href="./own_advance/all"><i className="fas fa-list-ul"></i></a>
                                                    </div><div className="icon-container approved" title={`Approved Transactions: ${activePlans}`}>
                                                    <a href="./own_advance/approved"><i style={{ color: '#28a745' }} className="fas fa-check-circle"></i></a>
                                                    </div>
                                                    <div className="icon-container pending" title={`Pending Transactions: ${plansNearExpiry}`}>
                                                    <a href="./own_advance/pending"><i style={{ color: '#ff7f00' }} className="fas fa-clock"></i></a>
                                                    </div>
                                                    <div className="icon-container rejected" title={`Rejected Transactions: ${expiredPlans}`}>
                                                    <a href="./own_advance/rejected"><i style={{ color: '#dc3545' }} className="fas fa-times-circle"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Approved Advance */}
                                    <div className="col-md-3">
                                        <div className="dashboard-tile">
                                            <div className="dashboard-image-container">
                                                <img src={approval_advance} alt="Approved" className="dashboard-image" />
                                            </div>
                                            <div className="dashboard-info">
                                                <p className="tile-title">Settlement Transactions</p>
                                                <div className="tile-icons">
                                                    <div className="icon-container total" title={`Total Transactions: ${TotalSettlementCount}`}>
                                                    <a href="./own_settlement/all"><i className="fas fa-list-ul"></i></a>
                                                    </div>
                                                    <div className="icon-container approved" title={`Approved Transactions: ${ActiveSettlementPlans}`}>
                                                    <a href="./own_settlement/approved"><i style={{ color: '#28a745' }} className="fas fa-check-circle"></i></a>
                                                    </div>
                                                    <div className="icon-container pending" title={`Pending Transactions: ${PlansNearSettlementExpiry}`}>
                                                    <a href="./own_settlement/pending"><i style={{ color: '#ff7f00' }} className="fas fa-clock"></i></a>
                                                    </div>
                                                    
                                                    <div className="icon-container rejected" title={`Rejected Transactions: ${ExpiredSettlementPlans}`}>
                                                    <a href="./own_settlement/rejected"><i style={{ color: '#dc3545' }} className="fas fa-times-circle"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Pending Advance */}
                                    <div className="col-md-3">
                                        <div className="dashboard-tile">
                                            <div className="dashboard-image-container">
                                                <img src={travel} alt="Pending" className="dashboard-image" />
                                            </div>
                                            <div className="dashboard-info">
                                                <p className="tile-title">Travel Requests </p>
                                                <div className="tile-icons">
                                                    <div className="icon-container total" title={`Total Transactions: ${totalTravelCount}`}>
                                                    <a href="./manage_travel_request/all"><i className="fas fa-list-ul"></i></a>
                                                    </div>
                                                    <div className="icon-container approved" title={`Approved Transactions: ${activeTravelPlans}`}>
                                                    <a href="./manage_travel_request/approved"><i style={{ color: '#28a745' }} className="fas fa-check-circle"></i></a>
                                                    </div>
                                                    <div className="icon-container pending" title={`Pending Transactions: ${planstravelNearExpiry}`}>
                                                    <a href="./manage_travel_request/pending"><i style={{ color: '#ff7f00' }} className="fas fa-clock"></i></a>
                                                    </div>
                                                   
                                                    <div className="icon-container rejected" title={`Rejected Transactions: ${travelExpiredPlans}`}>
                                                    <a href="./manage_travel_request/rejected"><i style={{ color: '#dc3545' }} className="fas fa-times-circle"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Rejected Advance */}
                                    <div className="col-md-3">
                                        <div className="dashboard-tile">
                                            <div className="dashboard-image-container">
                                                <img src={building} alt="Rejected" className="dashboard-image" />
                                            </div>
                                            <div className="dashboard-info">
                                                <p className="tile-title">Hotel requests </p>
                                                <div className="tile-icons">
                                                    <div className="icon-container total" title={`Total Transactions: ${totalHotelCount}`}>
                                                    <a href="./manage_hotel_request/all"><i className="fas fa-list-ul"></i></a>
                                                    </div>
                                                    <div className="icon-container approved" title={`Approved Transactions: ${activeHotelPlans}`}>
                                                    <a href="./manage_hotel_request/approved"><i style={{ color: '#28a745' }} className="fas fa-check-circle"></i></a>
                                                    </div>
                                                    <div className="icon-container pending" title={`Pending Transactions: ${planshotelNearExpiry}`}>
                                                    <a href="./manage_hotel_request/pending"><i style={{ color: '#ff7f00' }} className="fas fa-clock"></i></a>
                                                    </div>
                                                    <div className="icon-container rejected" title={`Rejected Transactions: ${hotelExpiredPlans}`}>
                                                        <a href="./manage_hotel_request/rejected"><i style={{ color: '#dc3545' }} className="fas fa-times-circle"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Repeat for other categories like Travel, Hotel, and Settlement */}
                            </div>
                        </section>
                    )}
                </div>
                <Footer />
                <aside className="control-sidebar control-sidebar-dark"></aside>
            </div>
        </div>
    );
};

export default Manage_Plan;
