import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './Loader.css';
import Pagination from './Pagination.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const Loader = () => (
//     <div style={{ textAlign: 'center', marginTop: '20px' }}>
//         <div className="spinner-border" role="status">
//             <span className="sr-only">Loading...</span>
//         </div>
//         <p>Loading...</p>
//     </div>
// );

const Loader = () => (
    <div className="loader-container1">
        <div className="spinner1"></div>
        <p>Loading...</p>
    </div>
);



const OwnAdvanceForm = () => {
    const { status } = useParams();
    const location = useLocation();
    const [EmpCode, setEmpCode] = useState('');
    const [RptMgr, setRptMgr] = useState('');
    const [items, setItems] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('SysNo');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');
                    setRptMgr(result.profile.report_mngr || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchItems = async (EmpCode) => {
            try {
                const token = localStorage.getItem('authToken');

                if (!token || !EmpCode) return;

                const response = await fetch(`${API_BASE_URL}getadvance?emp_code=${encodeURIComponent(EmpCode)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setItems(result.advance);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems(EmpCode);
        }
    }, [EmpCode, navigate]);

    useEffect(() => {
        const filtered = items.filter(item => {
            const nameMatch = item.comp_name.toLowerCase().includes(searchQuery.toLowerCase());
            const transMatch = item.trans_code.toLowerCase().includes(searchQuery.toLowerCase());
            const daysMatch = item.days.toLowerCase().includes(searchQuery.toLowerCase());
            const amountMatch = item.travel_advance_amount.toLowerCase().includes(searchQuery.toLowerCase());
            const gradeMatch = item.grade.toLowerCase().includes(searchQuery.toLowerCase());

            const codeMatch = item.emp_code.toLowerCase().includes(searchQuery.toLowerCase());
            const rpt_mgr_status = item.rpt_mgr_status.toLowerCase().includes(searchQuery.toLowerCase());
            const account_status = item.account_status.toLowerCase().includes(searchQuery.toLowerCase());
            const cityMatch = item.city.toLowerCase().includes(searchQuery.toLowerCase());
            const purposeMatch = item.purpose.toLowerCase().includes(searchQuery.toLowerCase());
            const travelMatch = item.mode_of_travel.toLowerCase().includes(searchQuery.toLowerCase());
            const report_mngrMatch = item.report_mngr.toLowerCase().includes(searchQuery.toLowerCase());

            const matchesSearch = nameMatch || report_mngrMatch || gradeMatch || purposeMatch || travelMatch || cityMatch || transMatch || daysMatch || amountMatch || codeMatch || rpt_mgr_status || account_status;

            // Filter based on URL path
            console.log("path", location.pathname);
            if (location.pathname === '/own_advance/all') {
                return matchesSearch; // Show all matching items
            } else if (location.pathname === '/own_advance/pending') {
                return item.account_status === 'Pending' && matchesSearch; // Show only pending items
            } else if (location.pathname === '/own_advance/approved') {
                return item.account_status === 'Approved' && matchesSearch; // Show only pending items
            } else if (location.pathname === '/own_advance/rejected') {
                return item.rpt_mgr_status === 'Rejected' && matchesSearch; // Show only pending items
            } else if (location.pathname == "/own_advance") {
                return matchesSearch;
            }
            return false; // Default case
        });

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder, location.pathname]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        let sortedItems;
        if (column === 'sno') {
            // Sort by the order in which records appear, using array indices
            sortedItems = [...items].sort((a, b) => {
                const indexA = items.indexOf(a);
                const indexB = items.indexOf(b);
                return sortOrder === 'asc' ? indexA - indexB : indexB - indexA;
            });
        } else {
            // General sorting based on column value
            sortedItems = [...items].sort((a, b) => {
                if (a[column] < b[column]) return sortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setItems(sortedItems);
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            CompleteName: item.comp_name,
            Employeecode: item.emp_code,
            TransCode: item.trans_code,
            Grade: item.grade,
            Purpose: item.purpose,
            ReportingManager: item.report_mngr,
            Days: item.days,
            TravelAdvanceAmount: item.travel_advance_amount,
            ReportingManagerStatus: item.rpt_mgr_status,
            FinanceStatus: item.account_status,
            City: item.city,
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Advance');
        XLSX.writeFile(workbook, 'Advance_Details.xlsx');
    };

    // Function to handle delete
    const handleDelete = async (id, e) => {
        const token = localStorage.getItem('authToken');
        e.preventDefault(); // Prevent default behavior of the anchor tag

        // Show confirmation dialog
        const confirmed = window.confirm('Are you sure you want to delete this item?');
        if (!confirmed) return; // Exit if user cancels

        try {
            // Send DELETE request to API with the item id
            const response = await fetch(`${API_BASE_URL}delete_advance/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    // Add other headers if necessary (e.g., Authorization)
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the item');
            }
           
            // Optional: Display a success message or perform other actions after successful deletion
            alert('Item deleted successfully');
            window.location.reload();
            // Navigate to another page after successful deletion
            navigate(`/own_advance`);
        } catch (error) {
            console.error('Error deleting item:', error);
            alert('Failed to delete the item.');
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Tour/Travel - Advance Form</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><Link to="/dashboard">Home</Link></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Tour/Travel-Advance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                        <div className="card-header">
                                            <span style={{ float: 'right' }}>
                                                <Link to="/add_advance">
                                                    <button type="button" className="btn btn-success">Add New</button>
                                                </Link>
                                                <button type="button" className="btn btn-secondary ml-2" onClick={exportToExcel}>
                                                    Export to Excel
                                                </button>
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            {loading ? (
                                                <Loader /> // Show loader if loading is true
                                            ) : (
                                                <>
                                                    <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                        <label>
                                                            <input
                                                                type="search"
                                                                value={searchQuery}
                                                                onChange={handleSearchChange}
                                                                className="form-control form-control-sm"
                                                                placeholder="Search"
                                                                aria-controls="example1"
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <select
                                                            className="form-control custom-select-sm"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </div>

                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-striped" style={{ fontSize: '13px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th onClick={() => handleSort("sno")} style={{ cursor: 'pointer', backgroundColor: sortColumn === "sno" ? '#f8f9fa' : 'transparent' }}>
                                                                        S.No {sortColumn === "sno" && (sortOrder === "asc" ? '▲' : '▼')}
                                                                    </th>
                                                                    <th>Emp Code</th>
                                                                    <th>Trans Code</th>
                                                                    <th>Emp Name</th>
                                                                    <th>Grade</th>
                                                                    <th>Purpose</th>
                                                                    <th>Travel Mode</th>
                                                                    <th>Advance Amount</th>
                                                                    <th>Reporting Manager</th>
                                                                    <th>Days</th>
                                                                    <th>City</th>
                                                                    <th>Reporting Manager Status</th>
                                                                    <th>Finance Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {currentItems.length > 0 ? (
                                                                    currentItems.map((item, index) => (
                                                                        (item.emp_code === EmpCode || (item.report_mngr === EmpCode && item.rpt_mgr_status !== 'Rejected')) ? (
                                                                            <tr key={index}>
                                                                                <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                                <td>{item.emp_code}</td>
                                                                                <td>{item.trans_code}</td>
                                                                                <td>{item.comp_name}</td>
                                                                                <td>{item.grade}</td>
                                                                                <td>
                                                                                    {item.purpose === 'Others'
                                                                                        ? `${item.purpose} (${item.customPurpose.length > 12 ? item.customPurpose.substring(0, 12) + '...' : item.customPurpose})`
                                                                                        : item.purpose}
                                                                                </td>
                                                                                <td>{item.mode_of_travel}</td>
                                                                                <td>
                                                                                    {item.accounts_approved_amount ? item.accounts_approved_amount : "Need to approval by finance"}
                                                                                </td>

                                                                                <td>{item.report_mngr}</td>
                                                                                <td>{item.days}</td>
                                                                                <td>{item.city}</td>
                                                                                <td style={{ color: item.rpt_mgr_status === 'Pending' ? 'orange' : item.rpt_mgr_status === 'Rejected' ? 'red' : item.rpt_mgr_status === 'Approved' ? 'green' : 'inherit' }}>
                                                                                    {item.rpt_mgr_status}
                                                                                </td>
                                                                                <td style={{ color: item.account_status === 'Pending' ? 'orange' : item.account_status === 'Approved' ? 'green' : item.account_status === 'Rejected' ? 'red' : 'inherit' }}>
                                                                                    {item.account_status}
                                                                                </td>
                                                                                <td>
                                                                                    <Link to={`/edit_advance_form/${item.id}`}>
                                                                                        <i className="fa fa-edit" style={{ fontSize: '12px', color: 'green', marginRight: '10px' }}></i>
                                                                                    </Link>
                                                                                    {/* {item.account_status === 'Pending' && (<a href="#" onClick={(e) => handleDelete(item.id, e)}>
                                                                                        <i className="fa fa-times" style={{ fontSize: '12px', color: 'red' }}></i>
                                                                                    </a>)} */}
                                                                                </td>

                                                                            </tr>
                                                                        ) : null
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                handlePageChange={handlePageChange}
                                            />
                                    <div className="mb-3">
                                        Showing {startItem} to {endItem} of {filteredItems.length} entries
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default OwnAdvanceForm;
