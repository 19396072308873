import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './AddSettlement.css';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const AddSettlementForm = () => {
    const { id } = useParams();
    const [items, setItems] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [EmpCode, setEmpCode] = useState('');
    const [Grade, setGrade] = useState('');
    const [grades, setGrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [Amount, setAmount] = useState('');
    const [purposes, setPurposes] = useState([]);
    const [Purpose, setPurpose] = useState('');
    const [CustomPurpose, setCustomPurpose] = useState('');
    const [error, setError] = useState('');
    const [currentRowId, setCurrentRowId] = useState(null);
    const [currentInput, setCurrentInput] = useState('');
    const [employeeRows, setEmployeeRows] = useState([{ id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade1: '', mobile: '' }]);
    const [travelRows, setTravelRows] = useState([{ id: Date.now(), date: '', time: '', tourPlace: '', fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', hotel_amount: '', ticket_travel_booked_by: '', ticket_travel_amount: '', perDiem: '', perDiem1: '', local: '', misc: '', totalAmount: '' }]);
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [empCodes, setEmpCodes] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();
    const [Hotel, setHotel] = useState('');
    const [Diem, setDiem] = useState('');
    const [localConveyanceTotals, setLocalConveyanceTotals] = useState({});
    const [formData, setFormData] = useState({ days: '', travel_amount: 0, Group: 'Shared', bank_acnum: '', bank_name: '', ifsc_code: '' });
    const [rows, setRows] = useState([{ id: Date.now(), fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', ticket_travel_booked_by: '', hotel_amount: 0, ticket_travel_amount: 0, misc: 0, perDiem: 0, perDiem1: 0, local: 0, totalAmount: 0 }]);
    const [documentsRows, setDocumentsRows] = useState([]);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

                const result = await response.json();
                if (result.status) {
                    setItems(result.data);
                } else {
                    toast.error(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                toast.error('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    const handleChange = (id, event) => {
        const { name, value } = event.target;
        const updatedRows = travelRows.map(row => row.id === id ? { ...row, [name]: value } : row);

        if (name === 'days') {
            const days = parseFloat(value) || 0;
            const firstRow = updatedRows[0];

            // Reset perDiem and perDiem1
            if (firstRow) {
                firstRow.perDiem = 0;
                firstRow.perDiem1 = 0;
                firstRow.toPlace = '';
            }

            const empSize = (employeeRows.length + 1);



            // Reset 'toPlace' (city) to an empty string or default value
            setFormData(prevData => ({
                ...prevData,
                [name]: value,  // Update 'days' value
                toPlace: ''     // Reset 'toPlace'
            }));

        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }

        if (name === 'groupSelf') {
            if (value === 'Self') {
                navigate('/add_settlement'); // Adjust the path as necessary
            } else if (value === 'Shared') {
                navigate('/add_settlement_group'); // Adjust the path as necessary
            }
        }

        // Update the state
        setRows(updatedRows); // Update rows state
    };


    const [transportRows, setTransportRows] = useState([{ id: 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);
    const handleAddTransportRow = () => {
        setTransportRows([...transportRows, { id: transportRows.length + 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);
        updateLocalConveyanceTotals();
    };
    const totalLocalConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    const handleTransportChange = (index, e) => {
        const { name, value, type, files } = e.target;
        const newTransportRows = [...transportRows];

        // Update the transport row with either file or other data
        if (type === 'file' && files.length > 0) {
            newTransportRows[index] = { ...newTransportRows[index], [name]: files[0] }; // Store the file object
        } else {
            newTransportRows[index] = { ...newTransportRows[index], [name]: value }; // Update other values
        }

        setTransportRows(newTransportRows);


    };

    const handleRemoveTransportRow = (index) => {
        if (transportRows.length === 1) return; // Prevent removal if it's the only row
        const updatedRows = transportRows.filter((_, i) => i !== index).map((row, i) => ({ ...row, id: i + 1 }));
        setTransportRows(updatedRows);
        updateLocalConveyanceTotals();
    };
    const updateLocalConveyanceTotals = () => {
        const totals = {};
        transportRows.forEach((row) => {
            if (row.travelDate) {
                totals[row.travelDate] = (totals[row.travelDate] || 0) + (parseFloat(row.travelAmount) || 0);
            }
        });
        setLocalConveyanceTotals(totals);
    };
 

    const addRow = () => {
        const newRow = { id: Date.now(), fromPlace: '', toPlace: '', hotel_amount: 0, ticket_travel_amount: 0, misc: 0, perDiem: 0 };
        setRows([...rows, newRow]);
    };

    const removeRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };


    const grandTotal = () => {
        const totalHotelAmount = travelRows.reduce((total, row) => total + (parseFloat(row.hotel_amount) || 0), 0);
        const totalTravelAmount = travelRows.reduce((total, row) => total + (parseFloat(row.ticket_travel_amount) || 0), 0);
        const totalMisc = travelRows.reduce((total, row) => total + (parseFloat(row.misc) || 0), 0);
        const totalPerDiem = travelRows.reduce((total, row) => total + (parseFloat(row.perDiem) || 0), 0);
        // Calculate total per diem based on booking method
        

        return totalHotelAmount + totalTravelAmount + totalMisc + totalPerDiem + totalLocalConveyance;
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data:", result); // Log to check fetched data

                if (result.status) {
                    const { profile, travel_advance } = result;

                    // Set default values from profile
                    setEmpCode(profile.emp_code || '');
                    setGrade(profile.grade || '');
                    setFormData(prevData => ({
                        ...prevData,
                        comp_name: profile.comp_name || '',
                        emp_code: profile.emp_code || '',
                        dept_name: profile.dept_name || '',
                        designation_name: profile.designation_name || '',
                        grade: profile.grade || '',
                        report_mngr: profile.report_mngr || '',
                        mobile: profile.mobile || '', // Do not display the password
                        bank_acnum: profile.bank_acnum || '',
                        bank_name: profile.bank_name || '',
                        ifsc_code: profile.ifsc_code || '',
                        location_name: profile.location_name || '',
                        // other fields...
                    }));

                    // Set travel advance values conditionally
                    setPurpose(travel_advance.purpose || '');
                    setCustomPurpose(travel_advance.customPurpose || '');
                    setAmount(travel_advance.accounts_approved_amount || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);


    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), date: '', time: '', tourPlace: '', fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', hotel_amount: '', ticket_travel_booked_by: '', ticket_travel_amount: '', perDiem: '', local: '', misc: '', totalAmount: '' }]);
    };

    const handleRemoveRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const validateDays = () => {
        const { days } = formData;
        console.log("Days input:", days); // Log the input value

        const regex = /^(1|1\.5|2|2\.5|3|3\.5|4|4\.5|5|5\.5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10)$/; // Updated regex
        if (days && !regex.test(days)) {
            toast.error('Please enter a valid number (1, 1.5, 2, 2.5, etc.)');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const handleSelectCode = (code) => {

        setRows(prevRows =>
            prevRows.map(row => (row.id === currentRowId ? { ...row, emp_code: code } : row))
        );
        setCurrentInput(code);
        setFilteredCodes([]);
        fetchEmployeeDetails(code);
    };

    const handleRowChange = (id, e) => {
        const { name, value } = e.target;
        setRows(prevRows =>
            prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
        );
        if (name === 'emp_code') {
            const emp_code = parseFloat(value) || 0;
            setCurrentRowId(id);
            setCurrentInput(value);
            setFilteredCodes(empCodes.filter(code => code.includes(value)));
        }
    };




    const fetchEmployeeDetails = async (empCode) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}users?emp_code=${empCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            if (result.status) {
                const fetchedGrade = result.user.grade;
                console.log("fetchedGrade", fetchedGrade);

                // Update the rows with the fetched employee details
                setEmployeeRows(prevRows => {
                    return prevRows.map(row => row.emp_code === empCode ? {
                        ...row,
                        comp_name: result.user.comp_name,
                        dept_name: result.user.dept_name,
                        designation_name: result.user.designation_name,
                        grade1: fetchedGrade,
                        mobile: result.user.mobile,
                    } : row);
                });

                // Optionally update the grades state
                setGrades(prevGrades => {
                    if (!prevGrades.includes(fetchedGrade)) {
                        return [...prevGrades, fetchedGrade];
                    }
                    return prevGrades;
                });
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch employee details');
        }
    };

    // Example function to get all grades as a single object
    const getCombinedGradesObject = () => {
        return grades.reduce((acc, grade, index) => {
            acc[`grade${index + 1}`] = grade; // Create keys like grade1, grade2, etc.
            return acc;
        }, {});
    };

    // Usage example:
    const combinedGrades = getCombinedGradesObject();
    const firstChars = Object.values(combinedGrades).map(grade => grade.charAt(0));

    console.log(firstChars);
    console.log("Combined Grades Object:", firstChars);

    useEffect(() => {
        if (EmpCode) {
            fetchEmployeeDetails(EmpCode);
        }
    }, [EmpCode]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');

                if (!token) return;

                const response = await fetch(`${API_BASE_URL}purposes`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setPurposes(result.data); // Set the fetched purposes
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);

    const handlePurposeChange = (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            purpose: value, // Update the purpose in formData
        }));
    };

    const handleAddDocument = (e) => {
        const files = Array.from(e.target.files);
        const newDocumentsRows = files.map((file, index) => ({
            id: documentsRows.length + index,
            file: file
        }));
        setDocumentsRows(prevRows => [...prevRows, ...newDocumentsRows]);
    };

    const handleRemoveDocument = (index) => {
        const updatedDocumentsRows = documentsRows.filter((_, i) => i !== index);
        setDocumentsRows(updatedDocumentsRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) {
            return; // Prevent multiple submissions
        }
        setIsSubmitting(true);

        // Simulate form submission (e.g., API call or any async operation)
        setTimeout(() => {
            // Reset isSubmitting after the submission is complete
            setIsSubmitting(false);

        }, 2000); // Simulating a 2-second delay for the submission process
        if (!validateDays()) {
            return; // Prevent submission if validation fails
        }
        let isValid = true; // Flag to track form validity

        // Check file sizes in transportRows
        for (const [index, row] of transportRows.entries()) {
            if (row.travelAmount > 300 && !row.receipt) {
                toast.error(`Receipt is mandatory for travel amount greater than 300 for the entry from ${row.origin} to ${row.destination}.`);
                isValid = false; // Invalid if receipt is missing
                break; // Stop further checks
            }

            // Check the file size if receipt exists
            if (row.receipt) {
                const file = row.receipt;
                const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes

                if (file.size > MAX_SIZE) {
                    toast.error(`File for Local Conveyance row ${index + 1} exceeds the 2MB limit. Please upload a smaller file.`);
                    isValid = false; // Invalid if file size exceeds 2MB
                    break; // Stop further checks
                }
            }
        }

        // Check file sizes in documentsRows
        if (isValid) { // Only proceed with documentsRows validation if transportRows are valid
            for (const [index, doc] of documentsRows.entries()) {
                if (doc.file) {
                    const file = doc.file;
                    const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes

                    if (file.size > MAX_SIZE) {
                        toast.error(`File for Additional Document row ${index + 1} exceeds the 2MB limit. Please upload a smaller file.`);
                        isValid = false; // Invalid if file size exceeds 2MB
                        break; // Stop further checks
                    }
                }
            }
        }

        // Check file sizes for hotel_receipt and ticket_receipt in rows
        if (isValid) { // Only proceed with rows validation if previous checks are valid
            for (const [index, row] of travelRows.entries()) {
                // Validate hotel_receipt file size
                if (row.hotel_receipt) {
                    const file = row.hotel_receipt;
                    const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes

                    if (file.size > MAX_SIZE) {
                        toast.error(`Hotel receipt file for row ${index + 1} exceeds the 2MB limit. Please upload a smaller file.`);
                        isValid = false; // Invalid if file size exceeds 2MB
                        break; // Stop further checks
                    }
                }

                // Validate ticket_receipt file size
                if (row.ticket_receipt) {
                    const file = row.ticket_receipt;
                    const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes

                    if (file.size > MAX_SIZE) {
                        toast.error(`Ticket receipt file for row ${index + 1} exceeds the 2MB limit. Please upload a smaller file.`);
                        isValid = false; // Invalid if file size exceeds 2MB
                        break; // Stop further checks
                    }
                }
            }
        }

        // If any validation failed, stop submission
        if (!isValid) {
            return; // Prevent form submission if validation fails
        }
        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });


        employeeRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSubmit.append(`employeeRows[${index}][${key}]`, row[key]);
            });
        });

        travelRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSubmit.append(`travelRows[${index}][${key}]`, row[key]);
            });
        });

        transportRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSubmit.append(`transportRows[${index}][${key}]`, row[key]);
            });
        });

        // Append documents as documentsRow[n]additional_doc
        documentsRows.forEach((doc, index) => {
            formDataToSubmit.append(`documentsRows[${index}]`, doc.file);
        });

        // Calculate grand totals
        const grandTotalHotel = travelRows.reduce((total, row) => total + (parseFloat(row.hotel_amount) || 0), 0);
        const grandTotalTicket = travelRows.reduce((total, row) => total + (parseFloat(row.ticket_travel_amount) || 0), 0);
        // const grandTotalPerDiem = rows.reduce((total, row) => total + (parseFloat(row.perDiem) || 0), 0);
        const grandTotalPerDiem = rows.reduce((total, row) => total + (parseFloat(row.perDiem)));

        const grandTotalLocal = totalLocalConveyance;
        const grandTotalMisc = travelRows.reduce((total, row) => total + (parseFloat(row.misc) || 0), 0);

        // Calculate grandTotalAmount for each row
        const grandTotalAmount = travelRows.reduce((total, row, index) => {
         

            // Calculate total for the row by summing all the amounts with default values
            return total + (
                parseFloat(row.perDiem || 0) +
                parseFloat(row.hotel_amount || 0) +
                parseFloat(row.ticket_travel_amount || 0) +
                parseFloat(row.misc || 0) +
                (index === 0 ? parseFloat(totalLocalConveyance || 0) : 0) // Include totalLocalConveyance only for the first row
            );
        }, 0);

        // Debugging the result

        // Travel advance amount
        const travelAdvanceAmount = parseFloat(formData.travel_amount) || 0;

        // Adjust the total amount after subtracting travel advance
        const adjustedTotalAmount = grandTotalAmount - travelAdvanceAmount;

        // Debugging alert to check the calculated grandTotalAmount
        // Append the calculated values to formDataToSubmit
        formDataToSubmit.append('grandTotalHotel', grandTotalHotel); // Assuming grandTotalHotel is already calculated
        formDataToSubmit.append('grandTotalTicket', grandTotalTicket); // Assuming grandTotalTicket is already calculated
        formDataToSubmit.append('grandTotalPerDiem', grandTotalPerDiem); // Assuming grandTotalPerDiem is already calculated
        formDataToSubmit.append('grandTotalLocal', grandTotalLocal); // Assuming totalLocalConveyance was grandTotalLocal
        formDataToSubmit.append('grandTotalMisc', grandTotalMisc);
        formDataToSubmit.append('already_taken_advance', formData.already_taken_advance);
        formDataToSubmit.append('city', travelRows[0].toPlace);
        { formDataToSubmit.append('purpose', Purpose) }

        { formDataToSubmit.append('travel_amount', Amount) }

        //travel_amount

        // Adjust the grandTotalAmount to include local conveyance
        formDataToSubmit.append('grandTotalAmount', grandTotalAmount);


        // Final total amount after all deductions and adjustments

        formDataToSubmit.append('TotalAmount', grandTotalAmount)

        // Debugging logs
        const formDataObject = {};
        formDataToSubmit.forEach((value, key) => {
            console.log(`${key}: ${value}`);
            if (formDataObject[key]) {
                // If the key exists, convert it to an array if it's not already
                if (!Array.isArray(formDataObject[key])) {
                    formDataObject[key] = [formDataObject[key]];
                }
                // Push the new value to the array
                formDataObject[key].push(value);
            } else {
                formDataObject[key] = value;
            }
        });
        console.log('Form Data to Submit:', JSON.stringify(formDataObject, null, 2));

        try {
            const token = localStorage.getItem('authToken');
            console.log("checktoken", token);

            const response = await fetch(`${API_BASE_URL}postSettlementGroup`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSubmit
            });

            const result = await response.json();
            console.log("Response Data:", result);

            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({ emp_code: '' }); // Reset other fields as necessary
                navigate('/own_settlement');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    const handleAddEmpRow = () => {
        setEmployeeRows([...employeeRows, { id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade1: '', mobile: '' }]);
    };

    const handleRemoveEmpRow = (id) => {
        if (employeeRows.length > 1) {
            setEmployeeRows(prevRows => prevRows.filter(row => row.id !== id));
        }
    };

    const handleAddTravelRow = () => {
        setTravelRows([...travelRows, { id: Date.now(), date: '', time: '', tourPlace: '', fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', hotel_amount: '', ticket_travel_booked_by: '', ticket_travel_amount: '', perDiem: '', local: '', misc: '', totalAmount: '' }]);
    };

    const handleRemoveTravelRow = (id) => {
        if (travelRows.length > 1) {
            setTravelRows(prevRows => prevRows.filter(row => row.id !== id));
        }
    };

    const handleEmpRowChange = (id, e) => {
        const { name, value } = e.target;
        setEmployeeRows(prevRows => prevRows.map(row => row.id === id ? { ...row, [name]: value } : row));
        if (name === 'emp_code') {
            const emp_code = parseFloat(value) || 0;
            setCurrentRowId(id);
            setCurrentInput(value);
            setFilteredCodes(empCodes.filter(code => code.includes(value)));
        }
    };

    const handleTravelRowChange = (id, e) => {
        const { name, value, type, files } = e.target;
        // Determine the new value based on input type
        const newValue = type === 'file' && files.length ? files[0] :
            (name === 'hotel_amount' || name === 'ticket_travel_amount' || name === 'misc' ? parseFloat(value) || 0 : value);

        // Update the rows
        setTravelRows(prevRows => prevRows.map(row =>
            row.id === id ? { ...row, [name]: newValue } : row
        ));
    };

    const handleGuesthouseChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        // You can add any additional logic related to the guesthouse here.
    };

    const handleTravelTypeChange = (id, event) => {
        const { name, value } = event.target;

        const updatedRows = travelRows.map(row =>
            row.id === id ? { ...row, [name]: value } : row
        );

        setTravelRows(updatedRows);
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Tour/Travel Settlement Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/own_settlement">Settlement Details</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel Settlement Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader /> // Show loader if loading is true
                    ) : (
                        <>
                            <section className="col-lg-12">
                                <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8" encType="multipart/form-data">
                                            <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                <div className="box-body">
                                                    <div className="row">

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Employee No
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="emp_code"
                                                                    id="name"
                                                                    value={formData.emp_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee No/Consultant"
                                                                    readonly="true"

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Name <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="comp_name"
                                                                    id="name"
                                                                    value={formData.comp_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Name"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Employee Band<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="grade"
                                                                    id="name"
                                                                    value={formData.grade}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="dept_name"
                                                                    id="name"
                                                                    value={formData.dept_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="designation_name"
                                                                    id="name"
                                                                    value={formData.designation_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Mobile No
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="mobile"
                                                                    id="name"
                                                                    value={formData.mobile}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="days">No of Days
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="days"
                                                                    id="days"
                                                                    value={formData.days}
                                                                    onChange={(e) => handleChange('days', e)}
                                                                    onBlur={validateDays}
                                                                    className="form-control input-font"
                                                                    placeholder="1,1.5,2,2.5, etc"
                                                                    required



                                                                />
                                                            </div>
                                                        </div>




                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="travelAdvance">Has advance settled <span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                    name="travel_advance"
                                                                    id="travelAdvance"
                                                                    value={formData.travel_advance}
                                                                    onChange={(e) => handleChange('travel_advance', e)}
                                                                    className="form-control input-font custom-select"
                                                                    required
                                                                >

                                                                    <option value="">Select</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="already_taken_advance">Have you taken advance? <span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                    name="already_taken_advance"
                                                                    id="already_taken_advance"
                                                                    value={formData.already_taken_advance}
                                                                    onChange={(e) => handleChange('already_taken_advance', e)}
                                                                    className="form-control input-font"
                                                                    required
                                                                >

                                                                    <option value="">Select</option>
                                                                    <option value="Y">Yes</option>
                                                                    <option value="N">No</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        {/* {Amount > 0 && (
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="travelAmount">Travel Advance Amount <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="number"
                                                                        name="travel_amount"
                                                                        id="travelAmount"
                                                                        value={Amount}
                                                                        onChange={(e) => handleChange('travel_amount', e)} // Handle change for travel amount
                                                                        className="form-control input-font"
                                                                        required
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>)} */}
                                                        {/* <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '12px' }} htmlFor="purpose">Purpose<span style={{ color: 'red' }}>*</span></label>
                                                                        {loading ? (
                                                                            <p>Loading purposes...</p> // Loading state
                                                                        ) : error ? (
                                                                            <p>{error}</p> // Error message
                                                                        ) : (
                                                                            <select
                                                                                name="purpose"
                                                                                id="purpose"
                                                                                value={formData.purpose}
                                                                                onChange={handlePurposeChange}
                                                                                className="form-control input-font"
                                                                                required
                                                                            >
                                                                                <option value={Purpose}>{Purpose}</option>
                                                                                <option value="">Select a purpose</option>
                                                                                {purposes.map(item => (
                                                                                    <option key={item.id} value={item.purpose}>{item.purpose}</option>
                                                                                ))}
                                                                            </select>
                                                                        )}
                                                                        {Purpose === 'Others' && (
                                                                            <input
                                                                                type="text"
                                                                                name="customPurpose"
                                                                                value={CustomPurpose}
                                                                                onChange={(e) => setFormData({ ...formData, customPurpose: e.target.value })}
                                                                                placeholder="Enter custom purpose"
                                                                                className="form-control input-font mt-2" // Add margin-top for spacing
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div> */}
                                                        {/* {Amount > 0 && (
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="purpose">Purpose<span style={{ color: 'red' }}>*</span></label>
                                                                    {loading ? (
                                                                        <p>Loading purposes...</p> // Loading state
                                                                    ) : error ? (
                                                                        <p>{error}</p> // Error message
                                                                    ) : (
                                                                        <select
                                                                            name="purpose"
                                                                            id="purpose"
                                                                            value={formData.purpose}
                                                                            onChange={handlePurposeChange}
                                                                            className="form-control input-font custom-select"
                                                                            required
                                                                            disabled
                                                                        >
                                                                            <option value={Purpose}>{Purpose} {CustomPurpose}</option>
                                                                            <option value="">Select a purpose</option>
                                                                            {purposes.map(item => (
                                                                                <option key={item.id} value={item.purpose}>{item.purpose}</option>
                                                                            ))}
                                                                        </select>
                                                                    )}
                                                                </div>
                                                            </div>)} */}
                                                    
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="settlement_purpose">Settlement Purpose <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="settlement_purpose"
                                                                        id="settlement_purpose"
                                                                        value={formData.settlement_purpose}
                                                                        onChange={(e) => handleChange('settlement_purpose', e)} // Handle change for settlement purpose
                                                                        className="form-control input-font"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>

                                                       
                                                                {/* <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label htmlFor="settlement_purpose">Settlement Purpose <span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            name="settlement_purpose"
                                                                            id="settlement_purpose"
                                                                            value={formData.settlement_purpose}
                                                                            onChange={(e) => handleChange('settlement_purpose', e)} // Handle change for purpose
                                                                            className="form-control input-font"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div> */}

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Reporting Manager
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="report_mngr"
                                                                    id="name"
                                                                    value={formData.report_mngr}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Location
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.location_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Shared or Single
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <br />
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        type="radio"
                                                                        name="groupSelf" // Use a common name for grouping
                                                                        id="Shared" // Unique ID for each radio button
                                                                        value="Shared"
                                                                        checked={formData.Group === 'Shared'} // Check if Group is selected
                                                                        onChange={(e) => handleChange('groupSelf', e)}// Use handleChange to update state
                                                                        className="form-check-input"
                                                                    />
                                                                    <label htmlFor="Shared" className="form-check-label">
                                                                        Shared
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        type="radio"
                                                                        name="groupSelf" // Use the same common name for the other option
                                                                        id="Self" // Unique ID for each radio button
                                                                        value="Self"
                                                                        checked={formData.Group === 'Self'} // Check if Self is selected
                                                                        onChange={(e) => handleChange('groupSelf', e)} // Use handleChange to update state
                                                                        className="form-check-input"
                                                                    />
                                                                    <label htmlFor="Self" className="form-check-label">
                                                                        Single
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="guesthouse">Select City Guest-House
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="guesthouse"
                                                                    id="guesthouse"
                                                                    value={formData.guesthouse}
                                                                    onChange={handleGuesthouseChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select a Guest-House</option>
                                                                    <option value="Hyderabad">Hyderabad</option>
                                                                    <option value="Vishakapatnam">Vishakapatnam</option>
                                                                    <option value="Rajuhmundry">Rajuhmundry</option>
                                                                    <option value="Gurgaon">Gurgaon</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="bank_acnum">Bank Ac Num
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_acnum"
                                                                    id="bank_acnum"
                                                                    value={formData.bank_acnum}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="bank_name">Bank Name
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_name"
                                                                    id="bank_name"
                                                                    value={formData.bank_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="ifsc_code">IFSC Code
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="ifsc_code"
                                                                    id="ifsc_code"
                                                                    value={formData.ifsc_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                                    <button type="button" onClick={handleAddEmpRow} className="btn btn-primary">+</button>
                                                    <table className="expense-table">
                                                        <thead>
                                                            <tr className="header-row">
                                                                <th>Emp Code</th>
                                                                <th>Name</th>
                                                                <th>Dept</th>
                                                                <th>Designation</th>
                                                                <th>Grade</th>
                                                                <th>Mobile</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employeeRows.map(row => (
                                                                <tr key={row.id}>
                                                                    <td data-label="Emp Code">
                                                                        <input
                                                                            type="text"
                                                                            name="emp_code"
                                                                            value={row.emp_code}
                                                                            onChange={(e) => {
                                                                                handleEmpRowChange(row.id, e);
                                                                                setEmpCode(e.target.value); // Update EmpCode state to trigger API fetch
                                                                            }}
                                                                            className="form-control input-font"
                                                                            placeholder="Emp Code"
                                                                            required
                                                                        />
                                                                        {filteredCodes.length > 0 && (
                                                                            <ul className="suggestions">
                                                                                {filteredCodes.map((code) => (
                                                                                    <li key={code} onClick={() => handleSelectCode(code)}>
                                                                                        {code}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                    </td>
                                                                    <td data-label="Name">
                                                                        <input
                                                                            type="text"
                                                                            name="comp_name"
                                                                            value={row.comp_name}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Name"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Dept">
                                                                        <input
                                                                            type="text"
                                                                            name="dept_name"
                                                                            value={row.dept_name}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Dept"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Designation">
                                                                        <input
                                                                            type="text"
                                                                            name="designation_name"
                                                                            value={row.designation_name}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Designation"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Grade">
                                                                        <input
                                                                            type="text"
                                                                            name="grade"
                                                                            value={row.grade1}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Grade"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Mobile">
                                                                        <input
                                                                            type="text"
                                                                            name="mobile"
                                                                            value={row.mobile}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Mobile"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Actions">
                                                                        <button type="button" onClick={() => handleRemoveEmpRow(row.id)} className="btn btn-danger">×</button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                                    <button type="button" onClick={handleAddTravelRow} className="btn btn-primary">+</button>
                                                    <table className="expense-table">
                                                        <thead>
                                                            <tr className="header-row">
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th>Travel Type</th>
                                                                <th>From City</th>
                                                                <th>To City</th>
                                                                <th>Hotel Booked By</th>
                                                                <th>Hotel Receipt</th>
                                                                <th>Hotel Amount</th>
                                                                <th>Travel Ticket Booked By</th>
                                                                <th>Ticket Receipt</th>
                                                                <th>Travel Amount</th>
                                                                <th>Per Diem Allow</th>
                                                                
                                                                <th>Local Conveyance</th>
                                                                <th>Misc</th>
                                                                <th>Total Rs</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {travelRows.map((row, index) => (
                                                                <tr key={row.id}>
                                                                    <td data-label="Date">
                                                                        <input
                                                                            type="date"
                                                                            name="date"
                                                                            value={row.date}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            max={new Date().toISOString().split("T")[0]}
                                                                            required={index === 0}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Time">
                                                                        <input
                                                                            type="time"
                                                                            name="time"
                                                                            value={row.time}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            required={index === 0}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Travel Type">
                                                                        <select name="travelType" value={row.travelType} onChange={(e) => handleTravelTypeChange(row.id, e)} className="form-control input-font" required={index === 0}>
                                                                            <option value="">Select Travel Type</option>

                                                                            {(formData.grade && ['E', 'F'].includes(formData.grade[0])) ? (
                                                                                <>
                                                                                    <option value='Air'>Air</option>
                                                                                    <option value='Train'>Train</option>
                                                                                    <option value='Bus'>Bus</option>
                                                                                    <option value='Local_Taxi'>Local Taxi</option>
                                                                                    <option value='Local_Auto'>Local Auto</option>
                                                                                    <option value='Own_Bike'>Own Bike</option>
                                                                                    <option value='Own_Car'>Own Car</option>
                                                                                </>

                                                                            ) : (
                                                                                <>
                                                                                    <option value='Air'>Air</option>
                                                                                    <option value='Train'>Train</option>
                                                                                    <option value='Bus'>Bus</option>
                                                                                    <option value='Local_Taxi'>Local Taxi</option>
                                                                                    <option value='Local_Auto'>Local Auto</option>
                                                                                    <option value='Own_Bike'>Own Bike</option>
                                                                                    {/* <option value='Own_Car'>Own Car</option> */}
                                                                                </>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="From City">
                                                                        <select
                                                                            name="fromPlace"
                                                                            value={row.fromCity}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font custom-select"
                                                                            required={index === 0}
                                                                        >
                                                                            <option value="">Select a city</option>
                                                                            {items.map((city) => (
                                                                                <option key={city.id} value={city.id}>
                                                                                    {city.city_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="To City">
                                                                        <select
                                                                            name="toPlace"
                                                                            value={row.toPlace}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font custom-select"
                                                                            required={index === 0}
                                                                        >
                                                                            <option value="">Select a city</option>
                                                                            {items.map((city) => (
                                                                                <option key={city.id} value={city.id}>
                                                                                    {city.city_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="Booked by">
                                                                        <select name="hotel_booked_by" value={row.hotel_booked_by} onChange={(e) => handleTravelRowChange(row.id, e)} className="form-control input-font" required={index === 0}>
                                                                            <option value="">Select Booked By</option>
                                                                            <option value="Self">Self</option>
                                                                            <option value="TravelDesk">Travel Desk</option>
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="Receipt/Bill">
                                                                        <input
                                                                            type="file"
                                                                            name="hotel_receipt"
                                                                            accept="image/*,application/pdf"
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={row.hotel_booked_by === "TravelDesk"}
                                                                        />

                                                                    </td>
                                                                    <td data-label="Hotel Amount">
                                                                        <input
                                                                            type="number"
                                                                            name="hotel_amount"
                                                                            value={row.hotel_amount}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={row.hotel_booked_by === "TravelDesk"}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Booked by">
                                                                        <select name="ticket_travel_booked_by" value={row.ticket_travel_booked_by} onChange={(e) => handleTravelRowChange(row.id, e)} className="form-control input-font" required={index === 0}>
                                                                            <option value="">Select Booked By</option>
                                                                            <option value="Self">Self</option>
                                                                            <option value="TravelDesk">Travel Desk</option>
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="Receipt/Bill">
                                                                        <input
                                                                            type="file"
                                                                            name="ticket_receipt"
                                                                            accept="image/*,application/pdf"
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={row.ticket_travel_booked_by === "TravelDesk"}
                                                                        />

                                                                    </td>
                                                                    <td data-label="Travel Amount">
                                                                        <input
                                                                            type="number"
                                                                            name="ticket_travel_amount"
                                                                            value={row.ticket_travel_amount}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={row.ticket_travel_booked_by === "TravelDesk"}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Per Diem Allow">
                                                                        <input
                                                                            type="number"
                                                                            name="perDiem"
                                                                            value={
                                                                            row.perDiem
                                                                            }
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            required={index === 0}
                                                                            disabled={index !== 0} // Disable for other rows if needed
                                                                        />
                                                                    </td>
                                                                    <td data-label="Local Conveyance">
                                                                        <input
                                                                            type="number"
                                                                            name="local"
                                                                            value={index === 0 ? totalLocalConveyance : ''} // Set only in the first row
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={index !== 0} // Disable for other rows
                                                                        />
                                                                    </td>


                                                                    <td data-label="Misc">
                                                                        <input
                                                                            type="number"
                                                                            name="misc"
                                                                            value={row.misc}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                        />
                                                                    </td>
                                                                    <td data-label="Total Rs.">
                                                                        <input
                                                                            type="number"
                                                                            value={parseFloat(
                                                                                (parseFloat(row.perDiem)) +
                                                                                (parseFloat(row.hotel_amount) || 0) +
                                                                                (parseFloat(row.ticket_travel_amount) || 0) +
                                                                                (parseFloat(row.misc) || 0) +
                                                                                (index === 0 ? (parseFloat(totalLocalConveyance) || 0) : 0)
                                                                            )}
                                                                            className="form-control input-font"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" onClick={() => handleRemoveTravelRow(row.id)} className="btn btn-danger">
                                                                            <i className="fas fa-times"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                <th colSpan="14" style={{ textAlign: 'right' }}>Grand Total</th>
                                                                <th style={{ textAlign: 'center' }}>{grandTotal()}</th>
                                                                <th></th>
                                                            </tr>

                                                            {/* {Amount ? ( // Check if Amount is available
                                                                <tr>
                                                                    <th colSpan="14" style={{ textAlign: 'right' }}>Less Advance</th>
                                                                    <th style={{ textAlign: 'center' }}>{Amount}</th>
                                                                    <th></th>
                                                                </tr>
                                                            ) : null} */}
                                                            <tr>
                                                                <th colSpan="14" style={{ textAlign: 'right' }}>
                                                                    {Amount ? 'Total' : 'Total'} {/* Display Total or Grand Total based on Amount */}
                                                                </th>
                                                                <th style={{ textAlign: 'center' }}>
                                                                    {Math.trunc(grandTotal().toFixed(2))}
                                                                </th>
                                                                <th></th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </fieldset>
                                            <br />
                                            <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                <center><span className='btn btn-primary' style={{ fontSize: '12px' }}>Details of Local Conveyance</span></center>


                                                <div className="transport-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                                    <button type="button" onClick={handleAddTransportRow} className="btn btn-primary">+</button>
                                                    <table className="expense-table">
                                                        <thead>
                                                            <tr>
                                                                <td colSpan="8"></td>
                                                            </tr>
                                                            <tr className="header-row">

                                                                <th style={{ textAlign: 'center' }}>Travel Date</th>
                                                                <th style={{ textAlign: 'center' }}>Origin</th>
                                                                <th style={{ textAlign: 'center' }}>Destination</th>
                                                                <th style={{ textAlign: 'center' }}>Amount</th>
                                                                <th style={{ textAlign: 'center' }}>Transport Mode</th>
                                                                <th style={{ textAlign: 'center' }}>Receipt/Bill</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {transportRows.map((row, index) => (
                                                                <tr key={index}>

                                                                    <td data-label="Travel Date">
                                                                        <input
                                                                            type="date"
                                                                            name="travelDate"
                                                                            value={row.travelDate}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            max={new Date().toISOString().split("T")[0]}
                                                                            required={index === 0}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Origin">
                                                                        <input
                                                                            type="text"
                                                                            name="origin"
                                                                            value={row.origin}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="From"
                                                                            required={index === 0}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Destination">
                                                                        <input
                                                                            type="text"
                                                                            name="destination"
                                                                            value={row.destination}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="To"
                                                                            required={index === 0}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Amount">
                                                                        <input
                                                                            type="number"
                                                                            name="travelAmount"
                                                                            value={row.travelAmount}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            required={index === 0}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Transport Mode">
                                                                        <select
                                                                            name="transportMethod"
                                                                            value={row.transportMethod}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            required={index === 0}
                                                                        >
                                                                            <option value="">Select Mode</option>
                                                                            <option value="bus">Bus</option>
                                                                            <option value="auto">Auto</option>
                                                                            <option value="taxi">Taxi</option>
                                                                            <option value="Car">Own Vehicle-Car</option>
                                                                            <option value="Bike">Own Vehicle-Bike</option>
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="Receipt/Bill">
                                                                        <input
                                                                            type="file"
                                                                            name="receipt"
                                                                            accept="image/*,application/pdf"
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                        />
                                                                        {row.travelAmount > 300 && !row.receipt && (
                                                                            <span style={{ color: 'red' }}>Receipt is mandatory for amounts greater than 300.</span>
                                                                        )}
                                                                    </td>
                                                                    <td data-label="Actions">
                                                                        <button type="button" onClick={() => handleRemoveTransportRow(index)} className="btn btn-danger">×</button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="4" style={{ textAlign: 'right' }}>Total Amount:</td>
                                                                <td>{totalLocalConveyance}</td>
                                                                <td colSpan="3"></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </fieldset>
                                            <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                <center><span className="btn btn-primary" style={{ fontSize: '12px' }}>Additional Documents</span></center>
                                                <div className="document-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleAddDocument}
                                                        multiple
                                                        className="form-control input-font"
                                                    />
                                                    <table className="document-table" style={{ marginTop: '10px' }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'center' }}>You Can Add Multiple Document</th>
                                                                <th style={{ color: 'red', fontSize: '17px' }}>
                                                                    Make sure to provide accurate information and include all
                                                                    relevant attachments (tickets, invoices, bills, etc.) to ensure a smooth
                                                                    processing without rejection.
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {documentsRows.map((doc, index) => (
                                                                <tr key={index}>
                                                                    <td data-label="Document Name">{doc.file.name}</td>
                                                                    <td data-label="Preview">
                                                                        <img
                                                                            src={URL.createObjectURL(doc.file)}
                                                                            alt={doc.file.name}
                                                                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Action">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleRemoveDocument(index)}
                                                                            className="btn btn-danger"
                                                                        >
                                                                            ×
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </fieldset>
                                            <div className="box-footer">
                                                <br />
                                                <center><button type="submit" className="btn btn-success pull-right">Submit</button></center>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    );
};

export default AddSettlementForm;
