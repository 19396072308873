import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
//import { BASE_URL } from "./Constants.js";
import './AddSettlement.css';
import pdficon from "./pdf.png";
import icon1 from "./picture.png";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);
const EditSettlementGroupForm = () => {
    const { id } = useParams();
    const [EmpCode, setEmpCode] = useState('');
    const [TotalLocal, setTotalLocal] = useState('');
    const [PerDiem, setPerDiem] = useState('');
    const [RptMgr, setRptMngr] = useState('');
    const [currentRowId, setCurrentRowId] = useState(null);
    const [currentInput, setCurrentInput] = useState('');
    const [Amount, setAmount] = useState('');
    const [Purpose, setPurpose] = useState('');
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [localConveyanceTotals, setLocalConveyanceTotals] = useState({});
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState({
        rpt_mgr_status: '', // Initial errors can go here (empty or predefined)
    });
    const [grades, setGrades] = useState([]);
    const [empCodes, setEmpCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [employeeRows, setEmployeeRows] = useState([{ id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade1: '', mobile: '' }]);
    const [rows1, setRows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [documentsRows, setdocumentsRows] = useState([]);
    const [rows3, setRows3] = useState([]);
    const [formData, setFormData] = useState({
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        password: '', // Do not display the password,
        travel_from_date: '', // Ensure this is initialized
        travel_to_date: '',   // Ensure this is initialized
        days: '',
        rpt_mgr_status: '',
        manager_remarks: '',
        manager_approved_amount: '',
        created_on: '',
        manager_approved_date: '',
        bank_acnum: '',
         bank_name: '',
         ifsc_code: ''
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [isApproved, setIsApproved] = useState(false);
    const navigate = useNavigate();

    const validateFormData = () => {
        const { emp_code } = formData;
        if (!emp_code) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const handleAddEmpRow = () => {
        setEmployeeRows([...employeeRows, { id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade1: '', mobile: '' }]);
    };

    const handleSelectCode = (code) => {

        setRows(prevRows =>
            prevRows.map(row => (row.id === currentRowId ? { ...row, emp_code: code } : row))
        );
        setCurrentInput(code);
        setFilteredCodes([]);
        fetchEmployeeDetails(code);
    };

    const handleRemoveEmpRow = (id) => {
        if (employeeRows.length > 1) {
            setEmployeeRows(prevRows => prevRows.filter(row => row.id !== id));
        }
    };

    const fetchEmployeeDetails = async (empCode) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}users?emp_code=${empCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            if (result.status) {
                const fetchedGrade = result.user.grade;
                console.log("fetchedGrade", fetchedGrade);

                // Update the rows with the fetched employee details
                setEmployeeRows(prevRows => {
                    return prevRows.map(row => row.emp_code === empCode ? {
                        ...row,
                        comp_name: result.user.comp_name,
                        dept_name: result.user.dept_name,
                        designation_name: result.user.designation_name,
                        grade1: fetchedGrade,
                        mobile: result.user.mobile,
                    } : row);
                });

                // Optionally update the grades state
                setGrades(prevGrades => {
                    if (!prevGrades.includes(fetchedGrade)) {
                        return [...prevGrades, fetchedGrade];
                    }
                    return prevGrades;
                });
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch employee details');
        }
    };


    const updateLocalConveyanceTotals = () => {
        const totals = {};
        transportRows.forEach((row) => {
            if (row.travelDate) {
                totals[row.travelDate] = (totals[row.travelDate] || 0) + (parseFloat(row.travelAmount) || 0);
            }
        });
        setLocalConveyanceTotals(totals);
    };
    const handleSubmit = async (e) => {

        e.preventDefault();

        let formIsValid = true;
        let newErrors = {};

        // If Reporting Manager Status is Pending, show error
        if (formData.rpt_mgr_status === 'Pending') {
            formIsValid = false;
            newErrors.rpt_mgr_status = 'Please approve the status before submitting.';
        }

        if (!formIsValid) {
            setErrors(newErrors); // Update errors state if form is invalid
            return;
        }

        if (!validateFormData()) return;

        const travelAdvance = (grandTotal() - parseFloat(formData.travel_amount)) || 0;
        // if (parseFloat(formData.manager_approved_amount) > travelAdvance) {
        //     setError('Manager Approved Amount must be less than or equal to Travel amount.');
        //     return; // Prevent submission
        // }
        const approvedAmount = parseFloat(formData.manager_approved_amount) || 0;
        

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });
        const dataToSubmit = {
            ...formData
        };
        try {
            const token = localStorage.getItem('authToken');
            console.log("check tokens", token);
            const response = await fetch(`${API_BASE_URL}updateSettlementForm/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSubmit),
            });

            const result = await response.json();
            console.log("advanceformdata", result.data);
            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({
                    comp_name: '',
                    emp_code: ''
                });
                navigate('/manage_settlement');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };



    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token12", token);
                const response = await fetch(`${API_BASE_URL}getsettlement?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("Settlement data:", result);

                if (result.status) {
                    setEmpCode(result.settlement.emp_code || '');
                    setPerDiem(result.settlement.grandTotalPerDiem || '');
                    setTotalLocal(result.settlement.grandTotalLocal || '');
                    setPurpose(result.settlement.purpose || '');
                    setAmount(result.settlement.travel_amount || '');
                    const report_mngr = result.settlement.report_mngr || '';
                    const rpt_mgr_status = result.settlement.rpt_mgr_status || '';
                    setFormData({
                        comp_name: result.settlement.comp_name || '',
                        settlement_purpose: result.settlement.settlement_purpose || '',
                        emp_code: result.settlement.emp_code || '',
                        dept_name: result.settlement.dept_name || '',
                        designation_name: result.settlement.designation_name || '',
                        already_taken_advance: result.settlement.already_taken_advance || '',
                        guesthouse: result.settlement.guesthouse || '',
                        purpose: result.settlement.purpose || '',
                        mode_of_travel: result.settlement.mode_of_travel || '',
                        audit_status: result.settlement.audit_status || '',
                        travel_amount: result.settlement.travel_amount || '',
                        days: result.settlement.days || '',
                        city: result.settlement.city || '',
                        travel_advance: result.settlement.travel_advance || '',
                        //  bank_details: result.data.bank_details || '',
                        grade: result.settlement.grade || '',
                        mobile: result.settlement.mobile || '',
                        rpt_mgr_status: rpt_mgr_status || '',
                        manager_remarks: result.settlement.manager_remarks || '',
                        report_mngr: report_mngr,
                        created_on: result.settlement.created_on || '',
                        manager_approved_date: result.settlement.manager_approved_date || '',
                        manager_approved_amount: result.settlement.manager_approved_amount || '',
                        bank_acnum: result.settlement.bank_acnum || '',
                        bank_name: result.settlement.bank_name || '',
                        ifsc_code: result.settlement.ifsc_code || '',


                    });
                    setIsApproved(rpt_mgr_status === 'Approved');
                    //  fetchReportingData(report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);
    useEffect(() => {
        const fetchSettlement1 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1er", token);
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id1=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("settl;ement1", result);

                if (result.status) {
                    setRows1(result.settlement1);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSettlement1();
    }, [id]);

    useEffect(() => {
        const fetchSettlement2 = async () => {

            try {
                const token = localStorage.getItem('authToken');
                console.log("token1a", token);

                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id2=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("settlement2:", result);

                if (result.status) {
                    //  setItems(result.data);
                    setRows2(result.settlement2);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchSettlement2();
    }, [id]);

    useEffect(() => {
        const fetchSettlement3 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id3=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("travelid", result.settlement2);
                if (result.status) {
                    setRows3(result.settlement3);
                    // Initialize transportRows with data from the fetched settlement

                    if (result.settlement3.length > 0) {
                        const initialTransportRows = result.settlement3.map((settlement, index) => ({

                            id: settlement.id,
                            travelId: settlement.travel_id || '',
                            travelDate: settlement.travelDate || '',
                            origin: settlement.origin || '',
                            destination: settlement.destination || '',
                            travelAmount: settlement.travelAmount || '',
                            transportMethod: settlement.transportMethod || '',
                            receipt: settlement.receipt || ''
                        }));

                        setTransportRows(initialTransportRows);
                    }
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchSettlement3();
    }, [id]);

    useEffect(() => {
        const fetchSettlement4 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id4=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setdocumentsRows(result.settlement4);
                    // Initialize transportRows with data from the fetched settlement


                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
               // setError('Failed to fetch data');
            }
        };

        fetchSettlement4();
    }, [id]);





    const [rows, setRows] = useState([
        { id: Date.now(), date: '', time: '', tourPlace: '', travelType: '', stayType: '', perDiem: '', perDiem1: '', local: '', misc: '', totalAmount: '' },

    ]);
    const handleChange = (id, event) => {
        // Check if event is defined
        if (!event || !event.target) {
            console.error("Event or event.target is undefined");
            return;
        }

        const { name, value } = event.target;

        if (name === 'manager_approved_amount') {

            const travelAdvance = parseFloat(grandTotal() - parseFloat(formData.travel_amount)) || 0;

            const approvedAmount = parseFloat(value) || 0;

            // if (approvedAmount > travelAdvance) {
            //     console.log("check", value);
            //     setError('Manager Approved Amount must be less than or equal to Travel Amount Requisitioned.');
            // } else {
            //     setError(''); // Clear the error if both conditions are met
            // }
        }

        // Update rows based on id
        const updatedRows = rows.map(row => {
            if (row.id === id) {
                const newRow = { ...row, [name]: value };
                newRow.totalAmount = (parseFloat(newRow.perDiem) || 0) + (parseFloat(newRow.local) || 0) + (parseFloat(newRow.misc) || 0);
                return newRow;
            }
            return row;
        });

        setRows(updatedRows);

        // Update formData if needed
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, [name]: value } : row
            )
        );
    };



    const calculateTotals = () => {
        return rows.reduce((acc, row) => {
            return {
                perDiem: acc.perDiem + (parseFloat(row.perDiem) || 0),
                local: acc.local + (parseFloat(row.local) || 0),
                misc: acc.misc + (parseFloat(row.misc) || 0),
                totalAmount: acc.totalAmount + (parseFloat(row.totalAmount) || 0)
            };
        }, { perDiem: 0, local: 0, misc: 0, totalAmount: 0 });
    };

    const totals = calculateTotals();


    const [transportRows, setTransportRows] = useState([{ id: 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '' }]);

    const handleAddTransportRow = () => {
        setTransportRows([...transportRows, { id: transportRows.length + 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '' }]);
        updateLocalConveyanceTotals();
    };
    const totalLocalConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);

    const totalTravelAmount = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);

    const handleEmpRowChange = (id, e) => {
        const { name, value } = e.target;
        setEmployeeRows(prevRows => prevRows.map(row => row.id === id ? { ...row, [name]: value } : row));
        if (name === 'emp_code') {
            const emp_code = parseFloat(value) || 0;
            setCurrentRowId(id);
            setCurrentInput(value);
            setFilteredCodes(empCodes.filter(code => code.includes(value)));
        }
    };
    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("get all cities data:", result);

                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);
    const totalTransportAmount = rows2.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);

    const grandTotal = () => {
        const totalHotelAmount = rows1.reduce((total, row) => total + (parseFloat(row.hotel_amount) || 0), 0);
        const totalTravelAmount = rows1.reduce((total, row) => total + (parseFloat(row.ticket_travel_amount) || 0), 0);
        const totalMisc = rows1.reduce((total, row) => total + (parseFloat(row.misc) || 0), 0);
        const totalPerDiem = rows1.reduce((total, row) => total + (parseFloat(row.perDiem) || 0), 0);
        // const totalPerDiem = rows1.reduce((total, row) => {
        //     // Check if hotel_booked_by is "TravelDesk" and get the appropriate per diem value
        //     const perDiemValue = row.hotel_booked_by === "TravelDesk"
        //         ? parseFloat(row.perDiem1) || 0  // Fallback to 0 if NaN
        //         : parseFloat(row.perDiem) || 0;  // Fallback to 0 if NaN

        //     return total + perDiemValue;  // Add to total
        // }, 0);

        return totalHotelAmount + totalTravelAmount + totalMisc + totalPerDiem + totalTransportAmount;
    };

    const handleGuesthouseChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        // You can add any additional logic related to the guesthouse here.
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">

                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Tour/Travel Settlement Reporting Manager Approval Form</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Tour/Travel Settlement Reporting Manager Approval Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader /> // Show loader if loading is true
                    ) : (
                        <>
                            <section className="col-lg-12">
                                <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                    <div className="box box-info">


                                        <div className="card-body">
                                            <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                                <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                    <div className="box-body">
                                                        <div className="row">

                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Employee No
                                                                        <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="emp_code"
                                                                        id="name"
                                                                        value={formData.emp_code}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Employee No/Consultant"
                                                                        readonly="true"

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Name <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="comp_name"
                                                                        id="name"
                                                                        value={formData.comp_name}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Name"
                                                                        readonly="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Employee Band<span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="grade"
                                                                        id="name"
                                                                        value={formData.grade}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Employee Band as per Category"
                                                                        readonly="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="dept_name"
                                                                        id="name"
                                                                        value={formData.dept_name}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Department&Designation"
                                                                        readonly="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="designation_name"
                                                                        id="name"
                                                                        value={formData.designation_name}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Department&Designation"
                                                                        readonly="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Mobile No
                                                                        <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobile"
                                                                        id="name"
                                                                        value={formData.mobile}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Days of travel"
                                                                        readonly="true"
                                                                    />
                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div className="row">

                                                            <div className="col-md-1">
                                                                <div className="form-group">
                                                                    <label htmlFor="days">No of Days
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        name="days"
                                                                        id="days"
                                                                        value={formData.days}
                                                                        onChange={(e) => handleChange('days', e)}
                                                                        className="form-control input-font"
                                                                        placeholder="1,1.5,2,2.5, etc"
                                                                        required
                                                                        readonly="true"


                                                                    />
                                                                </div>
                                                            </div>




                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="travelAdvance">Has advance settled <span style={{ color: 'red' }}>*</span></label>
                                                                    <select
                                                                        name="travel_advance"
                                                                        id="travelAdvance"
                                                                        value={formData.travel_advance}
                                                                        onChange={(e) => handleChange('travel_advance', e)}
                                                                        className="form-control input-font"
                                                                        readonly="true"
                                                                    >

                                                                        <option value="">Select</option>
                                                                        <option value="yes">Yes</option>
                                                                        <option value="no">No</option>
                                                                    </select>
                                                                </div>
                                                            </div>
{/* 
                                                            {Purpose && (<>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label htmlFor="travelAmount">Travel Advance Amount <span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="number"
                                                                            name="travel_amount"
                                                                            id="travelAmount"
                                                                            value={Amount}
                                                                            onChange={(e) => handleChange('travel_amount', e)} // Handle change for travel amount
                                                                            className="form-control input-font"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <div className="form-group">
                                                                        <label htmlFor="purpose">Adv Purpose <span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            name="purpose"
                                                                            id="purpose"
                                                                            value={Purpose}
                                                                            onChange={(e) => handleChange('purpose', e)} // Handle change for purpose
                                                                            className="form-control input-font"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div></>)} */}
                                                           
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label htmlFor="purpose">Settlement Purpose <span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            name="purpose"
                                                                            id="purpose"
                                                                            value={formData.settlement_purpose}
                                                                            onChange={(e) => handleChange('purpose', e)} // Handle change for purpose
                                                                            className="form-control input-font"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>



                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Reporting Manager
                                                                        <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobile"
                                                                        id="name"
                                                                        value={formData.report_mngr}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        placeholder="Days of travel"
                                                                        readonly="true"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="guesthouse">Select City Guest-House
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <select
                                                                        required
                                                                        name="guesthouse"
                                                                        id="guesthouse"
                                                                        value={formData.guesthouse}
                                                                        onChange={handleGuesthouseChange}
                                                                        className="form-control input-font"
                                                                        disabled
                                                                    >
                                                                        <option value="">Select a Guest-House</option>
                                                                        <option value="Hyderabad">Hyderabad</option>
                                                                        <option value="Vishakapatnam">Vishakapatnam</option>
                                                                        <option value="Rajuhmundry">Rajuhmundry</option>
                                                                        <option value="Gurgaon">Gurgaon</option>
                                                                        <option value="no">No</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="bank_acnum">Bank Ac Num
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_acnum"
                                                                    id="bank_acnum"
                                                                    value={formData.bank_acnum}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="bank_name">Bank Name
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_name"
                                                                    id="bank_name"
                                                                    value={formData.bank_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="ifsc_code">IFSC Code
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="ifsc_code"
                                                                    id="ifsc_code"
                                                                    value={formData.ifsc_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>


                                                        </div>




                                                    </div>

                                                    <div className="expense-table-container" style={{ textAlign: 'right' }}>

                                                        <table className="expense-table">
                                                            <thead>
                                                                <tr className="header-row">
                                                                    <th>Emp Code</th>
                                                                    <th>Name</th>
                                                                    <th>Dept</th>
                                                                    <th>Designation</th>
                                                                    <th>Grade</th>
                                                                    <th>Mobile</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rows3.map(row => (
                                                                    <tr key={row.id}>
                                                                        <td data-label="Emp Code">
                                                                            <input
                                                                                type="text"
                                                                                name="emp_code"
                                                                                value={row.emp_code}
                                                                                onChange={(e) => {
                                                                                    handleEmpRowChange(row.id, e);
                                                                                    setEmpCode(e.target.value); // Update EmpCode state to trigger API fetch
                                                                                }}
                                                                                className="form-control input-font"
                                                                                placeholder="Emp Code"
                                                                                required
                                                                                readOnly
                                                                            />
                                                                            {filteredCodes.length > 0 && (
                                                                                <ul className="suggestions">
                                                                                    {filteredCodes.map((code) => (
                                                                                        <li key={code} onClick={() => handleSelectCode(code)}>
                                                                                            {code}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            )}
                                                                        </td>
                                                                        <td data-label="Name">
                                                                            <input
                                                                                type="text"
                                                                                name="comp_name"
                                                                                value={row.comp_name}
                                                                                onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                                className="form-control input-font"
                                                                                placeholder="Name"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Dept">
                                                                            <input
                                                                                type="text"
                                                                                name="dept_name"
                                                                                value={row.dept_name}
                                                                                onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                                className="form-control input-font"
                                                                                placeholder="Dept"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Designation">
                                                                            <input
                                                                                type="text"
                                                                                name="designation_name"
                                                                                value={row.designation_name}
                                                                                onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                                className="form-control input-font"
                                                                                placeholder="Designation"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Grade">
                                                                            <input
                                                                                type="text"
                                                                                name="grade"
                                                                                value={row.grade}
                                                                                onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                                className="form-control input-font"
                                                                                placeholder="Grade"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Mobile">
                                                                            <input
                                                                                type="text"
                                                                                name="mobile"
                                                                                value={row.mobile}
                                                                                onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                                className="form-control input-font"
                                                                                placeholder="Mobile"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Actions">

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="expense-table-container">

                                                        <table className="expense-table">
                                                            <thead>
                                                                <tr>
                                                                    <td colSpan="9"></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr className="header-row">
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    <th>From Place</th>
                                                                    <th>To Place</th>
                                                                    <th>Travel Type</th>
                                                                    <th>Hotel Booked By</th>
                                                                    <th>Hotel Receipt</th>
                                                                    <th>Hotel Amount</th>
                                                                    <th>Travel Ticket Booked By</th>
                                                                    <th>Ticket Receipt</th>
                                                                    <th>Travel Ticket Amount</th>
                                                                    <th>Per Diem Allow</th>
                                                                    
                                                                    <th>Local Conveyance</th>
                                                                    <th>Misc Exp</th>
                                                                    <th>Total Rs.</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rows1.map((row, index) => {
                                                                    // Calculate total for the current row
                                                                    const total = (
                                                                        (parseFloat(row.hotel_amount) || 0) +
                                                                        (parseFloat(row.ticket_travel_amount) || 0) +
                                                                        (parseFloat(row.perDiem) || 0) + (parseFloat(PerDiem)) +
                                                                        (index === 0 ? parseFloat(TotalLocal) : 0) + // Include Local Conveyance only for the first row
                                                                        (parseFloat(row.misc) || 0)
                                                                    );

                                                                    return (
                                                                        <tr key={row.id}>
                                                                            <td data-label="Date">
                                                                                <input type="date" name="date" readOnly value={row.date} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" />
                                                                            </td>
                                                                            <td data-label="Time">
                                                                                <input type="time" name="time" readOnly value={row.time} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" />
                                                                            </td>
                                                                            <td data-label="From Place">
                                                                                <select disabled name="from_city" value={row.from_city} onChange={(e) => handleChange(row.id, e)} className="form-control input-font">
                                                                                    <option value="">Select a Tour Place</option>
                                                                                    {items.map(city => (
                                                                                        <option key={city.id} value={city.id}>
                                                                                            {city.city_name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </td>
                                                                            <td data-label="To Place">
                                                                                <select disabled name="to_city" value={row.to_city} onChange={(e) => handleChange(row.id, e)} className="form-control input-font">
                                                                                    <option value="">Select a Tour Place</option>
                                                                                    {items.map(city => (
                                                                                        <option key={city.id} value={city.id}>
                                                                                            {city.city_name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </td>
                                                                            <td data-label="Travel Type">
                                                                                <select name="travelType" disabled value={row.travelType} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                                                                                    <option value="">Select Travel Type</option>

                                                                                    {(formData.grade && ['E', 'F'].includes(formData.grade[0])) ? (
                                                                                        <>
                                                                                            <option value='Air'>Air</option>
                                                                                            <option value='Train'>Train</option>
                                                                                            <option value='Bus'>Bus</option>
                                                                                            <option value='Local_Taxi'>Local Taxi</option>
                                                                                            <option value='Local_Auto'>Local Auto</option>
                                                                                            <option value='Own_Bike'>Own Bike</option>
                                                                                            <option value='Own_Car'>Own Car</option>
                                                                                        </>

                                                                                    ) : (
                                                                                        <>

                                                                                            <option value='Train'>Train</option>
                                                                                            <option value='Bus'>Bus</option>
                                                                                            <option value='Local_Taxi'>Local Taxi</option>
                                                                                            <option value='Local_Auto'>Local Auto</option>
                                                                                            <option value='Own_Bike'>Own Bike</option>
                                                                                            <option value='Own_Car'>Own Car</option>
                                                                                        </>
                                                                                    )}
                                                                                </select>
                                                                            </td>
                                                                            <td data-label="Booked by">
                                                                                <select name="hotel_booked_by" value={row.hotel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required disabled>
                                                                                    <option value="">Select Booked By</option>
                                                                                    <option value="Self">Self</option>
                                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                                </select>
                                                                            </td>
                                                                            <td className="d-flex justify-content-center align-items-center">
                                                                                {row.hotel_receipt && (
                                                                                    <a
                                                                                        href={row.hotel_receipt}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        className="position-relative"
                                                                                    >
                                                                                        {row.hotel_receipt.toLowerCase().endsWith('.pdf') ? (
                                                                                            // PDF icon
                                                                                            <img
                                                                                                src={pdficon} // Replace with your PDF icon path
                                                                                                alt="View PDF"
                                                                                                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                                                                            />
                                                                                        ) : (
                                                                                            // Image icon or actual image
                                                                                            <img
                                                                                                src={row.hotel_receipt} // Replace with your actual image path
                                                                                                alt="View Receipt"
                                                                                                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                                                                            />
                                                                                        )}
                                                                                    </a>
                                                                                )}
                                                                            </td>
                                                                            <td data-label="Hotel Amount">
                                                                                <input type="text" name="hotel_amount" value={row.hotel_amount} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" disabled={row.hotel_booked_by === "TravelDesk"} readOnly />
                                                                            </td>
                                                                            <td data-label="Ticket Booked By">
                                                                                <select name="ticket_travel_booked_by" disabled value={row.ticket_travel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                                                                                    <option value="">Select Booked By</option>
                                                                                    <option value="Self">Self</option>
                                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                                </select>
                                                                            </td>
                                                                            <td className="d-flex justify-content-center align-items-center">
                                                                                {row.ticket_receipt && (
                                                                                    <a
                                                                                        href={row.ticket_receipt}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        className="position-relative"
                                                                                    >
                                                                                        {row.ticket_receipt.toLowerCase().endsWith('.pdf') ? (
                                                                                            // PDF icon
                                                                                            <img
                                                                                                src={pdficon} // Replace with your PDF icon path
                                                                                                alt="View PDF"
                                                                                                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                                                                            />
                                                                                        ) : (
                                                                                            // Image icon or actual image
                                                                                            <img
                                                                                                src={row.ticket_receipt} // Replace with your actual image path
                                                                                                alt="View Receipt"
                                                                                                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                                                                            />
                                                                                        )}
                                                                                    </a>
                                                                                )}
                                                                            </td>
                                                                            <td data-label="Travel Amount">
                                                                                <input type="text" name="ticket_travel_amount" value={row.ticket_travel_amount} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" disabled={row.ticket_travel_booked_by === "TravelDesk"} readOnly />
                                                                            </td>
                                                                            <td data-label="Per Diem Allow">
                                                                                <input
                                                                                    type="number"
                                                                                    name="perDiem"
                                                                                    value={
                                                                                        row.perDiem
                                                                                    }
                                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                                    className="form-control input-font"
                                                                                    required
                                                                                    disabled={index !== 0}
                                                                                />
                                                                            </td>
                                                                            <td data-label="Local Conveyance">
                                                                                <input
                                                                                    type="number"
                                                                                    name="local"
                                                                                    value={index === 0 ? totalTransportAmount : ''} // Set only in the first row
                                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                                    className="form-control input-font"
                                                                                    disabled={index !== 0} // Disable for other rows
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td data-label="Misc Exp">
                                                                                <input type="text" name="misc" readOnly value={row.misc} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" placeholder="Misc Exp" />
                                                                            </td>
                                                                            <td data-label="Total Rs.">
                                                                                <input
                                                                                    type="number"
                                                                                    value={parseFloat(
                                                                                        parseFloat(row.perDiem) +
                                                                                        (parseFloat(row.hotel_amount) || 0) +
                                                                                        (parseFloat(row.ticket_travel_amount) || 0) +
                                                                                        (parseFloat(row.misc) || 0) +
                                                                                        (index === 0 ? (parseFloat(totalTransportAmount) || 0) : 0)
                                                                                    )}
                                                                                    className="form-control input-font"
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td data-label="Actions">
                                                                                {/* Your action buttons (if any) go here */}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>

                                                            <tfoot>
                                                                <tr>
                                                                    <th colSpan="14" style={{ textAlign: 'right' }}>Grand Total</th>
                                                                    <th style={{ textAlign: 'center' }}>{grandTotal()}</th>
                                                                    <th></th>
                                                                </tr>

                                                                {/* <tr>
                                                                    <th colSpan="14" style={{ textAlign: 'right' }}>Less Advance</th>
                                                                    <th style={{ textAlign: 'center' }}>{Amount}</th>
                                                                    <th></th>
                                                                </tr> */}
                                                                <tr>
                                                                    <th colSpan="14" style={{ textAlign: 'right' }}>Total</th>
                                                                    <th style={{ textAlign: 'center' }}>{grandTotal()}</th>
                                                                    <th></th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>


                                                </fieldset>
                                                <br />
                                                <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                    <center><span className='btn btn-primary' style={{ fontSize: '12px' }}>Details of Local Conveyance</span></center>


                                                    <div className="transport-table-container" style={{ marginBottom: '20px' }}>

                                                        <table className="expense-table">
                                                            <thead>
                                                                <tr>
                                                                    <td colSpan="8"></td>
                                                                </tr>
                                                                <tr className="header-row">

                                                                    <th style={{ textAlign: 'center' }}>Travel Date</th>
                                                                    <th style={{ textAlign: 'center' }}>Origin</th>
                                                                    <th style={{ textAlign: 'center' }}>Destination</th>
                                                                    <th style={{ textAlign: 'center' }}>Amount</th>
                                                                    <th style={{ textAlign: 'center' }}>Transport Mode</th>
                                                                    <th style={{ textAlign: 'center' }}>Receipt/Bill</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rows2.map((row, index) => (
                                                                    <tr key={index}>

                                                                        <td data-label="Travel Date">
                                                                            <input
                                                                                type="date"
                                                                                name="travelDate"
                                                                                readonly="true"
                                                                                value={row.travelDate}

                                                                                className="form-control input-font"
                                                                            />
                                                                        </td>
                                                                        <td data-label="Origin">
                                                                            <input
                                                                                type="text"
                                                                                name="origin"
                                                                                readonly="true"
                                                                                value={row.origin}

                                                                                className="form-control input-font"
                                                                                placeholder="From"
                                                                            />
                                                                        </td>
                                                                        <td data-label="Destination">
                                                                            <input
                                                                                type="text"
                                                                                name="destination"
                                                                                readonly="true"
                                                                                value={row.destination}

                                                                                className="form-control input-font"
                                                                                placeholder="To"
                                                                            />
                                                                        </td>
                                                                        <td data-label="Amount">
                                                                            <input
                                                                                type="number"
                                                                                readonly="true"
                                                                                name="travelAmount"
                                                                                value={row.travelAmount}

                                                                                className="form-control input-font"
                                                                                placeholder="Amount"
                                                                            />
                                                                        </td>
                                                                        <td data-label="Transport Mode">
                                                                            <select
                                                                                name="transportMethod"
                                                                                readonly="true"
                                                                                value={row.transportMethod}

                                                                                className="form-control input-font"

                                                                            >
                                                                                <option value="">Select Mode</option>
                                                                                <option value="bus">Bus</option>
                                                                                <option value="auto">Auto</option>
                                                                                <option value="taxi">Taxi</option>
                                                                                <option value="own">Own Vehicle</option>
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex justify-content-center align-items-center">
                                                                                {row.receipt && (
                                                                                    <a href={row.receipt} target="_blank" rel="noopener noreferrer" className="position-relative">
                                                                                        <img
                                                                                            src={icon1}
                                                                                            alt="Receipt"
                                                                                            style={{ width: '50px', height: '50px', borderRadius: '4px' }} // Add rounded corners
                                                                                        />

                                                                                    </a>
                                                                                )}
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan="3" style={{ textAlign: 'right' }}>Total Amount:</td>
                                                                    <td>{Math.trunc(totalTransportAmount.toFixed(2))}</td>
                                                                    <td colSpan="3"></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </fieldset>
                                                <br />
                                                <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                    <center><span className="btn btn-primary" style={{ fontSize: '12px' }}>Additional Documents</span></center>
                                                    <div className="document-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>

                                                        <table className="document-table" style={{ marginTop: '10px' }}>
                                                            <thead>
                                                                <tr>

                                                                    <th style={{ textAlign: 'center' }}>Click to Preview</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* Loop through documentsRows and display each document's image */}
                                                                {documentsRows.length > 0 ? (
                                                                    <tr>
                                                                        <td colSpan="1" style={{ textAlign: 'center' }}>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                                                                {documentsRows.map((document) => (
                                                                                    <div key={document.id} style={{ margin: '10px' }}>
                                                                                        <a href={document.additional_doc} target="_blank" rel="noopener noreferrer" className="position-relative">
                                                                                            {document.additional_doc.toLowerCase().endsWith('.pdf') ? (
                                                                                                // If the document is a PDF, show the PDF icon
                                                                                                <img
                                                                                                    src={pdficon}// Replace with the actual path to your PDF icon
                                                                                                    alt={`PDF Document ${document.id}`}
                                                                                                    style={{
                                                                                                        width: '79px',
                                                                                                        height: '100px',
                                                                                                        objectFit: 'cover',
                                                                                                        margin: '5px',
                                                                                                    }}
                                                                                                />
                                                                                            ) : (
                                                                                                // Otherwise, show the image
                                                                                                <img
                                                                                                    src={document.additional_doc}
                                                                                                    alt={`Document ${document.id}`}
                                                                                                    style={{
                                                                                                        width: '100px',
                                                                                                        height: '100px',
                                                                                                        objectFit: 'cover',
                                                                                                        margin: '5px',
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </a>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="1" style={{ textAlign: 'center' }}>
                                                                            {error ? error : 'No documents available.'}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </fieldset>
                                                <div class="row"><div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="rpt_mgr_status">Reporting Manager Status
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <select
                                                            name="rpt_mgr_status"
                                                            id="rpt_mgr_status"
                                                            value={formData.rpt_mgr_status}
                                                            onChange={(e) => handleChange('rpt_mgr_status', e)}
                                                            required
                                                            className="form-control input-font custom-select"

                                                        >

                                                            <option value="Pending">Pending</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                        </select>
                                                        {errors.rpt_mgr_status && (
                                                                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                                                        {errors.rpt_mgr_status}
                                                                    </div>
                                                                )}
                                                    </div>
                                                </div><div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Reporting Manager Approved Amount
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                name="manager_approved_amount"
                                                                id="name"
                                                                value={formData.manager_approved_amount}

                                                                onChange={(e) => handleChange('manager_approved_amount', e)}
                                                                className="form-control input-font"

                                                                required

                                                            />
                                                        </div>
                                                        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="manager_remarks">Reporting Manager Remarks
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>

                                                            <textarea
                                                                id="manager_remarks"
                                                                name="manager_remarks"
                                                                value={formData.manager_remarks}
                                                                onChange={(e) => handleChange('manager_remarks', e)}
                                                                className="form-control"

                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </div></div>

                                                {/* <div className="col-sm-12 mt-2">
                <table className="table table-responsive table1" style={{width:'100%',marginTop:'0.1em'}}>
                    <tbody><tr>
                        <td> <b> Bank Details : </b>
                            Syndicate Bank<b> Acc. Name :</b>  Rohit R Mehta <b>  Acc. No : </b>554466778899 <b> IFSC : </b>1122333                        </td>
                    </tr>
                </tbody></table>
            </div> */}
                                                <div className="col-sm-12 row" style={{ width: '100%' }}>
                                                    <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                                        <b>Prepared by </b>
                                                        <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                            : {formData.created_on}
                                                        </p>
                                                    </div>



                                                </div>



                                                <div className="box-footer">
                                                    <br />
                                                    <center>
                                                        <button
                                                            type="submit"
                                                            disabled={isApproved}
                                                            className="btn btn-success pull-right"
                                                        >
                                                            Submit
                                                        </button>
                                                    </center>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>

            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default EditSettlementGroupForm;
