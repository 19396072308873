import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
import { BASE_URL } from "./Constants.js";
import useAuth from './useAuth';
import * as XLSX from "xlsx";
import "./Manage_User.css";

const Loader = () => (
	<div style={{ textAlign: 'center', marginTop: '20px' }}>
		<div className="spinner-border" role="status">
			<span className="sr-only">Loading...</span>
		</div>
		<p>Loading...</p>
	</div>
);

const ManageHotelInfo = () => {
	const [statelistData, setStatelistData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState("id");
	const [sortOrder, setSortOrder] = useState("desc");
	const navigate = useNavigate();
	useAuth();
	const token = localStorage.getItem("authToken") || null;

	useEffect(() => {
		const fetchItems = async () => {
			try {
				if (!token) return;

				const response = await fetch(`${BASE_URL}api/getHotelInfo`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const result = await response.json();
				if (result.status) {
					console.log(result.hotel_info);
					setItems(result.hotel_info); // Assuming this is an array of objects
					//setStatelistData(result.travel_desk);
				} else {
					setError(result.message);
				}
			} catch (error) {
				console.error("Fetch error:", error);
			//	setError("Failed to fetch items");
			} finally {
				setLoading(false);
			}
		};

		fetchItems();
	}, [navigate]);

	useEffect(() => {
		if (!Array.isArray(items)) {
			setFilteredItems([]);
			return;
		}

		const filtered = items.filter(item => {
			const trans_code = item.trans_code ? item.trans_code.toLowerCase() : "";
			const hotel_name = item.hotel_name ? item.hotel_name.toLowerCase() : "";
			const check_in = item.check_in ? item.check_in.toLowerCase() : "";
			const check_out = item.check_out ? item.check_out.toLowerCase() : "";
			const amount = item.check_out ? item.amount.toLowerCase() : "";
			const emp_code = (item.related_details && item.related_details.length > 0)
				? item.related_details[0].emp_code
				: '';

			return (
				trans_code.includes(searchQuery.toLowerCase()) ||
				hotel_name.includes(searchQuery.toLowerCase()) ||
				check_in.includes(searchQuery.toLowerCase()) ||
				check_out.includes(searchQuery.toLowerCase()) ||
				amount.includes(searchQuery.toLowerCase()) ||
				emp_code.includes(searchQuery.toLowerCase())
			);
		});

		const sorted = filtered.sort((a, b) => {
			if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
			if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
			return 0;
		});

		setFilteredItems(sorted);
		setCurrentPage(1); // Reset to first page when items or search query changes
	}, [searchQuery, items, sortColumn, sortOrder]);

	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const startItem = indexOfFirstItem + 1;
	const endItem = Math.min(indexOfLastItem, filteredItems.length);

	const handleSearchChange = e => {
		setSearchQuery(e.target.value);
	};

	const handlePageChange = pageNumber => {
		setCurrentPage(pageNumber);
	};

	const handleItemsPerPageChange = e => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handleSort = column => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === "asc" ? "desc" : "asc");
		} else {
			setSortColumn(column);
			setSortOrder("asc");
		}
	};

	const exportToExcel = () => {
		const filteredStateNames = filteredItems.map(item => ({
			trans_code: item.trans_code,
			HotelName: item.hotel_name,
			Checkin: item.check_in,
			Checkout: item.check_out,
			Amount: item.amount,
			emp_code: item.related_details[0].emp_code,
		}));
		const worksheet = XLSX.utils.json_to_sheet(filteredStateNames);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "HotelInfo");
		XLSX.writeFile(workbook, "HotelInfo.xlsx");
	};

	const handleDelete = async (id, e) => {
        const token = localStorage.getItem('authToken');
        e.preventDefault(); // Prevent default behavior of the anchor tag

        // Show confirmation dialog
        const confirmed = window.confirm('Are you sure you want to delete this item?');
        if (!confirmed) return; // Exit if user cancels

        try {
            // Send DELETE request to API with the item id
            const response = await fetch(`${BASE_URL}api/delete_hotelinfo_request/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    // Add other headers if necessary (e.g., Authorization)
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the item');
            }
           
            // Optional: Display a success message or perform other actions after successful deletion
            alert('Item deleted successfully');
            window.location.reload();
            // Navigate to another page after successful deletion
            navigate(`/manage_travel_desk`);
        } catch (error) {
            console.error('Error deleting item:', error);
            alert('Failed to delete the item.');
        }
    };

	return (
		<>
			<div className='hold-transition sidebar-mini layout-fixed' style={{ fontSize: '12px' }}>
				<div className='wrapper'>
					<Header />
					<Sidebar />
					<div className='content-wrapper'>
						<div className='content-header'>
							<div className='container-fluid'>
								<div className='row mb-2'>
									<div className='col-sm-6'>
										<h1 className='m-0' style={{ fontSize: '20px' }}>Hotel Info Details</h1>
									</div>
									<div className='col-sm-6'>
										<ol className='breadcrumb float-sm-right'>
											<li className='breadcrumb-item' style={{ fontSize: '12px' }}>
												<a href='/dashboard'>Home</a>
											</li>

											<li className='breadcrumb-item active' style={{ fontSize: '12px' }}>Hotel Info Details</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
						{loading ? (
							<Loader /> // Show loader if loading is true
						) : (
							<>
								<section className='content'>
									<div className='container-fluid'>
										<div className='row'>
											<section className='col-lg-12 connectedSortable'>
												<div className='card'>
													<div className='card-header'>

														<span style={{ float: "right" }}>
															<Link to='/hotel_info'>
																<button
																	type='button'
																	className='btn btn-success'>
																	Add New
																</button>
															</Link>
															<button
																type='button'
																className='btn btn-secondary ml-2'
																onClick={exportToExcel}>
																Export to Excel
															</button>
														</span>
													</div>
													<div className='card-body'>
														{loading ? (
															<p>Loading...</p>
														) : error ? (
															<p>Error: {error}</p>
														) : (
															<>
																<div
																	id='example1_filter'
																	style={{ float: "right" }}
																	className='dataTables_filter'>
																	<label>
																		<input
																			type='search'
																			value={searchQuery}
																			onChange={handleSearchChange}
																			className='form-control form-control-sm'
																			placeholder='Search'
																			aria-controls='example1'
																		/>
																	</label>
																</div>

																<div className='form-group'>
																	<select
																		className='form-control custom-select-sm'
																		value={itemsPerPage}
																		onChange={handleItemsPerPageChange}>
																		<option value={10}>10</option>
																		<option value={50}>50</option>
																		<option value={100}>100</option>
																	</select>
																</div>
																<div className="table-responsive">
																	<table className='table table-bordered table-striped' style={{ fontSize: '13px' }}>
																		<thead>
																			<tr>
																				<th onClick={() => handleSort("id")}>
																					ID {sortColumn === "id" && (sortOrder === "asc" ? "▲" : "▼")}
																				</th>
																				<th>
																					Trans Code
																				</th>
																				<th>

																					Hotel Name
																				</th>

																				<th>

																					Check In
																				</th>
																				<th>

																					Check out
																				</th>
																				<th>

																					Amount
																				</th>
																				<th>

																					Emp Code
																				</th>
																				<th>

																					Action
																				</th>
																			</tr>
																		</thead>
																		<tbody>
																			{currentItems.length > 0 ? (
																				currentItems.map((item, index) => (
																					<tr key={index}>
																						<td>
																							{index +
																								1 +
																								(currentPage - 1) * itemsPerPage}
																						</td>
																						<td>{item.trans_code}</td>
																						<td>{item.hotel_name}</td> {/* Ensure you're using the right property */}

																						<td>{item.check_in}</td>
																						<td>{item.check_out}</td>
																						<td>{item.amount}</td>
																						<td>
																							{item.related_details && item.related_details.length > 0
																								? item.related_details[0].emp_code
																								: "N/A"}
																						</td>
																						<td><Link to={`/get_hotel_info_form/${item.id}`}>
																							<i className="fa fa-edit" style={{ fontSize: '12px', color: 'green' }}></i> {/* Small edit icon */}
																						</Link>
																						<a href="#" onClick={(e) => handleDelete(item.id, e)}>
                                                                                        <i className="fa fa-times" style={{ fontSize: '12px', color: 'red' }}></i>
                                                                                    </a></td>
																					</tr>
																				))
																			) : (
																				<tr>
																					<td colSpan='8'><center>No data available</center></td>
																				</tr>
																			)}
																		</tbody>
																	</table>
																</div>
															</>
														)}
													</div>
												</div>
												<nav>
													<ul
														className='pagination'
														style={{ float: "right" }}>
														<li className='page-item'>
															<button
																className='page-link'
																onClick={() => handlePageChange(currentPage - 1)}
																disabled={currentPage === 1}>
																Previous
															</button>
														</li>
														{[...Array(totalPages).keys()].map(pageNumber => (
															<li
																key={pageNumber}
																className={`page-item ${pageNumber + 1 === currentPage ? "active" : ""
																	}`}>
																<button
																	className='page-link'
																	onClick={() => handlePageChange(pageNumber + 1)}>
																	{pageNumber + 1}
																</button>
															</li>
														))}
														<li className='page-item'>
															<button
																className='page-link'
																onClick={() => handlePageChange(currentPage + 1)}
																disabled={currentPage === totalPages}>
																Next
															</button>
														</li>
													</ul>
												</nav>
												<div className='mb-3'>
													Showing {startItem} to {endItem} of {filteredItems.length}{" "}
													entries
												</div>
											</section>
										</div>
									</div>
								</section>
							</>
						)}
					</div>

					<Footer />
					<aside className='control-sidebar control-sidebar-dark'></aside>
				</div>
			</div>
		</>
	);
};

export default ManageHotelInfo;
