import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import useAuth from './useAuth';
import './AddSettlement.css';
//import { BASE_URL } from "./Constants.js";
import pdficon from "./pdf.png";
import icon1 from "./picture.png";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const EditSettlementForm = () => {
    const { id } = useParams();
    const { id1 } = useParams();
    const [error, setError] = useState('');
    const [rows, setrows] = useState([]);
    const [Hotel, setHotel] = useState('');
    const [Diem, setDiem] = useState('');
    const [Amount, setAmount] = useState('');
    const [Purpose, setPurpose] = useState('');
    const [items, setItems] = useState([]);
    const [Grade, setGrade] = useState('');
    const [loading, setLoading] = useState(true);
    const [EmpCode, setEmpCode] = useState('');
    const [PerDiem, setPerDiem] = useState('');
    const [documentsRows, setdocumentsRows] = useState([]);
    const [ReportManageName, setReportManageName] = useState('');
    
    const [AuditManageName, setAuditManageName] = useState('');
    const [AccountsManageName, setAccountsManageName] = useState('');
    const [rows2, setRows2] = useState([]);
    const [MultipleRows, setMultipleRows] = useState([]);
    const [TravelId, setTravelId] = useState([]);
    const [nextId, setNextId] = useState(1);
    useAuth();
    const [formData, setFormData] = useState({
        days: 1, perDiemRate: 100,
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        password: '', // Do not display the password,
        travel_from_date: '', // Ensure this is initialized
        travel_to_date: '',   // Ensure this is initialized
        days: '',
        audit_status: '',
        finance_approved_amount: '',
        finance_remarks: '',
        account_status: '',
        audit_approved_amount: '',
        audit_remarks: '',
        audit_manager: '',
        accounts_manager: '',
        manager_approved_amount: '',
        utr: '',
        utr_date: '',
        created_on: '',
        manager_approved_date: '',
        audit_approved_date: '',
        finance_approved_date: '',
        rpt_mgr_status: '',
        settlement_flag: '',
        bank_acnum: '',
        bank_name: '',
        ifsc_code: ''
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [transportRows, setTransportRows] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("get all cities data:", result);

                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token12", token);
                const response = await fetch(`${API_BASE_URL}getsettlement?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("Settlement data:", result);

                if (result.status) {
                    setEmpCode(result.settlement.emp_code || '');
                    setPerDiem(result.settlement.grandTotalPerDiem || '');
                    setGrade(result.settlement.grade || '');
                    setPurpose(result.settlement.purpose || '');
                    setAmount(result.settlement.travel_amount || '');
                    setReportManageName(result.reportManagerName.comp_name || '');
                    setAuditManageName(result.auditManagerName.comp_name || '');
                    setAccountsManageName(result.accountsManagerName.comp_name || '');
                    const report_mngr = result.settlement.report_mngr || '';
                    setFormData({
                        comp_name: result.settlement.comp_name || '',
                        guesthouse: result.settlement.guesthouse || '',
                        emp_code: result.settlement.emp_code || '',
                        dept_name: result.settlement.dept_name || '',
                        designation_name: result.settlement.designation_name || '',
                        purpose: result.settlement.purpose || '',
                        mode_of_travel: result.settlement.mode_of_travel || '',
                        travel_amount: result.settlement.travel_amount || '',
                        days: result.settlement.days || '',
                        city: result.settlement.city || '',
                        travel_advance: result.settlement.travel_advance || '',
                        already_taken_advance: result.settlement.already_taken_advance || '',
                        settlement_purpose: result.settlement.settlement_purpose || '',
                        //  bank_details: result.data.bank_details || '',
                        grade: result.settlement.grade || '',
                        mobile: result.settlement.mobile || '',
                        rpt_mgr_status: result.settlement.rpt_mgr_status || '',
                        report_mngr: report_mngr,
                        manager_remarks: result.settlement.manager_remarks || '',
                        manager_approved_amount: result.settlement.manager_approved_amount || '',
                        finance_approved_amount: result.settlement.finance_approved_amount || '',
                        finance_remarks: result.settlement.finance_remarks || '',
                        account_status: result.settlement.account_status || '',
                        audit_status: result.settlement.audit_status || '',
                        audit_approved_amount: result.settlement.audit_approved_amount || '',
                        audit_remarks: result.settlement.audit_remarks || '',
                        audit_manager: result.settlement.audit_manager || '',
                        accounts_manager: result.settlement.accounts_manager || '',
                        utr: result.settlement.utr || '',
                        utr_date: result.settlement.utr_date || '',
                        created_on: result.settlement.created_on || '',
                        manager_approved_date: result.settlement.manager_approved_date || '',
                        audit_approved_date: result.settlement.audit_approved_date || '',
                        finance_approved_date: result.settlement.finance_approved_date || '',
                        bank_acnum: result.settlement.bank_acnum || '',
                        bank_name: result.settlement.bank_name || '',
                        ifsc_code: result.settlement.ifsc_code || '',
                        settlement_flag: 1,

                    });
                    //  fetchReportingData(report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchSettlement1 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id1=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setrows(result.settlement1);
                    setNextId(result.settlement1.length + 1);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch data');
            }
        };

        fetchSettlement1();
    }, [id]);

    useEffect(() => {
        const fetchSettlement2 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id2=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("travelid", result.settlement2);
                if (result.status) {
                    setRows2(result.settlement2);
                    // Initialize transportRows with data from the fetched settlement

                    if (result.settlement2.length > 0) {
                        const initialTransportRows = result.settlement2.map((settlement, index) => ({

                            id: settlement.id,
                            travelId: settlement.travel_id || '',
                            travelDate: settlement.travelDate || '',
                            origin: settlement.origin || '',
                            destination: settlement.destination || '',
                            travelAmount: settlement.travelAmount || '',
                            transportMethod: settlement.transportMethod || '',
                            receipt: settlement.receipt || ''
                        }));

                        setTransportRows(initialTransportRows);
                    }
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                // setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchSettlement2();
    }, [id]);




    const handleChange1 = (id, e) => {
        if (!e || !e.target) {
            console.error('Event or target is undefined', e);
            return; // Exit the function early
        }

        const { name, value } = e.target;

        const updatedRows = rows.map(row =>
            row.id === id ? { ...row, [name]: value } : row
        );
        setrows(updatedRows);
    }

    const handleChange2 = (e) => {
        const { name, value, type, files } = e.target;



        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value
        }));


        if (name === 'status_select') {
            setFormData(prevData => {
                let newData = { ...prevData, [name]: value };

                // Based on the selected request type, set the corresponding status to 'Pending'
                if (value === 'request_for_manager') {
                    newData.rpt_mgr_status = 'Pending';
                } else if (value === 'request_for_audit_mngr') {
                    newData.audit_status = 'Pending';
                } else if (value === 'request_for_account_mngr') {
                    newData.account_status = 'Pending';
                }

                return newData;
            });
        }

        // Handle other field changes (rpt_mgr_status, audit_status, account_status, etc.)
        else if (name === 'rpt_mgr_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } else if (name === 'audit_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } else if (name === 'account_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const handleAddRow = () => {
        const newRow = {
            id: nextId,
            travel_id: '',
            date: '',
            time: '',
            from_city: '',
            to_city: '',
            hotel_booked_by: '',
            hotel_amount: '',
            ticket_travel_booked_by: '',
            ticket_travel_amount: '',
            perDiem: '',
            local: '',
            misc: '',
            totalAmount: '',
            addFlag: 1,
        };
        setrows([...rows, newRow]);
        setNextId(nextId + 1);
    };

    const handleRemoveRow = async (id1, totalAmount, total) => {


        const userConfirmed = window.confirm(`Are you sure you want to delete ?`);
        // If row not found, exit
        const Amount = total - totalAmount;
        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        try {
            // Make API call to set status inactive
            const response = await fetch(`${API_BASE_URL}setSettlement1Delete?id=${id1}&total=${Amount}&id1=${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                // If API call was successful, remove the row
                setrows(prevRows => prevRows.filter(row => row.id !== id));
                toast.success("Employee deleted successfully.");
                navigate(0);

            } else {
                toast.error(result.message || "Failed to update employee status.");
            }
        } catch (error) {
            console.error('Error setting status inactive:', error);
            toast.error("Failed to set employee status inactive.");
        }
    };
    const totalLocalConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    const handleTransportChange = (index, e) => {
        const { name, files } = e.target; // Get the name and files from the event target
        const newTransportRows = [...transportRows]; // Create a shallow copy of transportRows

        if (files && files.length > 0) {
            const file = files[0]; // Get the first file
            newTransportRows[index] = {
                ...newTransportRows[index],
                [name]: file // Store the actual file object
            };
        } else {
            // Handle other input types
            const { value } = e.target;
            newTransportRows[index] = {
                ...newTransportRows[index],
                [name]: value // Update other values
            };
        }

        setTransportRows(newTransportRows); // Update the state with the new rows
    };


    const handleAddTransportRow = () => {
        const newTransportRow = {
            travelId: '',
            travelDate: '',
            origin: '',
            destination: '',
            travelAmount: '',
            transportMethod: '',
            receipt: null,
        };
        setTransportRows([...transportRows, newTransportRow]);
    };

    const handleRemoveTransportRow = async (id1, travelAmount, totalAmount) => {
        // Check if row is empty
        const rowToRemove = transportRows.find(row => row.id === id1);

        if (!rowToRemove ||
            (!rowToRemove.travelDate &&
                !rowToRemove.origin &&
                !rowToRemove.destination &&
                !rowToRemove.travelAmount &&
                !rowToRemove.transportMethod &&
                !rowToRemove.receipt)) {
            // If the row is empty, remove it directly from state
            setTransportRows(prevRows => prevRows.filter(row => row.id !== id1));
            toast.success("Transport row deleted successfully.");
            return;
        }

        const Amount1 = totalAmount - travelAmount;
        const userConfirmed = window.confirm(`Are you sure you want to delete this transport row?`);

        if (!userConfirmed) return; // Exit if user cancels

        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        try {
            // Make API call to set status inactive
            const response = await fetch(`${API_BASE_URL}setSettlement2Delete?id=${id1}&total=${Amount1}&id1=${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                // If API call was successful, remove the row
                setTransportRows(prevRows => prevRows.filter(row => row.id !== id1));
                toast.success("Transport row deleted successfully.");
                navigate(0);
            } else {
                toast.error(result.message || "Failed to update transport row status.");
            }
        } catch (error) {
            console.error('Error setting transport row status inactive:', error);
            toast.error("Failed to set transport row status inactive.");
        }
    };

    const totalTravelAmount = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    const validateDays = () => {
        const { days } = formData;
        console.log("Days input:", days); // Log the input value

        const regex = /^(1|1\.5|2|2\.5|3|3\.5|4|4\.5|5|5\.5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10)$/; // Updated regex
        if (days && !regex.test(days)) {
            toast.error('Please enter a valid number (1, 1.5, 2, 2.5, etc.)');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to Array
        const updatedDocuments = files.map((file, index) => ({
            travel_id: 29,  // Static travel_id (can be dynamically set)
            additional_doc: file, // URL for file display
          
           
        }));

        // Update state with the new documents
        setMultipleRows((prevRows) => [...prevRows, ...updatedDocuments]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateDays()) {
            return; // Prevent submission if validation fails
        }
        for (const row of transportRows) {
            if (row.travelAmount > 300 && !row.receipt) {
                toast.error(`Receipt is mandatory for travel amount greater than 300 for the entry from ${row.origin} to ${row.destination}.`);
                return; // Stop submission
            }
        }

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });

        
        const totalAmount = rows.reduce((total, row) => parseFloat(total) + (
            (parseFloat(row.hotel_amount) || 0) +
            (parseFloat(row.ticket_travel_amount) || 0) +
            (parseFloat(row.perDiem) || 0) +
            (parseFloat(row.local) || 0) +
            (parseFloat(row.misc) || 0)
        ), 0) - (parseFloat(formData.travel_amount) || 0) + parseFloat(totalLocalConveyance);

        // Append the total amount to formData
        formDataToSubmit.append('TotalAmount', grandTotal() - parseFloat(formData.travel_amount));
        formDataToSubmit.append('city', rows[0].to_city);
        //formDataToSubmit.append('TotalAmount', adjustedTotalAmount);
        rows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                // Skip the id for newly added rows
                if (key === 'id') {
                    if (row.addFlag === 1) { // Check if it's a newly added row
                        return; // Do not append id for new rows
                    } else {
                        formDataToSubmit.append(`rows[${index}][${key}]`, row[key]); // Append existing id
                    }
                } else {
                    formDataToSubmit.append(`rows[${index}][${key}]`, row[key]); // Append other row values
                }
            });
        });

        // Append transportRows data
        transportRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                // Check if the key corresponds to a file
                if (key === 'receipt' && row[key]) {
                    formDataToSubmit.append(`transportRows[${index}][${key}]`, row[key]); // Append the file object
                } else {
                    formDataToSubmit.append(`transportRows[${index}][${key}]`, row[key]); // Append other values
                }
            });
        });
        
        MultipleRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                // Check if the key corresponds to a file
                if (key === 'additional_doc' && row[key]) {
                    formDataToSubmit.append(`MultipleRows[${index}][${key}]`, row[key]); // Append the file object
                } 
            });
        });

        // Debugging logs
        const formDataObject = {};
        formDataToSubmit.forEach((value, key) => {
            console.log(`${key}: ${value}`);
            if (formDataObject[key]) {
                // If the key exists, convert it to an array if it's not already
                if (!Array.isArray(formDataObject[key])) {
                    formDataObject[key] = [formDataObject[key]];
                }
                // Push the new value to the array
                formDataObject[key].push(value);
            } else {
                formDataObject[key] = value;
            }
        });
        console.log('Form Data to Submit:', JSON.stringify(formDataObject, null, 2));

        try {
            const token = localStorage.getItem('authToken');
            console.log("checktoken", token);

            const response = await fetch(`${API_BASE_URL}updateSettlement/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSubmit
            });

            const result = await response.json();
            console.log("Response Data:", result);

            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({ emp_code: '' }); // Reset other fields as necessary
                navigate('/own_settlement');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    const updatePerDiemForRows = (rows, diemData, days) => {

        const { hotelPerHotel, hotelPerDiem } = diemData; // Extract both values from the object

        const updatedRows = rows.map((row, index) =>
            index === 0
                ? { ...row, perDiem: (hotelPerHotel * days).toFixed(2), perDiem1: (hotelPerDiem * days).toFixed(2) }
                : row
        );

        setrows(updatedRows);
    };

    const fetchDiem = async (selectedCity) => {
        try {
            const token = localStorage.getItem('authToken');
            const cityClassResponse = await fetch(`${API_BASE_URL}getCityClass?id=${selectedCity}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!cityClassResponse.ok) throw new Error('Network response was not ok');
            const cityClassData = await cityClassResponse.json();
            const cityClass = cityClassData.data.city_class || '';
            const modifiedGrade = Grade.charAt(0);
            const diemResponse = await fetch(`${API_BASE_URL}getDiem?grade=${modifiedGrade}&city_class=${cityClass}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!diemResponse.ok) throw new Error('Network response for second API was not ok');
            const diemData = await diemResponse.json();
            const hotelPerHotel = diemData.data.hotel || '';
            const hotelPerDiem = diemData.data.diem || '';

            setHotel(hotelPerHotel);
            setDiem(hotelPerDiem);
            return { hotelPerHotel, hotelPerDiem }; // Return the hotel per diem
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error('Error fetching data');
        }
    };

    const handleCityChange = async (id, e) => {
        if (!e || !e.target) {
            console.error('Event or target is undefined', e);
            return;
        }

        const { name, value: selectedCity } = e.target;

        const updatedRows = rows.map(row =>
            row.id === id ? { ...row, [name]: selectedCity } : row
        );
        setrows(updatedRows);

        if (id === rows[0].id && selectedCity) {
            const diemData = await fetchDiem(selectedCity);

            if (diemData) {
                // Update per diem and hotel based on the new city value
                updatePerDiemForRows(updatedRows, diemData, formData.days);
            } // Update per diem based on new city value
        }

    };

    useEffect(() => {
        const fetchSettlement4 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id4=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setdocumentsRows(result.settlement4);
                    // Initialize transportRows with data from the fetched settlement


                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch data');
            }
        };

        fetchSettlement4();
    }, [id]);


    const handleChange = async (id, event) => {
        const { name, value } = event.target;
        if (event && event.target) {
            const { value } = event.target;
            // Your logic...
        }
        const updatedRows = rows.map(row => {
            if (row.id === id) {
                // If "hotel_booked_by" is changed to "TravelDesk", set hotel_amount to 0
                if (name === 'hotel_booked_by' && value === 'TravelDesk') {
                    return { ...row, [name]: value, hotel_amount: 0 };
                }
                if (name === 'ticket_travel_booked_by' && value === 'TravelDesk') {
                    return { ...row, [name]: value, ticket_travel_amount: 0 };
                }
                return { ...row, [name]: value };
            }
            return row;
        });

        setrows(updatedRows);

        if (name === 'days') {
            const days = parseFloat(value) || 0;
            const selectedCity = updatedRows[0]?.to_city; // Get the selected city from the first row

            if (selectedCity) {
                const diemData = await fetchDiem(selectedCity);
                // console.log("checkit",hotelPerDiem); // Fetch the per diem for the selected city

                updatePerDiemForRows(updatedRows, diemData, days); // Update per diem based on new days value
            }
        }

        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleGuesthouseChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        // You can add any additional logic related to the guesthouse here.
    };


    const grandTotal = () => {
        const totalHotelAmount = rows.reduce((total, row) => total + (parseFloat(row.hotel_amount) || 0), 0);
        const totalTravelAmount = rows.reduce((total, row) => total + (parseFloat(row.ticket_travel_amount) || 0), 0);
        const totalMisc = rows.reduce((total, row) => total + (parseFloat(row.misc) || 0), 0);
        const totalPerDiem = rows.reduce((total, row) => total + (parseFloat(row.perDiem) || 0), 0);
        // Calculate total per diem based on booking method
        // const totalPerDiem = rows.reduce((total, row) => {
        //     const perDiemValue = row.hotel_booked_by === "TravelDesk" ? parseFloat(row.perDiem1) : parseFloat(row.perDiem);
        //     return total + (isNaN(perDiemValue) ? 0 : perDiemValue);
        // }, 0);

        return totalHotelAmount + totalTravelAmount + totalMisc + totalPerDiem + totalLocalConveyance;
    };



    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Tour/Travel Settlement Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/own_settlement">Settlement Details</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel Settlement Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">

                        {loading ? (
                            <Loader /> // Show loader if loading is true
                        ) : (
                            <>
                                <section className="col-lg-12">
                                    <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                        <div className="box box-info">
                                            <div className="card-body">
                                                <div className="box-body">
                                                    <div className="row">

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Employee No
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="emp_code"
                                                                    id="name"
                                                                    value={formData.emp_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee No/Consultant"
                                                                    readonly="true"

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Name <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="comp_name"
                                                                    id="name"
                                                                    value={formData.comp_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Name"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Employee Band<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="grade"
                                                                    id="name"
                                                                    value={formData.grade}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="dept_name"
                                                                    id="name"
                                                                    value={formData.dept_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="designation_name"
                                                                    id="name"
                                                                    value={formData.designation_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Mobile No
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="mobile"
                                                                    id="name"
                                                                    value={formData.mobile}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Days of travel"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="days">No of Days
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="days"
                                                                    id="days"
                                                                    value={formData.days}
                                                                    onChange={(e) => handleChange('days', e)}
                                                                    onBlur={validateDays}
                                                                    className="form-control input-font"
                                                                    placeholder="1,1.5,2,2.5, etc"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>




                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="travelAdvance">Has advance settled <span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                    name="travel_advance"
                                                                    id="travelAdvance"
                                                                    value={formData.travel_advance}
                                                                    onChange={(e) => handleChange('travel_advance', e)}
                                                                    className="form-control input-font"
                                                                >

                                                                    <option value="">Select</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>


                                                        {Purpose > 0 && (<>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="travelAmount">Travel Advance Amount <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="number"
                                                                        name="travel_amount"
                                                                        id="travelAmount"
                                                                        value={Amount}
                                                                        onChange={(e) => handleChange('travel_amount', e)} // Handle change for travel amount
                                                                        className="form-control input-font"
                                                                        required
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="purpose"> Purpose <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="purpose"
                                                                        id="purpose"
                                                                        value={Purpose}
                                                                        onChange={(e) => handleChange('purpose', e)} // Handle change for purpose
                                                                        className="form-control input-font"
                                                                        required
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div></>)}
                                                      
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="settlement_purpose">Settlement Purpose <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="settlement_purpose"
                                                                        id="settlement_purpose"
                                                                        value={formData.settlement_purpose}
                                                                        onChange={(e) => handleChange('settlement_purpose', e)} // Handle change for settlement purpose
                                                                        className="form-control input-font"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>



                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Reporting Manager
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="mobile"
                                                                    id="name"
                                                                    value={formData.report_mngr}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Days of travel"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="guesthouse">Select City Guest-House
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="guesthouse"
                                                                    id="guesthouse"
                                                                    value={formData.guesthouse}
                                                                    onChange={handleGuesthouseChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select a Guest-House</option>
                                                                    <option value="Hyderabad">Hyderabad</option>
                                                                    <option value="Vishakapatnam">Vishakapatnam</option>
                                                                    <option value="Rajuhmundry">Rajuhmundry</option>
                                                                    <option value="Gurgaon">Gurgaon</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="bank_acnum">Bank Ac Num
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_acnum"
                                                                    id="bank_acnum"
                                                                    value={formData.bank_acnum}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="bank_name">Bank Name
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_name"
                                                                    id="bank_name"
                                                                    value={formData.bank_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="ifsc_code">IFSC Code
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="ifsc_code"
                                                                    id="ifsc_code"
                                                                    value={formData.ifsc_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>




                                                </div>
                                                <div className="expense-table-container">
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                                        {/* <button type="button" onClick={handleAddRow} className="btn btn-primary">+</button> */}
                                                    </div>
                                                    <table className="expense-table">
                                                        <thead>
                                                            <tr className="header-row">
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th>Travel Type</th>
                                                                <th>From Place</th>
                                                                <th>To Place</th>
                                                                <th>Hotel Booked By</th>
                                                                <th>Hotel Receipt</th>
                                                                <th>Hotel Amount</th>
                                                                <th>Travel Ticket Booked By</th>
                                                                <th>Ticket Receipt</th>
                                                                <th>Travel Ticket Amount</th>
                                                                <th>Per Diem Allow</th>
                                                                <th>Local Conveyance</th>
                                                                <th>Misc Exp</th>
                                                                <th>Total Rs.</th>
                                                                {/* <th>Actions</th> */}
                                                            </tr>
                                                        </thead>


                                                        <tbody>
                                                            {rows.map((row, index) => (

                                                                <tr key={row.id}>
                                                                    <td data-label="Date">
                                                                        <input
                                                                            type="date"
                                                                            name="date"
                                                                            value={row.date}
                                                                            onChange={(e) => handleChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            max={new Date().toISOString().split("T")[0]}
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Time">
                                                                        <input
                                                                            type="time"
                                                                            name="time"
                                                                            value={row.time}
                                                                            onChange={(e) => handleChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Travel Type">
                                                                        <select name="travelType" value={row.travelType} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                                                                            <option value="">Select Travel Type</option>

                                                                            {(formData.grade && ['E', 'F'].includes(formData.grade[0])) ? (
                                                                                <>
                                                                                    <option value='Air'>Air</option>
                                                                                    <option value='Train'>Train</option>
                                                                                    <option value='Bus'>Bus</option>
                                                                                    <option value='Local_Taxi'>Local Taxi</option>
                                                                                    <option value='Local_Auto'>Local Auto</option>
                                                                                    <option value='Own_Bike'>Own Bike</option>
                                                                                    <option value='Own_Car'>Own Car</option>
                                                                                </>

                                                                            ) : (
                                                                                <>
                                                                                    <option value='Air'>Air</option>
                                                                                    <option value='Train'>Train</option>
                                                                                    <option value='Bus'>Bus</option>
                                                                                    <option value='Local_Taxi'>Local Taxi</option>
                                                                                    <option value='Local_Auto'>Local Auto</option>
                                                                                    <option value='Own_Bike'>Own Bike</option>
                                                                                    {/* <option value='Own_Car'>Own Car</option> */}
                                                                                </>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="From Place">
                                                                        <select name="from_city" value={row.from_city} onChange={(e) => handleChange1(row.id, e)} className="form-control input-font">
                                                                            <option value="">Select a Tour Place</option>
                                                                            {items.map(city => (
                                                                                <option key={city.id} value={city.id}>
                                                                                    {city.city_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="To Place">
                                                                        <select name="to_city" value={row.to_city} onChange={(e) => handleCityChange(row.id, e)} className="form-control input-font">
                                                                            <option value="">Select a Tour Place</option>
                                                                            {items.map(city => (
                                                                                <option key={city.id} value={city.id}>
                                                                                    {city.city_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="Booked by">
                                                                        <select name="hotel_booked_by" value={row.hotel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                                                                            <option value="">Select Booked By</option>
                                                                            <option value="Self">Self</option>
                                                                            <option value="TravelDesk">Travel Desk</option>
                                                                        </select>
                                                                    </td>
                                                                    <td className="d-flex justify-content-center align-items-center">
                                                                        {row.hotel_receipt && (
                                                                            <a
                                                                                href={row.hotel_receipt}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                className="position-relative"
                                                                            >
                                                                                {row.hotel_receipt.toLowerCase().endsWith('.pdf') ? (
                                                                                    // PDF icon
                                                                                    <img
                                                                                        src={pdficon} // Replace with your PDF icon path
                                                                                        alt="View PDF"
                                                                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                                                                    />
                                                                                ) : (
                                                                                    // Image icon or actual image
                                                                                    <img
                                                                                        src={row.hotel_receipt} // Replace with your actual image path
                                                                                        alt="View Receipt"
                                                                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                                                                    />
                                                                                )}
                                                                            </a>
                                                                        )}
                                                                    </td>


                                                                    <td data-label="Hotel Amount">
                                                                        <input
                                                                            type="number"
                                                                            name="hotel_amount"
                                                                            value={row.hotel_amount}
                                                                            onChange={(e) => handleChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={row.hotel_booked_by === "TravelDesk"}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Booked by">
                                                                        <select name="ticket_travel_booked_by" value={row.ticket_travel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                                                                            <option value="">Select Booked By</option>
                                                                            <option value="Self">Self</option>
                                                                            <option value="TravelDesk">Travel Desk</option>
                                                                        </select>
                                                                    </td>
                                                                    {/* <td>
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            {row.ticket_receipt && (
                                                                                <a href={row.ticket_receipt} target="_blank1" rel="noopener noreferrer" className="position-relative">
                                                                                    <img
                                                                                        src={icon1}
                                                                                        alt="Receipt"
                                                                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }} // Add rounded corners
                                                                                    />
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    </td> */}
                                                                    <td className="d-flex justify-content-center align-items-center">
                                                                        {row.ticket_receipt && (
                                                                            <a
                                                                                href={row.ticket_receipt}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                className="position-relative"
                                                                            >
                                                                                {row.ticket_receipt.toLowerCase().endsWith('.pdf') ? (
                                                                                    // PDF icon
                                                                                    <img
                                                                                        src={pdficon} // Replace with your PDF icon path
                                                                                        alt="View PDF"
                                                                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                                                                    />
                                                                                ) : (
                                                                                    // Image icon or actual image
                                                                                    <img
                                                                                        src={row.ticket_receipt} // Replace with your actual image path
                                                                                        alt="View Receipt"
                                                                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                                                                    />
                                                                                )}
                                                                            </a>
                                                                        )}
                                                                    </td>
                                                                    <td data-label="Travel Amount">
                                                                        <input
                                                                            type="number"
                                                                            name="ticket_travel_amount"
                                                                            value={row.ticket_travel_amount}
                                                                            onChange={(e) => handleChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={row.ticket_travel_booked_by === "TravelDesk"}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Per Diem Allow">
                                                                        <input
                                                                            type="number"
                                                                            name="perDiem"
                                                                            value={row.perDiem}
                                                                            onChange={(e) => handleChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            required
                                                                            // disabled={index !== 0}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Local Conveyance">
                                                                        <input
                                                                            type="number"
                                                                            name="local"
                                                                            value={index === 0 ? totalLocalConveyance : ''} // Set only in the first row
                                                                            onChange={(e) => handleChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={index !== 0} // Disable for other rows
                                                                        />
                                                                    </td>


                                                                    <td data-label="Misc">
                                                                        <input
                                                                            type="number"
                                                                            name="misc"
                                                                            value={row.misc}
                                                                            onChange={(e) => handleChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                        />
                                                                    </td>
                                                                    <td data-label="Total Rs.">
                                                                        <input
                                                                            type="number"

                                                                            value={
                                                                                parseFloat(
                                                                                    (parseFloat(row.perDiem)) +
                                                                                    parseFloat(row.hotel_amount) +
                                                                                    parseFloat(row.ticket_travel_amount) +
                                                                                    parseFloat(row.misc) +
                                                                                    (index === 0 ? totalLocalConveyance : 0)
                                                                                )
                                                                            }
                                                                            className="form-control input-font"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    {/* <td>
                                                                        <button type="button" onClick={() => handleRemoveRow(row.id, parseFloat(row.hotel_amount) + parseFloat(row.ticket_travel_amount) + parseFloat(row.misc), grandTotal() - parseFloat(formData.travel_amount))} className="btn btn-danger">
                                                                            <i className="fas fa-times"></i>
                                                                        </button>
                                                                    </td> */}
                                                                </tr>
                                                            ))}
                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                <th colSpan="14" style={{ textAlign: 'right' }}>Grand Total</th>
                                                                <th style={{ textAlign: 'center' }}>{grandTotal()}</th>
                                                                <th></th>
                                                            </tr>

                                                            {/* <tr>
                                                                <th colSpan="14" style={{ textAlign: 'right' }}>Less Advance</th>
                                                                <th style={{ textAlign: 'center' }}>{Amount !== '' ? Math.floor(Amount) : ''}</th>
                                                                <th></th>
                                                            </tr> */}
                                                            <tr>
                                                                <th colSpan="14" style={{ textAlign: 'right' }}>Total</th>
                                                                <th style={{ textAlign: 'center' }}>{grandTotal()}</th>

                                                                <th></th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className="col-lg-12">
                                    <div className="box box-info">
                                        <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                            <center>
                                                <span className='btn btn-primary' style={{ fontSize: '12px' }}>Details of Local Conveyance</span>
                                            </center>

                                            <div className="transport-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                                {/* <button type="button" onClick={handleAddTransportRow} className="btn btn-primary">+</button> */}
                                                <table className="expense-table">
                                                    <thead>
                                                        <tr className="header-row">
                                                            <th style={{ textAlign: 'center' }}>Travel Date</th>
                                                            <th style={{ textAlign: 'center' }}>Origin</th>
                                                            <th style={{ textAlign: 'center' }}>Destination</th>
                                                            <th style={{ textAlign: 'center' }}>Amount</th>
                                                            <th style={{ textAlign: 'center' }}>Transport Mode</th>
                                                            <th style={{ textAlign: 'center' }}>Receipt/Bill</th>
                                                            {/* <th>Actions</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transportRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <input
                                                                        type="date"
                                                                        name="travelDate"
                                                                        value={row.travelDate}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        max={new Date().toISOString().split("T")[0]}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="origin"
                                                                        value={row.origin}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="From"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="destination"
                                                                        value={row.destination}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="To"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        name="travelAmount"
                                                                        value={row.travelAmount}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        name="transportMethod"
                                                                        value={row.transportMethod}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                    >
                                                                        <option value="">Select Mode</option>
                                                                        <option value="bus">Bus</option>
                                                                        <option value="auto">Auto</option>
                                                                        <option value="taxi">Taxi</option>
                                                                        <option value="Car">Own Vehicle-Car</option>
                                                                        <option value="Bike">Own Vehicle-Bike</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <input
                                                                            type="file"
                                                                            name="receipt"
                                                                            accept="image/*,application/pdf"
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font me-2" // Add margin to separate the input and image
                                                                        />
                                                                        {row.receipt && typeof row.receipt === 'string' && (
                                                                            <a href={row.receipt} target="_blank" rel="noopener noreferrer" className="position-relative">
                                                                                {row.receipt.toLowerCase().endsWith('.pdf') ? (
                                                                                    // Show PDF icon if the file is a PDF
                                                                                    <img
                                                                                        src={pdficon}// Replace with your PDF icon path
                                                                                        alt="Receipt PDF"
                                                                                        style={{ width: '50px', height: '50px', borderRadius: '4px' }} // Add rounded corners
                                                                                    />
                                                                                ) : (
                                                                                    // Show image if it's not a PDF
                                                                                    <img
                                                                                        src={row.receipt} // Actual image file path
                                                                                        alt="Receipt"
                                                                                        style={{ width: '50px', height: '50px', borderRadius: '4px' }} // Add rounded corners
                                                                                    />
                                                                                )}
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </td>


                                                                {/* <td>
                                                                    <button type="button" onClick={() => handleRemoveTransportRow(row.id, row.travelAmount, grandTotal() - parseFloat(formData.travel_amount))} className="btn btn-danger">x</button>
                                                                </td> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="3" style={{ textAlign: 'right' }}>Total Amount:</td>
                                                            <td>{totalLocalConveyance}</td>
                                                            <td colSpan="3"></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                <center><span className="btn btn-primary" style={{ fontSize: '12px' }}>Additional Documents</span></center>
                                                <div className="document-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>

                                                    <table className="document-table" style={{ marginTop: '10px' }}>
                                                        <thead>
                                                            <tr>

                                                                <th style={{ textAlign: 'center' }}>Click to Preview</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* Loop through documentsRows and display each document's image */}
                                                            {documentsRows.length > 0 ? (
                                                                <tr>
                                                                    <td colSpan="1" style={{ textAlign: 'center' }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                                                            {documentsRows.map((document) => (
                                                                                <div key={document.id} style={{ margin: '10px' }}>
                                                                                    <a href={document.additional_doc} target="_blank" rel="noopener noreferrer" className="position-relative">
                                                                                        {document.additional_doc.toLowerCase().endsWith('.pdf') ? (
                                                                                            // If the document is a PDF, show the PDF icon
                                                                                            <img
                                                                                                src={pdficon}// Replace with the actual path to your PDF icon
                                                                                                alt={`PDF Document ${document.id}`}
                                                                                                style={{
                                                                                                    width: '100px',
                                                                                                    height: '100px',
                                                                                                    objectFit: 'cover',
                                                                                                    margin: '5px',
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            // Otherwise, show the image
                                                                                            <img
                                                                                                src={document.additional_doc}
                                                                                                alt={`Document ${document.id}`}
                                                                                                style={{
                                                                                                    width: '79px',
                                                                                                    height: '100px',
                                                                                                    objectFit: 'cover',
                                                                                                    margin: '5px',
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </a>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="1" style={{ textAlign: 'center' }}>
                                                                        {error ? error : 'No documents available.'}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                        
                                                    </table>
                                                    <div className="form-group">
                                                <label htmlFor="documents">Upload Documents</label>
                                                <input
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                   onChange={handleFileChange}
                                                    name = "additional_doc"
                                                    multiple
                                                    className="form-control input-font"
                                                    
                                                />
                                            </div>
                                                </div>
                                            </fieldset>
                                            <div class="row">{formData.rpt_mgr_status === 'Approved' && (<div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="rpt_mgr_status">Reporting Manager Status
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select
                                                        name="rpt_mgr_status"
                                                        id="rpt_mgr_status"
                                                        value={formData.rpt_mgr_status}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        readonly="true"
                                                        disabled
                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                            </div>)}
                                                {formData.manager_approved_amount > 0 && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Reporting Manager Approved Amount
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="manager_approved_amount"
                                                            id="name"
                                                            value={formData.manager_approved_amount !== '' ? Math.floor(formData.manager_approved_amount) : ''}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Manager Approved Amount"
                                                            min="1"   
                                                                step="1"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>)}
                                                {(formData.manager_remarks !== '' && formData.rpt_mgr_status === 'Approved') && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="remarks">Reporting Manager Remarks
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>

                                                        <textarea name="manager_remarks"
                                                            readonly="true"
                                                            id="remarks"
                                                            value={formData.manager_remarks}
                                                            onChange={handleChange}
                                                            rows = '1'
                                                            className="form-control "
                                                            placeholder="Remarks"></textarea>
                                                    </div>
                                                </div>)}
                                            </div>
                                            <div class="row">{formData.audit_status === 'Approved' && (<div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="audit_status">Audit Status
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select
                                                        name="audit_status"
                                                        id="audit_status"
                                                        value={formData.audit_status}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        readonly="true"
                                                        disabled
                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                            </div>)}
                                                {formData.audit_approved_amount > 0 && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Audit Approved Amount
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="audit_approved_amount"
                                                            id="name"
                                                            value={formData.audit_approved_amount !== '' ? Math.floor(formData.audit_approved_amount) : ''}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            min="1"   
                                                                step="1"
                                                            placeholder="Audit Approved Amount"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>)}
                                                {formData.audit_remarks !== '' && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="remarks">Audit Remarks
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>

                                                        <textarea name="audit_remarks"
                                                            readonly="true"
                                                            id="remarks"
                                                            value={formData.audit_remarks}
                                                            onChange={handleChange}
                                                            rows = '1'
                                                            className="form-control "
                                                            placeholder="Remarks"></textarea>
                                                    </div>
                                                </div>)}
                                            </div>
                                            <div className="row">{formData.account_status === 'Approved' && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="account_status">Finance Status
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <select
                                                            name="account_status"
                                                            id="account_status"
                                                            value={formData.account_status}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            required
                                                            readonly="true"
                                                        >
                                                            <option value="Pending">Pending</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                        </select>
                                                    </div>
                                                </div>)}

                                                {formData.finance_approved_amount > 0 && (
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Finance Approved Amount</label>
                                                            <span style={{ color: 'red' }}>*</span>
                                                            <input
                                                                type="text"
                                                                name="accounts_approved_amount"
                                                                id="name"
                                                                value={formData.finance_approved_amount !== '' ? Math.floor(formData.finance_approved_amount) : ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                                min="1"   
                                                                step="1"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>)}


                                                {formData.finance_remarks !== '' && (
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="remarks">Finance Remarks</label>
                                                            <span style={{ color: 'red' }}>*</span>
                                                            <textarea
                                                                name="accounts_remarks"
                                                                id="remarks"
                                                                value={formData.finance_remarks}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                required
                                                                rows = '1'
                                                                readonly="true"
                                                            ></textarea>
                                                        </div>
                                                    </div>)}

                                                {(formData.rpt_mgr_status === 'Rejected' || formData.audit_status === 'Rejected' || formData.account_status === 'Rejected') && (
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="status_select">
                                                                Request Type

                                                                <span style={{ color: 'red' }}>*</span>
                                                                (<small style={{ color: '#888', fontStyle: 'italic' }}>
                                                                    For Requesting Again to the Reporting Manager or Hotel Manager make this "Pending".
                                                                </small>)
                                                            </label>

                                                            <select
                                                                name="status_select"
                                                                id="status_select"
                                                                value={formData.status_select || ''}
                                                                onChange={handleChange2}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="">Select Request</option>

                                                                {/* Conditionally enable only the appropriate option */}
                                                                {formData.rpt_mgr_status === 'Rejected' && (
                                                                    <option value="request_for_manager">Request For Manager</option>
                                                                )}
                                                                {formData.audit_status === 'Rejected' && (
                                                                    <option value="request_for_audit_mngr">Request For Audit Manager</option>
                                                                )}
                                                                {formData.account_status === 'Rejected' && (
                                                                    <option value="request_for_account_mngr">Request For Finance Manager</option>
                                                                )}

                                                            </select>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* Based on selected Request Type, show the corresponding dropdowns */}
                                                {formData.status_select && (
                                                    <div className="col-md-4">
                                                        {formData.status_select === 'request_for_manager' && (
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">
                                                                    Request For Manager
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="rpt_mgr_status"
                                                                    id="rpt_mgr_status"
                                                                    value={formData.rpt_mgr_status || ''}
                                                                    onChange={handleChange2}
                                                                    className="form-control input-font"
                                                                    required
                                                                >
                                                                    <option value="Pending">Pending</option>
                                                                </select>
                                                            </div>
                                                        )}

                                                        {formData.status_select === 'request_for_audit_mngr' && (
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="audit_status">
                                                                    Request For Audit Manager
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="audit_status"
                                                                    id="audit_status"
                                                                    value={formData.audit_status || ''}
                                                                    onChange={handleChange2}
                                                                    className="form-control input-font"
                                                                    required
                                                                >
                                                                    <option value="Pending">Pending</option>
                                                                </select>
                                                            </div>
                                                        )}

                                                        {formData.status_select === 'request_for_account_mngr' && (
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="account_status">
                                                                    Request For Finance Manager
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="account_status"
                                                                    id="account_status"
                                                                    value={formData.account_status || ''}
                                                                    onChange={handleChange2}
                                                                    className="form-control input-font"
                                                                    required
                                                                >
                                                                    <option value="Pending">Pending</option>
                                                                </select>
                                                            </div>
                                                        )}


                                                    </div>
                                                )}

                                            </div>
                                            <div class="row">{formData.utr !== '' && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">UTR
                                                        </label><span style={{ color: 'red' }}>*</span>
                                                        <input
                                                            type="text"
                                                            name="utr"
                                                            id="name"
                                                            value={formData.utr}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="UTR"
                                                            required
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>)}{formData.utr_date !== '' && (
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="date">Date</label><span style={{ color: 'red' }}>*</span>
                                                            <input
                                                                type="date"
                                                                name="utr_date"
                                                                id="utr_date" // Use a unique id
                                                                value={formData.utr_date} // Bind it to your form state
                                                                onChange={handleChange} // Handle changes with the same function
                                                                className="form-control input-font"
                                                                placeholder="Select a date" // Optional placeholder text
                                                                required
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>)}

                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="col-sm-12 row" style={{ width: '100%' }}>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            <b>Prepared by </b>
                                            <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                : {formData.created_on}
                                            </p>
                                        </div>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            {formData.rpt_mgr_status === 'Approved' && (
                                                <>
                                                    <b> Approved by</b>
                                                    <p> {formData.report_mngr} ({ReportManageName})<br /> Date
                                                        : {formData.manager_approved_date}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            {formData.audit_status === 'Approved' && (
                                                <>
                                                    <b> Verified by Audit Team</b>
                                                    <p>                         <span>
                                                        {formData.audit_manager} ({AuditManageName} )   <br /> Date
                                                        : {formData.audit_approved_date}
                                                    </span>
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            {formData.account_status === 'Approved' && (
                                                <>
                                                    <b> Verified by Finance Team</b>
                                                    <p>                         <span>
                                                        {formData.accounts_manager} ({AccountsManageName} )   <br /> Date
                                                        : {formData.finance_approved_date}
                                                    </span>
                                                    </p>
                                                </>
                                            )}
                                        </div>



                                    </div>
                                </section>

                            </>
                        )}

                        <div className="box-footer" style={{ textAlign: 'center' }}>
                            {formData.account_status !== 'Approved' && (
                                <button type="submit" className="btn btn-success pull-right">Submit</button>
                            )}


                        </div>
                    </form>
                </div>

                <Footer />
            </div>

            <ToastContainer />
        </div>
    );
};

export default EditSettlementForm;
