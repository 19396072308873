import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);
const EditTravelAdvance = () => {
    const { id } = useParams();
    const [RptMgr, setRptMngr] = useState('');
    const [ReportManagerName, setReportManagerName] = useState('');
    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        // Get hours and minutes
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? String(hours).padStart(2, '0') : '12'; // Handle 0 as 12

        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    };
    const [EmpCode, setEmpCode] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [purposes, setPurposes] = useState([]);
    const [items, setItems] = useState([]);
    const [showCustomInput, setShowCustomInput] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        comp_name: '',
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        purpose: '',
        mode_of_travel: '',
        travel_advance: '',
        travel_advance_amount: '',
        days: '',
        city: '',
        hotel_tickets: '',
        travel_tickets: '',
        manager_remarks: '',
        accounts_remarks: '',
        manager_approved_amount: '',
        accounts_approved_amount: '',
        account_status: '',
        utr: '',
        utr_date: '',
        manager_approved_date: '',
        accounts_approved_date: '',
        accounts_manager: '',
        created_on: '',
        focus_code: '',
        bank_acnum: '',
        bank_name: '',
        ifsc_code: '',
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [isApproved, setIsApproved] = useState(false);
    const navigate = useNavigate();

    const validateFormData = () => {
        const { comp_name, emp_code, grade } = formData;
        if (!comp_name | !emp_code | !grade) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Convert UTR input to uppercase if the name is 'utr'
        const newValue = name === 'utr' ? value.toUpperCase() : value;
        const newValue1 = name === 'focus_code' ? value.toUpperCase() : value;
		
		 if (name === 'accounts_approved_amount') {
			 
            const travelAdvance = parseFloat(formData.travel_advance_amount) || 0;
			console.log("checkas1",(parseFloat(value)));
            console.log("checkas2",(parseFloat(formData.manager_approved_amount)));
           
            // if (parseFloat(value) > travelAdvance) {
				
            //     setError('Finance Approved Amount must be less than or equal to Travel Advance Requisitioned.');
            // } else {
            //     setError('');
            // }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
            [name]: newValue1,
        }));
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!validateFormData()) return;
		
		const travelAdvance = parseFloat(formData.travel_advance_amount) || 0;
        // if ((parseFloat(formData.manager_approved_amount)) !== (parseFloat(formData.accounts_approved_amount))) {
        //     setError('Manager Approved Amount must be equal to Finance Approvbed amount.');
        //     return;
        // }
        if (parseFloat(formData.accounts_approved_amount) > travelAdvance) {
            setError('Finance Approved Amount must be less than or equal to Travel Advance Requisitioned.');
            return; // Prevent submission
        }
        // const approvedAmount = parseFloat(formData.accounts_approved_amount) || 0;
        // if (approvedAmount <= 0) {
        //     setError('Finance Approved Amount must be greater than 0.');
        //     return; // Prevent submission
        // }
        setSubmitted(true);

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });
        const dataToSubmit = {
            ...formData
        };
        try {
            const token = localStorage.getItem('authToken');
            console.log("check tokens", token);
            const response = await fetch(`${API_BASE_URL}updateAdvanceForm/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSubmit),
            });

            const result = await response.json();
            console.log("advanceformdata", result.data);
            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({
                    comp_name: '',
                    emp_code: ''
                });
                navigate('/finance_form');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}getadvance?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("Profile data12:", result);

                if (result.status) {
                    setEmpCode(result.advance.emp_code || '');
                    setReportManagerName(result.reportManagerName.comp_name || '');
                    const report_mngr = result.advance.report_mngr || '';
                    const account_status = result.advance.account_status || '';
                    setFormData({
                        comp_name: result.advance.comp_name || '',
                        emp_code: result.advance.emp_code || '',
                        trans_code: result.advance.trans_code || '',
                        focus_code: result.advance.focus_code || '',
                        dept_name: result.advance.dept_name || '',
                        designation_name: result.advance.designation_name || '',
                        purpose: result.advance.purpose || '',
                        customPurpose: result.advance.customPurpose || '',
                        mode_of_travel: result.advance.mode_of_travel || '',
                        travel_advance_amount: result.advance.travel_advance_amount || '',
                        days: result.advance.days || '',
                        city: result.advance.city || '',
                        travel_advance: result.advance.travel_advance || '',
                        bank_details: result.advance.bank_details || '',
                        grade: result.advance.grade || '',
                        mobile: result.advance.mobile || '',
                        rpt_mgr_status: result.advance.rpt_mgr_status || '',
                        report_mngr: report_mngr,
                        manager_remarks: result.advance.manager_remarks || '',
                        accounts_remarks: result.advance.accounts_remarks || '',
                        manager_approved_amount: result.advance.manager_approved_amount || '',
                        accounts_approved_amount: result.advance.accounts_approved_amount || '',
                        account_status: account_status || '',
                        utr: result.advance.utr || '',
                        utr_date: result.advance.utr_date || '',
                        manager_approved_date: result.advance.manager_approved_date || '',
                        accounts_approved_date: result.advance.accounts_approved_date || '',
                        accounts_manager: result.advance.accounts_manager || '',
                        created_on: result.advance.created_on || '',
                        hotel_tickets: result.advance.hotel_tickets || '',
                        travel_tickets: result.advance.travel_tickets || '',
                        bank_acnum: result.advance.bank_acnum || '',
                        bank_name: result.advance.bank_name || '',
                        ifsc_code: result.advance.ifsc_code || '',
                        
                    });
                    setIsApproved(account_status === 'Approved');
                    //fetchReportingData(report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("get all cities data:", result);

                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    const currentDateTime = formatDateTime(new Date());

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');

                if (!token) return;

                const response = await fetch(`${API_BASE_URL}purposes`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setPurposes(result.data); // Set the fetched purposes
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">

                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Tour/Travel Advance Form {formData.trans_code && `(${formData.trans_code})`}</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Tour/Travel Advance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">


                                <div className="card-body">
                                    {loading ? (
                                        <Loader /> // Show loader if loading is true
                                    ) : (
                                        <>
                                            <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                                <div className="box-body">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Name <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="comp_name"
                                                                    id="name"
                                                                    value={formData.comp_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Name"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Employee No/Consultant
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="emp_code"
                                                                    id="name"
                                                                    value={formData.emp_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee No/Consultant"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="dept_name"
                                                                    id="name"
                                                                    value={formData.dept_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="designation_name"
                                                                    id="name"
                                                                    value={formData.designation_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>



                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Employee Band as per Category  <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="grade"
                                                                    id="name"
                                                                    value={formData.grade}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        {!formData.customPurpose && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="purpose">
                                                                        Purpose <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    {loading ? (
                                                                        <p>Loading purposes...</p>
                                                                    ) : error ? (
                                                                        <p>{error}</p>
                                                                    ) : (
                                                                        <select
                                                                            name="purpose"
                                                                            id="purpose"
                                                                            value={formData.purpose}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                // Show custom input if "Others" is selected
                                                                                setShowCustomInput(e.target.value === "Others");
                                                                            }}
                                                                            className="form-control input-font"
                                                                            required
                                                                            readOnly
                                                                        >
                                                                            <option value="">Select a purpose</option>
                                                                            {purposes.map(item => (
                                                                                <option key={item.id} value={item.purpose}>
                                                                                    {item.purpose}
                                                                                </option>
                                                                            ))}
                                                                            <option value="Others">Others</option>
                                                                        </select>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* Show Specify Purpose input only if purpose is "Others" or customPurpose is set */}
                                                        {(formData.purpose === 'Others' || formData.customPurpose) && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="customPurpose">
                                                                        Specify Purpose <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <textarea
                                                                        name="customPurpose"
                                                                        id="customPurpose"
                                                                        placeholder="Specify your purpose"
                                                                        value={formData.customPurpose}
                                                                        onChange={handleChange}
                                                                        className="form-control input-font"
                                                                        readOnly
                                                                        rows={1} // Adjust the number of rows as needed
                                                                    // Optional: restrict to 12 characters
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="mode_of_travel">Mode of Travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="mode_of_travel"
                                                                    id="mode_of_travel"
                                                                    value={formData.mode_of_travel}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    readonly="true"
                                                                    disabled
                                                                >
                                                                                                                                            <option value={formData.mode_of_travel}>{formData.mode_of_travel}</option>
                                                                    <option value={formData.mode_of_travel}>{formData.mode_of_travel}</option>
                                                                                                                                            <option value={formData.mode_of_travel}>{formData.mode_of_travel}</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Travel Advance requisitioned
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="travel_advance_amount"
                                                                    id="name"
                                                                    value={formData.travel_advance_amount}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Travel Advance requisitioned"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="travelAdvance">Has earlier travel advance settled <span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                    name="travel_advance"
                                                                    id="travelAdvance"
                                                                    value={formData.travel_advance}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    readonly="true"
                                                                    disabled
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="days">Days of travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="days"
                                                                    id="days"
                                                                    value={formData.days}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="1,1.5,2,2.5,etc"
                                                                    required
                                                                    min="1" // Prevents entering 0 or negative numbers
                                                                    step="0.1"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="city">Place to travel
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="city"
                                                                    id="city"
                                                                    value={formData.city}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    readonly="true"
                                                                    disabled
                                                                >
                                                                    <option value="">Select a city</option>
                                                                    {items.map((city) => (
                                                                        <option key={city.id} value={city.city_name}>
                                                                            {city.city_name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div class="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Reporting To
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="report_mngr"
                                                                    id="name"
                                                                    value={formData.report_mngr}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Days of travel"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Mobile No
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="mobile"
                                                                    id="name"
                                                                    value={formData.mobile}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Days of travel"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="hotel_tickets">Hotel/Tickets Booked by
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="hotel_tickets"
                                                                    id="hotel_tickets"
                                                                    value={formData.hotel_tickets}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    readonly="true"
                                                                    disabled
                                                                >

                                                                    <option value="Self">Self</option>
                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row"><div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="travel_tickets">Travel/Tickets Booked by
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="travel_tickets"
                                                                id="travel_tickets"
                                                                value={formData.travel_tickets}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                readonly="true"
                                                                disabled
                                                            >

                                                                <option value="Self">Self</option>
                                                                <option value="TravelDesk">Travel Desk</option>
                                                            </select>
                                                        </div>
                                                    </div></div>
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="name">Bank Ac Num </label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_acnum"
                                                                    id="name"
                                                                    value={formData.bank_acnum}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Department&Designation"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="bank_name">Bank Name </label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_name"
                                                                    id="bank_name"
                                                                    value={formData.bank_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="ifsc_code">IFSC Code </label>
                                                                <input
                                                                    type="text"
                                                                    name="ifsc_code"
                                                                    id="ifsc_code"
                                                                    value={formData.ifsc_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee Band as per Category"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className='row'><div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="rpt_mgr_status">Manager Status
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="rpt_mgr_status"
                                                                    id="rpt_mgr_status"
                                                                    value={formData.rpt_mgr_status}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    readonly="true"
                                                                    disabled
                                                                >

                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Approved">Approved</option>
                                                                    <option value="Rejected">Rejected</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Manager Approved Amount
                                                                <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="manager_approved_amount"
                                                                    id="name"
                                                                    value={formData.manager_approved_amount}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Manager Approved Amount"
                                                                    readonly="true"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="remarks">Manager Remarks
                                                                <span style={{ color: 'red' }}>*</span>
                                                                </label>

                                                                <textarea name="manager_remarks"
                                                                    readonly="true"
                                                                    id="remarks"
                                                                    value={formData.manager_remarks}
                                                                    onChange={handleChange}
                                                                    className="form-control "
                                                                    disabled
                                                                    placeholder="Remarks"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="account_status">Finance Status
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    name="account_status"
                                                                    id="account_status"
                                                                    value={formData.account_status}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    required
                                                                >
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Approved">Approved</option>
                                                                    <option value="Rejected">Rejected</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {formData.account_status !== 'Rejected' && (
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Finance Approved Amount</label>
                                                                <span style={{ color: 'red' }}>*</span>
                                                                <input
                                                                    type="number"
                                                                    name="accounts_approved_amount"
                                                                    id="name"
                                                                    value={formData.accounts_approved_amount}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    required
                                                                    min="1"   // Disallow zero and negative values
                                                                    step="any"    // Allows decimals, but restricts values to be above 0
                                                                />
                                                            </div>
															{error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
                                                        </div>)}
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="remarks">Finance Remarks</label>
                                                                <span style={{ color: 'red' }}>*</span>
                                                                <textarea
                                                                    name="accounts_remarks"
                                                                    id="remarks"
                                                                    value={formData.accounts_remarks}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </div>




                                                    </div>
                                                    <div class="row">
                                                        
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">UTR
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="utr"
                                                                    id="name"
                                                                    value={formData.utr}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="UTR"
                                                                    
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="date">Date</label>
                                                                <input
                                                                    type="date"
                                                                    name="utr_date"
                                                                    id="utr_date" // Use a unique id
                                                                    value={formData.utr_date} // Bind it to your form state
                                                                    onChange={handleChange} // Handle changes with the same function
                                                                    className="form-control input-font"
                                                                    placeholder="Select a date" // Optional placeholder text
                                                                    
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="focus_code">Focus Code
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="focus_code"
                                                                    id="focus_code"
                                                                    value={formData.focus_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="focus code"
                                                                    
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                            </form>

                                            <div className="col-sm-12 row" style={{ width: '100%' }}>
                                                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                                    <b>Prepared by </b>
                                                    <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                        : {formData.created_on}
                                                    </p>
                                                </div>
                                                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                                    <b> Approved by</b>
                                                    <p>                         <span>
                                                        {formData.report_mngr} ({ReportManagerName}) <br /> Date : {formData.manager_approved_date}

                                                    </span>
                                                    </p>
                                                </div>



                                            </div>
                                        </>
                                    )}
                                </div>

                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>

            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default EditTravelAdvance;
