import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css'; // Include your CSS styles if any (Bootstrap is required for this example)
//import { BASE_URL } from "./Constants.js";
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);
const MultiCheckboxDropdown = () => {
    const [selectedCities, setSelectedCities] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [cities, setCities] = useState([]);
    const [items, setItems] = useState([]);
    const [EmpCode, setEmpCode] = useState('');
    const [sortColumn, setSortColumn] = useState('SysNo');
    const [filteredItems, setFilteredItems] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [RptMgr, setRptMgr] = useState('');
    const [error, setError] = useState('');
    const [startDate, setStartDate] = useState(null);  // For date picker
    const [endDate, setEndDate] = useState(null);      // For date picker
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();
    useAuth();
    // Fetch profile data
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');
                    setRptMgr(result.profile.report_mngr || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    // Fetch cities data when the component mounts
    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);

                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("get all cities data:", result);

                if (result.status) {
                    setCities(result.data);  // Set the cities from API response
                    setFilteredCities(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Fetch items based on EmpCode
    useEffect(() => {
        const fetchItems = async (EmpCode) => {
            try {
                const token = localStorage.getItem('authToken');

                if (!token || !EmpCode) return;

                const response = await fetch(`${API_BASE_URL}getadvance?emp_code=${encodeURIComponent(EmpCode)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setItems(result.advance);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems(EmpCode);
        }
    }, [EmpCode, navigate]);



    // Handle checkbox changes to update the selected cities
    const handleCheckboxChange = (cityName) => {
        setSelectedCities(prevSelectedCities => {
            if (prevSelectedCities.includes(cityName)) {
                return prevSelectedCities.filter(city => city !== cityName);  // Remove if already selected
            } else {
                return [...prevSelectedCities, cityName];  // Add if not selected
            }
        });
    };

    // Toggle the dropdown visibility
    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            CompleteName: item.comp_name,
            Employeecode: item.emp_code,
            TransCode: item.trans_code,
            Grade: item.grade,
            Purpose: item.purpose,
            ReportingManager: item.report_mngr,
            Days: item.days,
            TravelAdvanceAmount: item.travel_advance_amount,
            ReportingManagerStatus: item.rpt_mgr_status,
          
            FinanceStatus: item.account_status,
            City: item.city,
            Date: item.created_on
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Advance');
        XLSX.writeFile(workbook, 'Advance Reports.xlsx');
    };

    useEffect(() => {
        const filtered = items.filter(item => {
            const matchesSearch = item.comp_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.trans_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.emp_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.days.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.report_mngr.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.rpt_mgr_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.account_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.created_on.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.accounts_approved_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.manager_approved_amount.toLowerCase().includes(searchQuery.toLowerCase());

            const matchesCity = selectedCities.length === 0 || selectedCities.includes(item.city);
            // Date range matching logic
            const matchesDateRange = (
                (!startDate || new Date(item.created_on).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0)) &&
                (!endDate || new Date(item.created_on).setHours(23, 59, 59, 999) <= new Date(endDate).setHours(23, 59, 59, 999))
            );

            return matchesSearch && matchesCity && matchesDateRange;
        });

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder, startDate, endDate, selectedCities]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleItemsPerPageChange = e => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        let sortedItems;
        if (column === 'sno') {
            sortedItems = [...items].sort((a, b) => {
                const indexA = items.indexOf(a);
                const indexB = items.indexOf(b);
                return sortOrder === 'asc' ? indexA - indexB : indexB - indexA;
            });
        } else {
            sortedItems = [...items].sort((a, b) => {
                if (a[column] < b[column]) return sortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setItems(sortedItems);
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    // Handle search query changes and filter cities
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query);

        // Filter cities based on search query
        const filtered = cities.filter(city => city.city_name.toLowerCase().includes(query));
        setFilteredCities(filtered);
    };

    const handleSearchChange1 = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Advance Reports</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                        <div className="card-header">
                                            <button
                                                className="btn btn-success"
                                                style={{ float: 'right' }}
                                                onClick={exportToExcel}
                                            >
                                                Export to Excel
                                            </button>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {/* Items Per Page Selector */}
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <select
                                                            className="form-control custom-select-sm"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </div>
                                                </div>



                                                <div className="col-md-2">
                                                    <div className="form-group">

                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            selectsStart
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            placeholderText="Select Start Date"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">

                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={(date) => setEndDate(date)}
                                                            selectsEnd
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            minDate={startDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            placeholderText="Select End Date"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 p-0">
                                                    <div className="form-group">

                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-secondary dropdown-toggle form-control"
                                                                type="button"
                                                                id="dropdownMenuButton"
                                                                aria-expanded="false"
                                                                onClick={toggleDropdown}
                                                            >
                                                                {selectedCities.length === 0
                                                                    ? 'Select Cities'
                                                                    : `${selectedCities.length} Cities Selected`}
                                                            </button>

                                                            {isOpen && (
                                                                <div className="dropdown-menu show" style={{
                                                                    maxHeight: '300px', // Max height to show only 10 items
                                                                    overflowY: 'auto', // Enables scroll if items exceed 10
                                                                    width: '100%'
                                                                }} aria-labelledby="dropdownMenuButton">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search Cities"
                                                                        value={searchQuery1}
                                                                        onChange={handleSearchChange}
                                                                        style={{ marginBottom: '10px' }}
                                                                    />
                                                                    {filteredCities.length > 0 ? (
                                                                        filteredCities.map((city) => (
                                                                            <div key={city.id} className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id={`city-${city.id}`}
                                                                                    value={city.city_name}
                                                                                    checked={selectedCities.includes(city.city_name)}
                                                                                    onChange={() => handleCheckboxChange(city.city_name)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`city-${city.id}`}>
                                                                                    {city.city_name}
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div>Loading cities...</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">

                                                        <label>
                                                            <input
                                                                type="search"
                                                                value={searchQuery}
                                                                onChange={handleSearchChange1}
                                                                className="form-control form-control-sm"
                                                                placeholder="Search"
                                                                aria-controls="example1"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>


                                            </div>

                                            {loading && <Loader />}
                                            {error && <div className="alert alert-danger">{error}</div>}

                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th onClick={() => handleSort('comp_name')}>Employee Name</th>
                                                            <th onClick={() => handleSort('trans_code')}>Trans Code</th>
                                                            <th onClick={() => handleSort('emp_code')}>Employee Code</th>
                                                            <th onClick={() => handleSort('days')}>Days</th>
                                                            <th onClick={() => handleSort('city')}>City</th>
                                                            <th onClick={() => handleSort('city')}>Travel Advance Amount</th>
                                                            <th onClick={() => handleSort('report_mngr')}>Reporting Manager</th>
                                                            <th onClick={() => handleSort('rpt_mgr_status')}>Reporting Manager Status</th>
                                                            <th onClick={() => handleSort('accounts_status')}>Finance Status</th>
                                                            <th onClick={() => handleSort('city')}>Date</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentItems.length > 0 ? (
                                                            currentItems.map(item => (
                                                                <tr key={item.trans_code}>
                                                                    <td>{item.comp_name}</td>
                                                                    <td>{item.trans_code}</td>
                                                                    <td>{item.emp_code}</td>
                                                                    <td>{item.days}</td>
                                                                    <td>{item.city}</td>
                                                                    <td>
                                                                        {item.accounts_approved_amount ? item.accounts_approved_amount : item.manager_approved_amount}
                                                                    </td>
                                                                    <td>{item.report_mngr}</td>
                                                                    <td style={{ color: item.rpt_mgr_status === 'Pending' ? 'orange' : item.rpt_mgr_status === 'Rejected' ? 'red' : item.rpt_mgr_status === 'Approved' ? 'green' : 'inherit' }}>
                                                                        {item.rpt_mgr_status}
                                                                    </td>
                                                                    <td style={{ color: item.account_status === 'Pending' ? 'orange' : item.account_status === 'Approved' ? 'green' : item.account_status === 'Rejected' ? 'red' : 'inherit' }}>
                                                                        {item.account_status}
                                                                    </td>
                                                                    <td>{item.created_on}</td>

                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="10">No records found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <nav>
                                        <ul className='pagination' style={{ float: "right" }}>
                                            <li className='page-item'>
                                                <button
                                                    className='page-link'
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}>
                                                    Previous
                                                </button>
                                            </li>
                                            {[...Array(totalPages).keys()].map(pageNumber => (
                                                <li key={pageNumber} className={`page-item ${pageNumber + 1 === currentPage ? "active" : ""}`}>
                                                    <button className='page-link' onClick={() => handlePageChange(pageNumber + 1)}>
                                                        {pageNumber + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className='page-item'>
                                                <button
                                                    className='page-link'
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}>
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className='mb-3'>
                                        Showing {startItem} to {endItem} of {filteredItems.length} entries
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </div>
    );
};

export default MultiCheckboxDropdown;
