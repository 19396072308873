import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Travel_Desk.css';
import useAuth from './useAuth';
import { BASE_URL } from "./Constants.js";

const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const GetHotelInfo = () => {
    const { id } = useParams();
    const [EmpCode, setEmpCode] = useState();
    const [items, setItems] = useState([]); // Cities
    const [rows, setRows] = useState([]); // Rows for employee details
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [formData, setFormData] = useState({
        city: '',
        check_in: '',
        check_out: '',
        travel_date: '',
        hotel_name: '',
        booking_date: '',
        receipt: null,
        amount: '',
        receiptPreview: null, // New state for preview
    });

    useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, type, files, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value,
            receiptPreview: type === 'file' ? URL.createObjectURL(files[0]) : prevState.receiptPreview, // Handle file preview
        }));
    };

    const handleRowChange = (id, e) => {
        const { name, value } = e.target;
        setRows(prevRows =>
            prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
        );
    };

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade: '', mobile: '' }]);
    };

    const handleRemoveRow = async (id) => {
        const rowToRemove = rows.find(row => row.id === id);
        const userConfirmed = window.confirm(`Are you sure you want to delete "${rowToRemove.comp_name}"?`);
        if (!userConfirmed) return;
        if (!rowToRemove) return; // If row not found, exit

        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        try {
            // Make API call to set status inactive
            const response = await fetch(`${BASE_URL}api/setHotelDelete?id=${rowToRemove.id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                // If API call was successful, remove the row
                setRows(prevRows => prevRows.filter(row => row.id !== id));
                toast.success("Employee deleted successfully.");
            } else {
                toast.error(result.message || "Failed to update employee status.");
            }
        } catch (error) {
            console.error('Error setting status inactive:', error);
            toast.error("Failed to set employee status inactive.");
        }
    };

    const fetchItems = async () => {
        try {
            const token = localStorage.getItem("authToken") || null;
            if (!token) return;

            const response = await fetch(`${BASE_URL}api/getHotelInfo?id=${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                // Populate form data and rows with fetched data
                const hotelInfo = result.hotel_info;
                setFormData({
                    city: hotelInfo.city,
                    travel_date: hotelInfo.travel_date,
                    check_in: hotelInfo.check_in,
                    check_out: hotelInfo.check_out,
                    hotel_name: hotelInfo.hotel_name,
                    booking_date: hotelInfo.booking_date,
                    trans_code: hotelInfo.trans_code,
                    receipt: hotelInfo.receipt,
                    amount: hotelInfo.amount,
                    receiptPreview: hotelInfo.receipt, // Set existing receipt preview
                });
                setRows(hotelInfo.related_details.map(detail => ({
                    id: detail.id,
                    emp_code: detail.emp_code,
                    comp_name: detail.comp_name,
                    dept_name: detail.dept_name,
                    designation_name: detail.designation_name,
                    grade: detail.grade,
                    mobile: detail.mobile,
                })));
            } else {
                setError(result.message);
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };

    const fetchCitiesData = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${BASE_URL}api/cities`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setItems(result.data);
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch cities data');
        }
    };

    const fetchEmployeeDetails = async (empCode) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${BASE_URL}api/users?emp_code=${empCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setRows(prevRows => {
                    const updatedRows = [...prevRows];
                    const rowIndex = updatedRows.findIndex(row => row.emp_code === empCode);
                    if (rowIndex !== -1) {
                        updatedRows[rowIndex].comp_name = result.user.comp_name;
                        updatedRows[rowIndex].dept_name = result.user.dept_name;
                        updatedRows[rowIndex].designation_name = result.user.designation_name;
                        updatedRows[rowIndex].grade = result.user.grade;
                        updatedRows[rowIndex].mobile = result.user.mobile;
                    }
                    return updatedRows;
                });
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch employee details');
        }
    };

    useEffect(() => {
        fetchItems();
        fetchCitiesData(); // Ensure this runs on component mount
    }, [id]);

    useEffect(() => {
        if (EmpCode) {
            fetchEmployeeDetails(EmpCode);
        }
    }, [EmpCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        const formDataToSend = new FormData();
        formDataToSend.append('hotel_name', formData.hotel_name);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('check_in', formData.check_in);
        formDataToSend.append('check_out', formData.check_out);
        formDataToSend.append('booking_date', formData.booking_date);
        formDataToSend.append('amount', formData.amount);
        if (formData.receipt) {
            formDataToSend.append('receipt', formData.receipt);
        }

        // Append related details in the desired format
        rows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSend.append(`related_details[${index}][${key}]`, row[key]);
            });
        });
        try {
            const response = await fetch(`${BASE_URL}api/updateHotelInfo?id=${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formDataToSend,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                toast.success("Travel desk updated successfully!");
                navigate(`/manage_hotel_info`); // Redirect or perform any action after success
            } else {
                toast.error(result.message || "Update failed.");
            }
        } catch (error) {
            console.error("Submission error:", error);
            toast.error("Failed to update travel desk.");
        }
    };
    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Hotel Ticket Booking Info ({formData.trans_code})</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/manage_hotel_info">Hotel Details</a></li>
                                        <li className="breadcrumb-item active">Hotel Booking Info</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader /> // Show loader if loading is true
                    ) : (
                        <>
                            <section className="col-lg-12">
                                <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                    <div className="card-body">
                                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="hotel_name">Hotel Name <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="hotel_name"
                                                                value={formData.hotel_name}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="city">City <span style={{ color: 'red' }}>*</span></label>
                                                            <select
                                                                name="city"
                                                                value={formData.city}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="">{formData.city}</option>
                                                                {items.map(city => (
                                                                    <option key={city.id} value={city.city_name}>
                                                                        {city.city_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="check_in">Check In <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="date"
                                                                name="check_in"
                                                                value={formData.check_in}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="check_out">Check Out <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="date"
                                                                name="check_out"
                                                                value={formData.check_out}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                                    <button type="button" onClick={handleAddRow} className="btn btn-primary">+</button>
                                                    <table className="expense-table">
                                                        <thead>
                                                            <tr className="header-row">
                                                                <th>Emp Code<span style={{ color: 'red' }}>*</span></th>
                                                                <th>Name</th>
                                                                <th>Dept</th>
                                                                <th>Designation</th>
                                                                <th>Grade</th>
                                                                <th>Mobile</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {rows.map(row => (
                                                                <tr key={row.id}>
                                                                    <td data-label="Emp Code">
                                                                        <input
                                                                            type="text"
                                                                            name="emp_code"
                                                                            value={row.emp_code}
                                                                            onChange={(e) => {
                                                                                handleRowChange(row.id, e);
                                                                                setEmpCode(e.target.value); // Update EmpCode state to trigger API fetch
                                                                            }}
                                                                            className="form-control input-font"
                                                                            placeholder="Emp Code"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Name">
                                                                        <input
                                                                            type="text"
                                                                            name="comp_name"
                                                                            value={row.comp_name}
                                                                            className="form-control input-font"
                                                                            placeholder="Name"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Dept">
                                                                        <input
                                                                            type="text"
                                                                            name="dept_name"
                                                                            value={row.dept_name}
                                                                            className="form-control input-font"
                                                                            placeholder="Dept"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Designation">
                                                                        <input
                                                                            type="text"
                                                                            name="designation_name"
                                                                            value={row.designation_name}
                                                                            className="form-control input-font"
                                                                            placeholder="Designation"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Grade">
                                                                        <input
                                                                            type="text"
                                                                            name="grade"
                                                                            value={row.grade}
                                                                            className="form-control input-font"
                                                                            placeholder="Grade"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Mobile">
                                                                        <input
                                                                            type="text"
                                                                            name="mobile"
                                                                            value={row.mobile}
                                                                            className="form-control input-font"
                                                                            placeholder="Mobile"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Actions">
                                                                        <button type="button" onClick={() => handleRemoveRow(row.id)} className="btn btn-danger">x</button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="amount">Amount <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="number"
                                                                name="amount"
                                                                value={formData.amount}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="receipt">Receipt <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="file"
                                                                name="receipt"
                                                                onChange={handleChange}
                                                                className="form-control input-font"

                                                            />
                                                            {formData.receiptPreview && (
                                                                <div className="mt-2">
                                                                    <a href={formData.receiptPreview} target="_blank" rel="noopener noreferrer">
                                                                        <img
                                                                            src={formData.receiptPreview}
                                                                            alt="Receipt Preview"
                                                                            style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover', marginTop: '10px' }}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-footer">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </form>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default GetHotelInfo;
