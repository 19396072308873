import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import useAuth from './useAuth';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AddCityClassification = () => {
    const [formData, setFormData] = useState({
        city_name: ''
    });
    const navigate = useNavigate();
    const [error, setError] = useState('');
    useAuth();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [statelistData, setStatelistData] = useState([]);
    const validateFormData = () => {
        const { city_name } = formData;
        if (!city_name) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}states`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setItems(result.data);
                    setStatelistData(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setError("Failed to fetch items");
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateFormData()) return;

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });

        try {
            const token = localStorage.getItem('authToken');

            const response = await fetch(`${API_BASE_URL}addCityType`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSubmit
            });

            const result = await response.json();

            if (result.status) {
                toast.success('City successfully added');
                setFormData({
                    city_name: ''
                });
                navigate('/manage_cities');
            } else {
                toast.error(result.message || 'An error occurred while adding the city classification.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Add City Classification</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Add City Classification</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                <div className="card-header">
                                    <h3 className="card-title">Add City Classification</h3>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="city_name"
                                                            id="name"
                                                            value={formData.city_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="City Name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="city_class">City Class<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="city_class"
                                                            id="city_class"
                                                            value={formData.city_class}
                                                            onChange={handleChange}
                                                            className="form-control custom-select input-font"
                                                            required
                                                        >
                                                            <option value="">Select City Class</option>
                                                            <option value="A">A</option>
                                                            <option value="B">B</option>
                                                            <option value="C">C</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="state_id">State Id<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="state_id"
                                                            id="state_id"
                                                            value={formData.state_id}
                                                            onChange={handleChange}
                                                            className="form-control custom-select input-font"
                                                            required
                                                        >
                                                            <option value="">Select State</option>
                                                            {statelistData && statelistData.length > 0 ? (
                                                                statelistData.map((state) => (
                                                                    <option key={state.id} value={state.id}>
                                                                        {state.name}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <option value="">No states available</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="box-footer">
                                            <button type="submit" className="btn btn-success pull-right">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
                <aside className="control-sidebar control-sidebar-dark"></aside>
            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default AddCityClassification;
