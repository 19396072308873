import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EditPurpose = () => {
    const [formData, setFormData] = useState({
        purpose: ''
    });
    const navigate = useNavigate();
    const { id } = useParams(); // Get the ID from URL params

    // Fetch the existing purpose data on component mount
    useEffect(() => {
        const fetchPurpose = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}get_purposes/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                
                if (result.status) {
                    setFormData({
                        purpose: result.data.purpose // Set the purpose data
                    });
                } else {
                    toast.error(result.message || 'Failed to fetch purpose data.');
                }
            } catch (error) {
                console.error('Fetch error:', error);
                toast.error('Failed to fetch purpose data.');
            }
        };

        fetchPurpose();
    }, [id]); // Run effect only once when the component mounts

    const validateFormData = () => {
        const { purpose } = formData;
        if (!purpose) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validate form data
        if (!validateFormData()) return;
    
        // Create JSON data from formData
        const jsonData = JSON.stringify(formData);
    
        try {
            const token = localStorage.getItem('authToken');
   
            const response = await fetch(`${API_BASE_URL}update/${id}`, {
                method: 'PUT', // Use PUT for updating
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: jsonData
            });
    
            const result = await response.json();
    
            if (result.status) {
                toast.success('Purpose successfully updated');
                navigate('/purposes');
            } else {
                toast.error(result.message || 'An error occurred while updating the purpose.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">
                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Edit Purpose</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Edit Purpose</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                <div className="card-header">
                                    <h3 className="card-title">Edit Purpose</h3>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="purpose">Purpose<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="purpose"
                                                            id="purpose"
                                                            value={formData.purpose}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Purpose Name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="box-footer">
                                            <button type="submit" className="btn btn-success pull-right">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
                <aside className="control-sidebar control-sidebar-dark"></aside>
            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default EditPurpose;
