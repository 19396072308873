import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './Travel_Desk.css';
import './Loader.css';
import useAuth from './useAuth.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div className="loader-container1">
      <div className="spinner1"></div>
      <p>Loading...</p>
    </div>
  );

const Edit_Audit_Manager_Request = () => {
    const { id } = useParams();  // Get the expense id from the URL parameter
    const [AdvanceStatus, setAdvanceStatus] = useState([]);
    const [hasSubmittedStaticData, setHasSubmittedStaticData] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [errors, setErrors] = useState({
        audit_status: '', // Initial errors can go here (empty or predefined)
    });
    const [EmpCode, setEmpCode] = useState('100076'); // Default Emp Code
    const [monthlyExpenses, setMonthlyExpenses] = useState([]); // Store monthly expenses data
    const [formData, setFormData] = useState({
        comp_name: '',
        areas_visited: '',
        from_city: '',
        to_city: '',
        travel_date: '',
        return_date: '',
        receipt: null,
        amount: '',
        email: '',
        branch: '',
        rpt_mgr_status: '',
    });

    const [dates, setDates] = useState([]);
    const [relatedDetails, setRelatedDetails] = useState([]);
    const [isApproved, setIsApproved] = useState(false);
    const [ReportManagerName, setReportManagerName] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        // Fetch expense data based on id
        const fetchExpence = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}manage_expences?id=${id}`, {  // Fetch expense data for the specific id
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    const Expence_Request = result.expence_request;
                    const audit_status = result.expence_request.audit_status || '';
                    setReportManagerName(result.reportManagerName.comp_name || '');
                    setFormData({
                        comp_name: Expence_Request.comp_name,
                        emp_code: Expence_Request.emp_code,
                        designation_name: Expence_Request.designation_name,
                        branch: Expence_Request.branch,
                        report_mngr: Expence_Request.report_mngr,
                        email: Expence_Request.email,
                        mobile: Expence_Request.mobile,
                        rpt_mgr_status: Expence_Request.rpt_mgr_status,
                        audit_status: Expence_Request.audit_status || '',
                        audit_remarks: Expence_Request.audit_remarks,
                        manager_remarks: Expence_Request.manager_remarks,
                        total_no_of_calls: Expence_Request.total_no_of_calls,
                        total_amount: Expence_Request.total_amount,
                        created_on: Expence_Request.created_on,
                        manager_approved_date: Expence_Request.manager_approved_date,


                    });
                    setIsApproved(audit_status === 'Approved');

                    setRelatedDetails(result.expence_request.related_details);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchExpence();
    }, [id]); // Run effect when `id` changes

    const handleChange = (e, index) => {
        const { name, value } = e.target;

       
        // If the name is 'rpt_mgr_status', update only the rpt_mgr_status in the formData
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setRelatedDetails(prevDetails => {
            const updatedDetails = [...prevDetails];  // Create a shallow copy of the details array
            updatedDetails[index] = {
                ...updatedDetails[index],  // Copy the current item at the specified index
                [name]: value,  // Update the specific field (amount in this case)
            };
    
            return updatedDetails;  // Return the updated array
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = true;
        let newErrors = {};

        // If Reporting Manager Status is Pending, show error
        if (formData.audit_status === 'Pending') {
            formIsValid = false;
            newErrors.audit_status = 'Please approve the status before submitting.';
        }

        if (!formIsValid) {
            setErrors(newErrors); // Update errors state if form is invalid
            return;
        }
        setIsSubmitting(true);

        // Create a FormData object to send the data
        const formDataToSend = new FormData();
        formDataToSend.append('audit_status', formData.audit_status);
        formDataToSend.append('audit_remarks', formData.audit_remarks);

        relatedDetails.forEach((expense, index) => {
            formDataToSend.append(`expenses[${index}][id]`, expense.id);
            formDataToSend.append(`expenses[${index}][monthly_id]`, expense.monthly_id);
            formDataToSend.append(`expenses[${index}][emp_code]`, expense.emp_code);
            formDataToSend.append(`expenses[${index}][month]`, expense.month);
            formDataToSend.append(`expenses[${index}][date]`, expense.date);
            formDataToSend.append(`expenses[${index}][areas_visited]`, expense.areas_visited);
            formDataToSend.append(`expenses[${index}][no_of_calls]`, expense.no_of_calls);
            formDataToSend.append(`expenses[${index}][amount]`, expense.amount);
            formDataToSend.append(`expenses[${index}][remarks]`, expense.remarks);
            formDataToSend.append(`expenses[${index}][created_by]`, expense.created_by);
            formDataToSend.append(`expenses[${index}][created_on]`, expense.created_on);
        });

        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}updateAudit/${id}`, {
                method: 'POST', // Or 'PUT' if you're updating
                headers: {
                    'Authorization': `Bearer ${token}`,
                    // Don't set 'Content-Type' header because FormData will handle it automatically
                },
                body: formDataToSend,  // Send the formDataToSend here
            });

            const result = await response.json();
            if (result.status) {
                toast.success('Expenses updated successfully!');
                navigate('/audit_expence_approval');  // Redirect after successful update
            } else {
                toast.error('Failed to update expenses');
            }
        } catch (error) {
            console.error('Submit error:', error);
            toast.error('Error updating expenses');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Reporting Manager Monthly Expence Approval</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Reporting Manager Monthly Expence Approval</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} encType="multipart/form-data" method="POST">
                                    <div className="box-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Name <span style={{ color: 'red', fontSize: '11px' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="comp_name"
                                                        id="name"
                                                        value={formData.comp_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Name"
                                                        readonly="true"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                        <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="emp_code"
                                                        id="name"
                                                        value={formData.emp_code}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Employee No/Consultant"
                                                        readonly="true"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="designation_name"
                                                        id="name"
                                                        value={formData.designation_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Department&Designation"
                                                        readonly="true"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }}>Reporting Manager<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="report_mngr"
                                                        value={formData.report_mngr}
                                                        className="form-control input-font"
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Employee Email  <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="grade"
                                                        id="name"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Employee Email"
                                                        readonly="true"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }}>Branch<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="branch"
                                                        value={formData.branch}
                                                        className="form-control input-font"
                                                        readonly="true"
                                                        disabled

                                                    />
                                                </div>
                                            </div>



                                        </div>

                                        <div className='row'><div className="col-md-4">
                                            <div className="form-group">
                                                <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                    <span style={{ color: 'red' }}>*</span></label>
                                                <input

                                                    type="text"
                                                    name="mobile"
                                                    id="name"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    className="form-control input-font"
                                                    placeholder="Days of travel"
                                                    readonly="true"
                                                />
                                            </div>
                                        </div></div>

                                        {/* New Dynamic Row */}
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-12">
                                                <div className="table-container custom-table-container" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                    <div className="table-responsive" style={{ overflowX: 'auto' }}>
                                                        <table className="table table-hover table-mc-light-blue table-bordered table-striped table-hover table-sticky">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Date</th>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Areas Visited</th>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>No of Calls</th>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Amount</th>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Remarks</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {relatedDetails.map((detail, index) => (
                                                                    <tr key={detail.id}>
                                                                        <td>
                                                                            <input
                                                                                type="date"
                                                                                name="date"
                                                                                value={detail.date}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                disabled
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="text"
                                                                                name="areas_visited"
                                                                                value={detail.areas_visited}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                disabled
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="no_of_calls"
                                                                                value={detail.no_of_calls}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                disabled
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="amount"
                                                                                value={detail.amount}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <textarea
                                                                                name="remarks"
                                                                                value={detail.remarks}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                rows="1"
                                                                                disabled
                                                                            ></textarea>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot className="tfoot-light">
                                                                <tr>
                                                                    <td colSpan="2">
                                                                        <strong>Total</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>
                                                                            {formData.total_no_of_calls}
                                                                        </strong>
                                                                    </td>
                                                                    <td>
                                                                    <strong>
                                                                                {/* Calculate total amount */}
                                                                                {relatedDetails.reduce((total, expense) => {
                                                                                    return total + (Number(expense.amount) || 0);
                                                                                }, 0).toFixed(2)} {/* Format to 2 decimal places */}
                                                                            </strong>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">Reporting Manager Status
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select
                                                        name="rpt_mgr_status"
                                                        id="rpt_mgr_status"
                                                        value={formData.rpt_mgr_status || ''}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                        disabled
                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="remarks">Reporting Manager Remarks
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>

                                                    <textarea name="manager_remarks"
                                                        required
                                                        id="remarks"
                                                        value={formData.manager_remarks || ''}
                                                        onChange={handleChange}
                                                        className="form-control "
                                                        disabled
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="audit_status">Audit Manager Status
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select
                                                        name="audit_status"
                                                        id="audit_status"
                                                        value={formData.audit_status || ''}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required

                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                    {errors.audit_status && (
                                                                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                                                        {errors.audit_status}
                                                                    </div>
                                                                )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="audit_remarks">Audit Manager Remarks
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>

                                                    <textarea name="audit_remarks"
                                                        required
                                                        id="remarks"
                                                        value={formData.audit_remarks || ''}
                                                        onChange={handleChange}
                                                        className="form-control "

                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 row" style={{ width: '100%' }}>
                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                                <b>Prepared by </b>
                                                <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                    : {formData.created_on}
                                                </p>
                                            </div>
                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                                {formData.rpt_mgr_status === 'Approved' && (
                                                    <>
                                                        <b> Approved by</b>
                                                        {/* <p> {formData.report_mngr} ({ReportManageName})<br /> Date
                                                                    : {formData.manager_approved_date}
                                                                </p> */}
                                                        <p> {formData.report_mngr} ({ReportManagerName})<br /> Date
                                                            : {formData.manager_approved_date}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                            {formData.account_status === 'Approved' && (
                                                <>
                                                    <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">

                                                        <b> Verified by Audit Team</b>
                                                        <p>                         <span>
                                                            {formData.audit_manager}    <br /> Date
                                                            : {formData.audit_approved_date}
                                                        </span>
                                                        </p>

                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                    <div className="box-footer" style={{ textAlign: 'center' }}>
                                        <button type="submit" className="btn btn-success pull-right" disabled={isApproved}>
                                            {isSubmitting ? 'Submitting...' : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>)}
                </div>
                <Footer />
                <ToastContainer />
            </div>
        </div>
    );
};

export default Edit_Audit_Manager_Request;
