import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from './useAuth';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import default CSS for Toastify
import "./CustomToastStyles.css"; // Import custom styles
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
//import { BASE_URL } from "./Constants.js";
import MD5 from "crypto-js/md5";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//import "./ChangePassword.css";

const ChangePassword = () => {
	const [formData, setFormData] = useState({
		current_password: "",
		new_password: "",
		confirm_password: "",
	});
	useAuth();
	const [responseMessage, setResponseMessage] = useState('');
	const navigate = useNavigate();
	const [Password, setPassword] = useState(false);
	const validateFormData = () => {

		const { current_password, new_password, confirm_password } = formData;
		if (!current_password || !new_password || !confirm_password) {
			toast.error("All fields are required");
			return false;
		}
		
		if (MD5(current_password).toString() != Password) {
			toast.error("Current Password and old password not matching");
			return false;
		}
		if (new_password.length < 8 || confirm_password.length < 8) {
			toast.error("Passwords must be at least 8 characters long");
			return false;
		}
		if (new_password.length !== confirm_password.length) {
			toast.error("New password and current password must be the same length");
			return false;
		}
		if (new_password !== confirm_password) {
			toast.error("New password and confirm password do not match");
			return false;
		}
		if (/vdc/i.test(new_password) || /vijaya/i.test(new_password)) {
			toast.error('Password should not contain "vdc" or "vijaya"');
			return false;
		}
		// Regular Expression for Password validation:
		// Must contain at least one uppercase letter, one lowercase letter, and one special character
		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

		if (!passwordRegex.test(new_password)) {
			toast.error("Password must contain at least one uppercase letter, one lowercase letter, and one special character");
			return false;
		}
		return true;
	};

	useEffect(() => {
		const fetchProfileData = async () => {
			try {
				const token = localStorage.getItem('authToken');
				const response = await fetch(`${API_BASE_URL}profile`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json'
					}
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const result = await response.json();

				if (result.status) {
					setFormData({
						emp_code: result.profile.emp_code || '',
						mail_id: result.profile.mail_id || '',
						password: '' // Do not display the password
					});
					setPassword(result.profile.password);


				} else {
					setResponseMessage('Error: ' + result.message);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				setResponseMessage('Error: ' + error.message);
			}
		};

		fetchProfileData(); // Call the function to fetch profile data
	}, []); // Ensure this runs once on component mount

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleLogout = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}logout`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const result = await response.json();
            if (result.status) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('exp');
                navigate('/');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!validateFormData()) return;

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			if (formData[key] !== null && formData[key] !== "") {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		try {
			const token = localStorage.getItem("authToken");
			const response = await fetch(`${API_BASE_URL}changePassword`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					// No 'Content-Type' header for FormData
				},
				body: formDataToSubmit,
			});

			const result = await response.json();

			if (result.status) {
				toast.success("Password successfully changed");
				setFormData({
					current_password: "",
					new_password: "",
					confirm_password: "",
				});
				
				handleLogout();
			} else {
				toast.error(result.messages.error || "An error occurred while changing the password.");
			}
		} catch (error) {
			console.error("Fetch error:", error);
			toast.error("Failed to submit form");
		}
	};

	return (
		<div className='hold-transition sidebar-mini layout-fixed' style={{ fontSize: '12px' }}>
			<div className='wrapper'>
				<Header />
				<Sidebar />

				<div className='content-wrapper'>
					<div className='content-header'>
						<div className='container-fluid'>
							<div className='row mb-2'>
								<div className='col-sm-6'>
									<h1 className='m-0'  style={{ fontSize: '20px' }}>Change Password</h1>
								</div>
								<div className='col-sm-6'>
									<ol className='breadcrumb float-sm-right'>
										<li className='breadcrumb-item'>
											<a href='#'>Home</a>
										</li>
										<li className='breadcrumb-item active'>Change Password</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<section className='col-lg-12'>
						<div className='card' style={{ borderTop: "3px solid #00c0ef" }}>
							<div className='box box-info'>
								<div className='card-header'>
									<h3 className='card-title'>Change Password</h3>
								</div>

								<div className='card-body'>
									<form onSubmit={handleSubmit} method='post' acceptCharset='utf-8'>
										<div className='box-body'>
											<div className='row'>
												<div className='col-md-12'>
													<div className='form-group'>

														<input
															type='hidden'
															name='old_password'
															id='old_password'
															value={Password}
															onChange={handleChange}
															className='form-control'
															placeholder='Old Password'
														/>
													</div>
													<div className='form-group'>
														<label htmlFor='current_password'>
															Current Password<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='password'
															name='current_password'
															id='current_password'
															value={formData.current_password}
															onChange={handleChange}
															className='form-control'
															placeholder='Current Password'
														/>
													</div>
													<div className='form-group'>
														<label htmlFor='new_password'>
															New Password<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='password'
															name='new_password'
															id='new_password'
															value={formData.new_password}
															onChange={handleChange}
															className='form-control'
															placeholder='New Password'
														/>
													</div>
													<div className='form-group'>
														<label htmlFor='confirm_password'>
															Confirm Password<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='password'
															name='confirm_password'
															id='confirm_password'
															value={formData.confirm_password}
															onChange={handleChange}
															className='form-control'
															placeholder='Confirm Password'
														/>
													</div>
												</div>
											</div>
										</div>

										<div className='box-footer'>
											<button type='submit' className='btn btn-success pull-right'>
												Submit
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
				</div>

				<Footer />

				<aside className='control-sidebar control-sidebar-dark'></aside>
			</div>

			{/* Toast Container */}
			<ToastContainer />
		</div>
	);
};

export default ChangePassword;
