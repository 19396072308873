import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
import { BASE_URL } from "./Constants.js";
import useAuth from './useAuth';
import * as XLSX from "xlsx";
import "./Manage_User.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Loader = () => (
	<div style={{ textAlign: 'center', marginTop: '20px' }}>
		<div className="spinner-border" role="status">
			<span className="sr-only">Loading...</span>
		</div>
		<p>Loading...</p>
	</div>
);

const HotelInfoReports = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCities, setSelectedCities] = useState([]);
    const [cities, setCities] = useState([]); // For dropdown filter
    const [allCities, setAllCities] = useState([]); // Keep original list of all cities
    const [searchQuery1, setSearchQuery1] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState("id");
	const [sortOrder, setSortOrder] = useState("desc");
    const [startDate, setStartDate] = useState(null);  // For date picker
    const [endDate, setEndDate] = useState(null);      // For date picker
	const navigate = useNavigate();
	useAuth();
	const token = localStorage.getItem("authToken") || null;

	useEffect(() => {
		const fetchItems = async () => {
			try {
				if (!token) return;

				const response = await fetch(`${BASE_URL}api/getHotelInfo`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const result = await response.json();
				if (result.status) {
					setItems(result.hotel_info); // Assuming this is an array of objects
				} else {
					setError(result.message);
				}
			} catch (error) {
				console.error("Fetch error:", error);
				setError("Failed to fetch items");
			} finally {
				setLoading(false);
			}
		};

		fetchItems();
	}, [navigate]);

    // Fetch cities data
    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const response = await fetch(`${BASE_URL}api/cities`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setCities(result.data); // Set filtered cities for dropdown
                    setAllCities(result.data); // Keep the full list of cities
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch cities data');
            }
        };
        fetchCitiesData();
    }, [token]);

	useEffect(() => {
		if (!Array.isArray(items)) {
			setFilteredItems([]);
			return;
		}

		const lowerCaseQuery = searchQuery.toLowerCase();
    
        const filtered = items.filter(item => {
            // Safeguard: Ensure `item` properties are valid strings before calling `.toLowerCase()`
            const fromCityName = item.city ? item.city.toLowerCase().trim() : '';
			const checkIn = item.check_in ? item.check_in.toLowerCase().trim() : '';
			const checkOut = item.check_out ? item.check_out.toLowerCase().trim() : '';
			const bookingDate = item.booking_date ? item.booking_date.toLowerCase().trim() : '';
			const hotelName = item.hotel_name ? item.hotel_name.toLowerCase().trim() : '';
            const toCityName = item.to_city ? item.to_city.toLowerCase().trim() : '';
			const CreatedOn = item.created_on ? item.created_on.toLowerCase().trim() : '';
            const empCode = item.related_details[0].emp_code ? item.related_details[0].emp_code.toLowerCase().trim() : '';
    
            // Safeguard: Ensure values are strings before calling `.toLowerCase()`
            const compName = item.comp_name ? item.comp_name.toLowerCase() : '';
            const transCode = item.trans_code ? item.trans_code.toLowerCase() : '';
            const accountsApprovedAmount = item.accounts_approved_amount ? item.accounts_approved_amount.toLowerCase() : '';
            const managerApprovedAmount = item.manager_approved_amount ? item.manager_approved_amount.toLowerCase() : '';
    
            // Perform the filtering logic
            const matchesSearch = compName.includes(lowerCaseQuery) ||
			CreatedOn.includes(lowerCaseQuery) ||
			bookingDate.includes(lowerCaseQuery) ||
			hotelName.includes(lowerCaseQuery) ||
			checkIn.includes(lowerCaseQuery) || 
			checkOut.includes(lowerCaseQuery) || 
            empCode.includes(lowerCaseQuery) ||
                transCode.includes(lowerCaseQuery) ||
                fromCityName.includes(lowerCaseQuery) ||
                toCityName.includes(lowerCaseQuery) ||
                accountsApprovedAmount.includes(lowerCaseQuery) ||
                managerApprovedAmount.includes(lowerCaseQuery);
    
            // Match against selected cities
            const matchesCity = selectedCities.length === 0 || 
                selectedCities.some(city => city.toLowerCase().trim() === fromCityName || city.toLowerCase().trim() === toCityName);

            // Date range matching logic
            const matchesDateRange = (
                (!startDate || new Date(item.created_on).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0)) &&
                (!endDate || new Date(item.created_on).setHours(23, 59, 59, 999) <= new Date(endDate).setHours(23, 59, 59, 999))
            );

            return matchesSearch && matchesCity && matchesDateRange;
        });
    
        // Sort the filtered items
        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });
    
        setFilteredItems(sorted);
        setCurrentPage(1);  // Reset to first page when filter changes
    }, [searchQuery, selectedCities, items, startDate, endDate, sortColumn, sortOrder]);

	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const startItem = indexOfFirstItem + 1;
	const endItem = Math.min(indexOfLastItem, filteredItems.length);

	const handleSearchChange = e => {
		setSearchQuery(e.target.value);
	};

    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };

	const handlePageChange = pageNumber => {
		setCurrentPage(pageNumber);
	};

	const handleSearchChange1 = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query);

        // Filter cities based on the search query in the dropdown
        const filtered = allCities.filter(city => city.city_name.toLowerCase().includes(query));
        setCities(filtered);
    };

	const handleItemsPerPageChange = e => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handleSort = (column) => {
		if (column === "id") {
			if (sortColumn === column) {
				setSortOrder(sortOrder === "asc" ? "desc" : "asc");
			} else {
				setSortColumn(column);
				setSortOrder("asc");
			}
		}
	};

    // Handle checkbox changes for cities
    const handleCheckboxChange = (cityName) => {
        setSelectedCities(prevSelectedCities => {
            if (prevSelectedCities.includes(cityName)) {
                return prevSelectedCities.filter(city => city !== cityName);  // Remove if already selected
            } else {
                return [...prevSelectedCities, cityName];  // Add if not selected
            }
        });
    };

	const exportToExcel = () => {
		const filteredStateNames = filteredItems.map(item => ({
			CheckIn: item.check_in,
			checkOut: item.check_out,
			HotelName: item.hotel_name,
			booking_date: item.booking_date,
			emp_code: item.related_details[0].emp_code,
			date: item.created_on
		}));
		const worksheet = XLSX.utils.json_to_sheet(filteredStateNames);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "TravelDesk");
		XLSX.writeFile(workbook, "HotelInfo.xlsx");
	};

	return (
		<>
			<div className='hold-transition sidebar-mini layout-fixed' style={{ fontSize: '12px' }}>
				<div className='wrapper'>
					<Header />
					<Sidebar />
					<div className='content-wrapper'>
                    <div className='content-header'>
							<div className='container-fluid'>
								<div className='row mb-2'>
									<div className='col-sm-6'>
										<h1 className='m-0' style={{ fontSize: '20px' }}>Hotel Info Reports</h1>
									</div>
									<div className='col-sm-6'>
										<ol className='breadcrumb float-sm-right'>
											<li className='breadcrumb-item' style={{ fontSize: '12px' }}>
												<a href='/dashboard'>Home</a>
											</li>

											<li className='breadcrumb-item active' style={{ fontSize: '12px' }}>Hotel Info Reports</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
						<section className='content'>
							<div className='container-fluid'>
								<div className='row'>
									<section className='col-lg-12 connectedSortable'>
										<div className='card'>
                                        <div className='card-header'>
												
												<span style={{ float: "right" }}>
													
													<button
														type='button'
														className='btn btn-secondary ml-2'
														onClick={exportToExcel}>
														Export to Excel
													</button>
												</span>
											</div>
											<div className='card-body'>
												{loading ? (
													<Loader /> // Show loader if loading is true
												) : (
													<>
														{/* Cities Dropdown */}
                                                        <div className="row">
														<div className="col-md-2">
                                                                <div className="form-group">
                                                                    <select
                                                                        className="form-control custom-select-sm"
                                                                        value={itemsPerPage}
                                                                        onChange={handleItemsPerPageChange}
                                                                    >
                                                                        <option value={10}>10</option>
                                                                        <option value={50}>50</option>
                                                                        <option value={100}>100</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                       
                                                            <div className="col-md-2">
                                                    <div className="form-group">
                                                       
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            selectsStart
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
															placeholderText="Select Start Date"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                       
                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={(date) => setEndDate(date)}
                                                            selectsEnd
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            minDate={startDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
															placeholderText="Select End Date"
                                                        />
                                                    </div>
                                                </div>
                                                            {/* Search Input */}
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="dropdown">
                                                                        <button
                                                                            className="btn btn-secondary dropdown-toggle form-control"
                                                                            type="button"
                                                                            id="dropdownMenuButton"
                                                                            aria-expanded="false"
                                                                            onClick={toggleDropdown}
                                                                        >
                                                                            {selectedCities.length === 0
                                                                                ? 'Select Cities'
                                                                                : `${selectedCities.length} Cities Selected`}
                                                                        </button>

                                                                        {/* Dropdown Menu */}
                                                                        {isOpen && (
                                                                            <div
                                                                                className="dropdown-menu show"
                                                                                style={{
                                                                                    maxHeight: '300px', // Max height to show only 10 items
                                                                                    overflowY: 'auto', // Enables scroll if items exceed 10
                                                                                    width: '100%',
                                                                                }}
                                                                                aria-labelledby="dropdownMenuButton"
                                                                            >
                                                                                {/* Search Cities Input */}
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Search Cities"
                                                                                    value={searchQuery1}
                                                                                    onChange={handleSearchChange1}
                                                                                    style={{ marginBottom: '10px' }}
                                                                                />

                                                                                {/* Cities List */}
                                                                                {cities.length > 0 ? (
                                                                                    cities.map((city) => (
                                                                                        <div key={city.id} className="form-check">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id={`city-${city.id}`}
                                                                                                value={city.city_name}
                                                                                                checked={selectedCities.includes(city.city_name)}
                                                                                                onChange={() => handleCheckboxChange(city.city_name)}
                                                                                            />
                                                                                            <label className="form-check-label" htmlFor={`city-${city.id}`}>
                                                                                                {city.city_name}
                                                                                            </label>
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div>Loading cities...</div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
															<div className="col-md-2">
                                                                <div className="form-group">
                                                                    <div id="example1_filter" className="dataTables_filter">
                                                                        <label>
                                                                            <input
                                                                                type="search"
                                                                                value={searchQuery}
                                                                                onChange={handleSearchChange}
                                                                                className="form-control form-control-sm"
                                                                                placeholder="Search"
                                                                                aria-controls="example1"
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
														<div className="table-responsive">
														<table className='table table-bordered table-striped' style={{ fontSize: '13px' }}>
															<thead>
																<tr>
																	<th onClick={() => handleSort("id")}>
																		ID {sortColumn === "id" && (sortOrder === "asc" ? "▲" : "▼")}
																	</th>
																	<th>Check-In</th>
																	<th>Check-Out</th>
																	<th>Hotel Name</th>
																	<th>Booking Date</th>
																	<th>City</th>
																	<th>Emp Code</th>
																	<th>Date</th>
																</tr>
															</thead>
															<tbody>
																{currentItems.length > 0 ? (
																	currentItems.map((item, index) => (
																		<tr key={index}>
																			<td>
																				{index + 1 + (currentPage - 1) * itemsPerPage}
																			</td>

																			<td>{item.check_in}</td>
																			<td>{item.check_out}</td>
																			<td>{item.hotel_name.toUpperCase()}</td>
																			<td>{item.booking_date}</td>
																			<td>{item.city}</td>
																			<td>
																				{item.related_details && item.related_details.length > 0
																					? item.related_details[0].emp_code
																					: "N/A"}
																			</td>
																			<td>{item.created_on}</td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td colSpan='8'><center>No data available</center></td>
																	</tr>
																)}
															</tbody>
														</table>
														</div>
													</>
												)}
											</div>

										</div>
                                        <nav>
											<ul
												className='pagination'
												style={{ float: "right" }}>
												<li className='page-item'>
													<button
														className='page-link'
														onClick={() => handlePageChange(currentPage - 1)}
														disabled={currentPage === 1}>
														Previous
													</button>
												</li>
												{[...Array(totalPages).keys()].map(pageNumber => (
													<li
														key={pageNumber}
														className={`page-item ${pageNumber + 1 === currentPage ? "active" : ""
															}`}>
														<button
															className='page-link'
															onClick={() => handlePageChange(pageNumber + 1)}>
															{pageNumber + 1}
														</button>
													</li>
												))}
												<li className='page-item'>
													<button
														className='page-link'
														onClick={() => handlePageChange(currentPage + 1)}
														disabled={currentPage === totalPages}>
														Next
													</button>
												</li>
											</ul>
										</nav>
										<div className='mb-3'>
											Showing {startItem} to {endItem} of {filteredItems.length}{" "}
											entries
										</div>
									</section>
								</div>
							</div>
						</section>
					</div>

					<Footer />
				</div>
			</div>
		</>
	);
};

export default HotelInfoReports;
