import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CustomToastStyles.css";
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
import { token } from "./Constants.js";
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const EditEmployee = () => {
	const { id } = useParams();
    const [EmpCode, setEmpCode] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
	const [formData, setFormData] = useState({
		name: "",
		status: "",
		id: id, // Use the id from params
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const validateFormData = () => {
		
		return true;
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value, // Adjust to set value correctly
		}));
	};

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1",token);
                const response = await fetch(`${API_BASE_URL}users?emp_code=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("Profile data1:", result);

                if (result.status) {
                    setEmpCode(result.user.emp_code || '');
                    const report_mngr = result.user.report_mngr || ''; 
                    setFormData({
                        comp_name: result.user.comp_name || '',
                        emp_code: result.user.emp_code || '',
                        dept_name: result.user.dept_name || '',
                        designation_name: result.user.designation_name || '',
                        grade: result.user.grade || '',
                        is_manager_approval	: result.user.is_manager_approval	 || '',
                        is_audit_approval	: result.user.is_audit_approval	 || '',
                        is_finance_approval	: result.user.is_finance_approval	 || '',
						is_traveldesk	: result.user.is_traveldesk	 || '',
						is_travelmanager_approved : result.user.is_travelmanager_approved || '',
						is_hotelmanager_approved : result.user.is_hotelmanager_approved || '',
						is_hotelinfo	: result.user.is_hotelinfo	 || '',
                        travel_advance: result.user.travel_advance || '',
                        travel_advance_amount: result.user.travel_advance_amount || '',
                        bank_details: result.user.bank_details || '',
                        days: result.user.days || '',
                        city: result.user.city || '',
                        purpose: result.user.purpose || '',
                        mode_of_travel: result.user.mode_of_travel || '',
                        mobile: result.user.mobile || '',
                        report_mngr:    report_mngr,
                        password: '', // Do not display the password
                        hotel_tickets: result.user.hotel_tickets || '',
                        travel_tickets: result.user.travel_tickets || '',
                       // to_city: result.profile.to_city || '',
                    });
                  
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };
      
        fetchProfileData();
    }, []);

	const handleSubmit = async e => {
		e.preventDefault();
		if (!validateFormData()) return; // Ensure validation

		const formDataToSubmit = new FormData();
		formDataToSubmit.append('is_manager_approval', formData.is_manager_approval);
    formDataToSubmit.append('is_finance_approval', formData.is_finance_approval);
	formDataToSubmit.append('is_traveldesk', formData.is_traveldesk);
	formDataToSubmit.append('is_hotelinfo', formData.is_hotelinfo);
    formDataToSubmit.append('is_audit_approval', formData.is_audit_approval);
	formDataToSubmit.append('is_travelmanager_approved', formData.is_travelmanager_approved);
	formDataToSubmit.append('is_hotelmanager_approved', formData.is_hotelmanager_approved);

		try {
            const token = localStorage.getItem('authToken');
			const response = await fetch(`${API_BASE_URL}editEmployee/${EmpCode}`, {
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
                    is_manager_approval: formData.is_manager_approval,
                    is_audit_approval: formData.is_audit_approval,
					is_traveldesk: formData.is_traveldesk,
					is_travelmanager_approved: formData.is_travelmanager_approved,
					is_hotelmanager_approved: formData.is_hotelmanager_approved,
					is_hotelinfo: formData.is_hotelinfo,
                    is_finance_approval: formData.is_finance_approval,
                }),
			});

			const result = await response.json();
			if (result.status) {
				toast.success("State successfully updated");
				navigate("/manage_employee");
			} else {
				toast.error(
					result.message || "An error occurred while updating the state.",
				);
			}
		} catch (error) {
			console.error("Fetch error:", error);
			toast.error("Failed to submit form");
		}
	};

	useEffect(() => {
		const fetchState = async () => {
			try {
				const formData = new URLSearchParams();
				formData.append("id", id);
				const response = await fetch(`${API_BASE_URL}getStateDetails`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: formData.toString(),
				});
				const result = await response.json();
				if (result.state) {
					setFormData({
						name: result.state.name,
						status: result.state.status,
						id: id,
					});
				} else {
					setError("No state data found.");
				}
			} catch (error) {
				console.error("Fetch error:", error);
				setError("Failed to get State details");
			}
			setLoading(false);
		};
		fetchState();
	}, [id]);

	return (
		<div className='hold-transition sidebar-mini layout-fixed'>
			<div className='wrapper'>
				<Header />
				<Sidebar />
				<div className='content-wrapper'>
					<div className='content-header'>
						<div className='container-fluid'>
							<div className='row mb-2'>
								<div className='col-sm-6'>
									<h1 className='m-0'>Edit Employee</h1>
								</div>
								<div className='col-sm-6'>
									<ol className='breadcrumb float-sm-right'>
										<li className='breadcrumb-item'>
											<a href='#'>Home</a>
										</li>
										<li className='breadcrumb-item active'>Edit Employee</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<section className='col-lg-12'>
						<div
							className='card'
							style={{ borderTop: "3px solid #00c0ef" }}>
							<div className='box box-info'>
								<div className='card-header'>
									<h3 className='card-title'>Edit Employee</h3>
								</div>
								{loading ? (
                                    <Loader /> // Show loader if loading is true
                                ) : (
                                    <>
								<div className='card-body'>
									<form onSubmit={handleSubmit}>
										<div className='box-body'>
											<div className='row'>
												<div className='col-md-4'>
													<div className='form-group'>
														<label htmlFor='name' style={{ fontSize: '12px' }}>
                                                        Employee Name<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='text'
															name='name'
															value={formData.comp_name}
															onChange={handleChange}
															className='form-control  input-font'
															disabled
														/>
													</div>
												</div>

												<div className='col-md-4'>
													<div className='form-group'>
														<label htmlFor='name' style={{ fontSize: '12px' }}>
                                                        Employee Code<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='text'
															name='name'
															value={formData.emp_code}
															onChange={handleChange}
															className='form-control  input-font'
															disabled
														/>
													</div>
												</div>

                                                <div className='col-md-4'>
													<div className='form-group'>
														<label htmlFor='name' style={{ fontSize: '12px' }}>
                                                        Employee Department<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='text'
															name='name'
															value={formData.dept_name}
															onChange={handleChange}
															className='form-control  input-font'
															disabled
														/>
													</div>
												</div>
											</div>
                                            <div className='row'>
												<div className='col-md-4'>
													<div className='form-group'>
														<label htmlFor='name' style={{ fontSize: '12px' }}>
                                                        Employee Designation<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='text'
															name='name'
															value={formData.designation_name}
															onChange={handleChange}
															className='form-control  input-font'
															disabled
														/>
													</div>
												</div>

												<div className='col-md-4'>
													<div className='form-group'>
														<label htmlFor='name' style={{ fontSize: '12px' }}>
                                                        Employee Grade<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='text'
															name='name'
															value={formData.grade}
															onChange={handleChange}
															className='form-control  input-font'
															disabled
														/>
													</div>
												</div>

                                                <div className='col-md-4'>
													<div className='form-group'>
														<label htmlFor='name' style={{ fontSize: '12px' }}>
                                                        Employee Mobile<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='text'
															name='name'
															value={formData.mobile}
															onChange={handleChange}
															className='form-control  input-font'
															disabled
														/>
													</div>
												</div>
											</div>
                                            <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="mode_of_travel">is Manager Approved
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select required
                                                        name="is_manager_approval"
                                                        id="is_manager_approval"
                                                        value={formData.is_manager_approval}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value='Y'>Yes</option>
                                                        <option value='N'>No</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="mode_of_travel">is Audit Approved
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select required
                                                        name="is_audit_approval"
                                                        id="is_audit_approval"
                                                        value={formData.is_audit_approval}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value='Y'>Yes</option>
                                                        <option value='N'>No</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="mode_of_travel">is Finance Approved
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select required
                                                        name="is_finance_approval"
                                                        id="is_finance_approval"
                                                        value={formData.is_finance_approval}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value='Y'>Yes</option>
                                                        <option value='N'>No</option>

                                                    </select>
                                                </div>
                                            </div>
                                            </div>
											<div className="row">
											<div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="is_traveldesk">Is Travel Desk
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select required
                                                        name="is_traveldesk"
                                                        id="is_traveldesk"
                                                        value={formData.is_traveldesk}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value='Y'>Yes</option>
                                                        <option value='N'>No</option>

                                                    </select>
                                                </div>
                                            </div>
											<div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="is_hotelinfo">Is Hotel Info
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select required
                                                        name="is_hotelinfo"
                                                        id="is_hotelinfo"
                                                        value={formData.is_hotelinfo}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value='Y'>Yes</option>
                                                        <option value='N'>No</option>

                                                    </select>
                                                </div>
                                            </div>
											</div>
											<div className="row">
											<div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="is_travelmanager_approved">Is Travel Manager Approved
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select required
                                                        name="is_travelmanager_approved"
                                                        id="is_travelmanager_approved"
                                                        value={formData.is_travelmanager_approved}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value='Y'>Yes</option>
                                                        <option value='N'>No</option>

                                                    </select>
                                                </div>
                                            </div>
											<div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="is_hotelmanager_approved">Is Hotel Manager Approved
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select required
                                                        name="is_hotelmanager_approved"
                                                        id="is_hotelmanager_approved"
                                                        value={formData.is_hotelmanager_approved}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value='Y'>Yes</option>
                                                        <option value='N'>No</option>

                                                    </select>
                                                </div>
                                            </div>
											</div>
										</div>

										<div className='box-footer'>
											<button
												type='submit'
												className='btn btn-success pull-right'>
												Submit
											</button>
										</div>
									</form>
								</div>
								</>
                                )}
							</div>
						</div>
					</section>
				</div>

				<Footer />
				<ToastContainer />
			</div>
		</div>
	);
};

export default EditEmployee;
