import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CustomToastStyles.css";
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
import { token } from "./Constants.js";
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EditState = () => {
	const { id } = useParams();
	const [formData, setFormData] = useState({
		name: "",
		status: "",
		id: id, // Use the id from params
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const validateFormData = () => {
		const { name, status, id } = formData; // Use the correct keys
		if (!name || !status || !id) {
			toast.error("All fields are required");
			return false;
		}
		return true;
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value, // Adjust to set value correctly
		}));
	};

	const handleSubmit = async e => {
		e.preventDefault();
		if (!validateFormData()) return; // Ensure validation

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach(key => {
			if (formData[key] !== "") {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		try {
			const response = await fetch(`${API_BASE_URL}editState`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			const result = await response.json();
			if (result.status) {
				toast.success("State successfully updated");
				navigate("/manage_states");
			} else {
				toast.error(
					result.message || "An error occurred while updating the state.",
				);
			}
		} catch (error) {
			console.error("Fetch error:", error);
			toast.error("Failed to submit form");
		}
	};

	useEffect(() => {
		const fetchState = async () => {
			try {
				const formData = new URLSearchParams();
				formData.append("id", id);
				const response = await fetch(`${API_BASE_URL}getStateDetails`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: formData.toString(),
				});
				const result = await response.json();
				if (result.state) {
					setFormData({
						name: result.state.name,
						status: result.state.status,
						id: id,
					});
				} else {
					setError("No state data found.");
				}
			} catch (error) {
				console.error("Fetch error:", error);
				setError("Failed to get State details");
			}
			setLoading(false);
		};
		fetchState();
	}, [id]);

	return (
		<div className='hold-transition sidebar-mini layout-fixed'>
			<div className='wrapper'>
				<Header />
				<Sidebar />
				<div className='content-wrapper'>
					<div className='content-header'>
						<div className='container-fluid'>
							<div className='row mb-2'>
								<div className='col-sm-6'>
									<h1 className='m-0'>Edit State</h1>
								</div>
								<div className='col-sm-6'>
									<ol className='breadcrumb float-sm-right'>
										<li className='breadcrumb-item'>
											<a href='#'>Home</a>
										</li>
										<li className='breadcrumb-item active'>Edit State</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<section className='col-lg-12'>
						<div
							className='card'
							style={{ borderTop: "3px solid #00c0ef" }}>
							<div className='box box-info'>
								<div className='card-header'>
									<h3 className='card-title'>Edit State</h3>
								</div>

								<div className='card-body'>
									<form onSubmit={handleSubmit}>
										<div className='box-body'>
											<div className='row'>
												<div className='col-md-4'>
													<div className='form-group'>
														<label htmlFor='name'>
															State Name<span style={{ color: "red" }}>*</span>
														</label>
														<input
															type='text'
															name='name'
															value={formData.name}
															onChange={handleChange}
															className='form-control'
															placeholder='Name'
														/>
													</div>
												</div>

												<div className='col-md-4'>
													<div className='form-group'>
														<label htmlFor='status'>
															Status<span style={{ color: "red" }}>*</span>
														</label>
														<select
															name='status'
															value={formData.status}
															onChange={handleChange}
															className='form-control'>
															<option value=''>Select Class</option>
															<option value='A'>A</option>
															<option value='I'>I</option>
														</select>
													</div>
												</div>
											</div>
										</div>

										<div className='box-footer'>
											<button
												type='submit'
												className='btn btn-success pull-right'>
												Submit
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
				</div>

				<Footer />
				<ToastContainer />
			</div>
		</div>
	);
};

export default EditState;
