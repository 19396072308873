import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth.js';
import './Manage_User.css'; // Include your CSS styles if any (Bootstrap is required for this example)
//import { BASE_URL } from "./Constants.js";
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import DatePicker from "react-datepicker";
import Pagination from './Pagination.js';
import "react-datepicker/dist/react-datepicker.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const Settlement_Audit_Reports = () => {
    const [selectedCities, setSelectedCities] = useState([]); // Store selected city IDs
    const [isOpen, setIsOpen] = useState(false);
    const [cities, setCities] = useState([]); // Object to map city ID to city name
    const [items, setItems] = useState([]);
    const [EmpCode, setEmpCode] = useState('');
    const [sortColumn, setSortColumn] = useState('SysNo');
    const [filteredItems, setFilteredItems] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]); // Cities filtered by search query
    const [allCities, setAllCities] = useState([]); // Keep original list of all cities
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [RptMgr, setRptMgr] = useState('');
    const [error, setError] = useState('');
    const [startDate, setStartDate] = useState(null);  // For date picker
    const [endDate, setEndDate] = useState(null);      // For date picker
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();
    useAuth();
    // Fetching cities from API
    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Cities data:", result); // Debugging API response

                if (result.status) {
                    const cityLookup = {};  // Object to map city ID to city name
                    result.data.forEach(city => {
                        cityLookup[city.id] = city.city_name;
                    });
                    setCities(cityLookup);  // Set the cities from API response
                    setFilteredCities(result.data); // Set filtered cities as the list of all cities
                    setAllCities(result.data); 
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');
                    setRptMgr(result.profile.report_mngr || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchItems = async (EmpCode) => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token || !EmpCode) return;

                const response = await fetch(`${API_BASE_URL}getsettlementauditreports`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    console.log("checked");
                    setItems(result.settlement);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems(EmpCode);
        }
    }, [EmpCode, navigate]);

    // Handle city checkbox change
    const handleCheckboxChange = (cityId) => {
        console.log("checked city ID:", cityId);
        setSelectedCities(prevSelectedCities => {
            if (prevSelectedCities.includes(cityId)) {
                return prevSelectedCities.filter(city => city !== cityId); // Remove the city ID
            } else {
                return [...prevSelectedCities, cityId]; // Add the city ID
            }
        });
    };

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };

    // Filter cities based on the search input
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query); // Update the search query state
    
        // Filter cities based on search query
        const filtered = allCities.filter(city =>
            city.city_name?.toLowerCase().includes(query) // Safely check if city_name exists
        );
    
        setFilteredCities(filtered); // Update the filteredCities state
    };

    const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
    };


    const handleItemsPerPageChange = e => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    // Handle the search query for settlement items
    const handleSearchChange1 = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter items based on selected cities, search query, and date range
    useEffect(() => {
        const filtered = items.filter(item => {
            // Search matching logic (case insensitive)
            const matchesSearch = 
                (item.comp_name?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
                (item.emp_code?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
                (item.date?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
                (item.trans_code?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
                (item.created_on?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
                (item.report_mngr?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
                (item.days?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
                (filteredCities.find(city => city.id === item.from_city)?.city_name?.toLowerCase().includes(searchQuery.toLowerCase()) || '') || // Check city name
                (filteredCities.find(city => city.id === item.to_city)?.city_name?.toLowerCase().includes(searchQuery.toLowerCase()) || '') || // Check city name
                (item.accounts_approved_amount?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
                (item.manager_approved_amount?.toLowerCase().includes(searchQuery.toLowerCase()) || '');
    
            // City matching logic
            const matchesCity = selectedCities.length === 0 || selectedCities.includes(item.city);
    
            // Date range matching logic
            const matchesDateRange = (
                (!startDate || new Date(item.created_on).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0)) &&
                (!endDate || new Date(item.created_on).setHours(23, 59, 59, 999) <= new Date(endDate).setHours(23, 59, 59, 999))
            );
    
            return matchesSearch && matchesCity && matchesDateRange;
        });
    
        // Sorting logic
        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });
    
        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder, startDate, endDate, selectedCities, filteredCities]);  // Ensure 'filteredCities' is a dependency
    

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);
    const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;

// Get the items for the current page
const paginatedItems = filteredItems.slice(startIndex, endIndex);

    const handleSort = (column) => {
        let sortedItems;
        if (column === 'sno') {
            // Sort by the order in which records appear, using array indices
            sortedItems = [...items].sort((a, b) => {
                const indexA = items.indexOf(a);
                const indexB = items.indexOf(b);
                return sortOrder === 'asc' ? indexA - indexB : indexB - indexA;
            });
        } else {
            // General sorting based on column value
            sortedItems = [...items].sort((a, b) => {
                if (a[column] < b[column]) return sortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setItems(sortedItems);
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            CompleteName: item.comp_name,
            Employeecode: item.emp_code,
            TransCode: item.trans_code,
            Date: item.date,
            Grade: item.grade,
            Purpose: item.purpose,
            ReportingManager: item.report_mngr,
            Days: item.days,
            TravelAmount: item.accounts_approved_amount || item.manager_approved_amount,
            ReportingManagerStatus: item.rpt_mgr_status,
            FinanceStatus: item.account_status,
            FromCity: cities[item.from_city],
            ToCity: cities[item.to_city]
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Advance');
        XLSX.writeFile(workbook, 'Settlement Reports.xlsx');
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Settlement Reports</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                        <div className="card-header">
                                            <button
                                                className="btn btn-success"
                                                style={{ float: 'right' }}
                                                onClick={exportToExcel}
                                            >
                                                Export to Excel
                                            </button>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className='form-group'>
                                                        <select
                                                            className='form-control custom-select-sm'
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}>
                                                            <option value={10}>10</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">

                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            selectsStart
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            placeholderText="Select Start Date"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">

                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={(date) => setEndDate(date)}
                                                            selectsEnd
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            minDate={startDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            placeholderText="Select End Date"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 p-0">
                                                    <div className="form-group">

                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-secondary dropdown-toggle form-control"
                                                                type="button"
                                                                id="dropdownMenuButton"
                                                                aria-expanded="false"
                                                                onClick={toggleDropdown}
                                                            >
                                                                {selectedCities.length === 0
                                                                    ? 'Select Cities'
                                                                    : `${selectedCities.length} Cities Selected`}
                                                            </button>

                                                            {isOpen && (
                                                                <div className="dropdown-menu show" style={{
                                                                    maxHeight: '300px',
                                                                    overflowY: 'auto',
                                                                    width: '100%',
                                                                    position: 'absolute',
                                                                    zIndex: 1000
                                                                }} aria-labelledby="dropdownMenuButton">
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control mb-3"
                                                                            value={searchQuery1}
                                                                            onChange={handleSearchChange}
                                                                            placeholder="Search Cities"
                                                                        />
                                                                    </div>
                                                                    {filteredCities.length > 0 ? (
                                                                        filteredCities.map((city) => (
                                                                            <div key={city.id} className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id={`city-${city.id}`}
                                                                                    value={city.id}  // Pass city ID
                                                                                    checked={selectedCities.includes(city.id)} // Compare by city ID
                                                                                    onChange={() => handleCheckboxChange(city.id)}  // Use city ID here
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`city-${city.id}`}>
                                                                                    {city.city_name}
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div>No cities found...</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">


                                                        <input
                                                            type="search"
                                                            value={searchQuery}
                                                            onChange={handleSearchChange1}
                                                            className="form-control form-control-sm"
                                                            placeholder="Search"
                                                            aria-controls="example1"
                                                        />

                                                    </div>
                                                </div>

                                            </div>

                                            {loading ? (
                                                <Loader />
                                            ) : (
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                        <th
                                                                        onClick={() => handleSort("sno")}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            backgroundColor: sortColumn === "sno" ? '#f8f9fa' : 'transparent',
                                                                            position: 'relative'
                                                                        }}
                                                                    >
                                                                        S.No {sortColumn === "sno" && (sortOrder === "asc" ? '▲' : '▼')}
                                                                        {/* Adding arrows for sorting indication */}
                                                                        <span
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                color: '#007bff',
                                                                                fontSize: '12px',
                                                                                textDecoration: 'underline',
                                                                            }}
                                                                        >

                                                                        </span>
                                                                    </th>
                                                            <th>Trans Code</th>
                                                            <th>Employee Name</th>
                                                            <th>Employee Code</th>
                                                            <th>Travel Date</th>
                                                            <th>From City</th>
                                                            <th>To City</th>
                                                            <th>Days</th>
                                                            <th>Travel Amount</th>
                                                            <th>Reporting Manager</th>
                                                            <th>Reporting Manager Status</th>
                                                           
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paginatedItems.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                <td>{item.trans_code}</td>
                                                                <td>{item.comp_name || 'Unknown'}</td>
                                                                <td>{item.emp_code}</td>
                                                                <td>{item.date}</td>
                                                                <td>{cities[item.from_city] || 'N/A'}</td>
                                                                <td>{cities[item.to_city] || 'N/A'}</td>
                                                                <td>{item.days}</td>
                                                                <td>{Math.trunc(item.audit_approved_amount)}</td>
                                                                <td>{item.report_mngr}</td>
                                                                <td style={{ color: item.rpt_mgr_status === 'Pending' ? 'orange' : item.rpt_mgr_status === 'Rejected' ? 'red' : item.rpt_mgr_status === 'Approved' ? 'green' : 'inherit' }}>
                                                                        {item.rpt_mgr_status}
                                                                    </td>
                                                                   
                                                                <td>{item.created_on}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                    <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                handlePageChange={handlePageChange}
                                            />
                                    <div className='mb-3'>
                                        Showing {startItem} to {endItem} of {filteredItems.length} entries
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Settlement_Audit_Reports;
