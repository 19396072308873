import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import './Manage_User.css';
import * as XLSX from 'xlsx';
import useAuth from './useAuth';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Manage_User = () => {
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);

    useEffect(() => {
        if (!Array.isArray(items)) return;

        const filtered = items.filter(item => {
            const cityName = item.city_name ? item.city_name.toLowerCase() : "";
            const stateName = item.state_id ? item.state_id.toLowerCase() : "";

            return (
                cityName.includes(searchQuery.toLowerCase()) ||
                stateName.includes(searchQuery.toLowerCase())
            );
        });

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1);
    }, [searchQuery, items, sortColumn, sortOrder]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            CityName: item.name
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'City_Details.xlsx');
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>City Classification</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>City Classification</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                        <div className="card-header">
                                            <span style={{ float: 'right' }}>
                                                <Link to="/add_city">
                                                    <button type="button" className="btn btn-success">Add New</button>
                                                </Link>
                                                <button type="button" className="btn btn-secondary ml-2" onClick={exportToExcel}>
                                                    Export to Excel
                                                </button>
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                <label>
                                                    <input
                                                        type="search"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        className="form-control form-control-sm"
                                                        placeholder="Search"
                                                        aria-controls="example1"
                                                    />
                                                </label>
                                            </div>
                                            
                                            <div className="form-group">
                                                <select
                                                    className="form-control custom-select-sm"
                                                    value={itemsPerPage}
                                                    onChange={handleItemsPerPageChange}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </div>

                                            <table className="table table-bordered table-striped" style={{ fontSize: '13px' }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>
                                                            <button onClick={() => handleSort('name')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                City Type Name {sortColumn === 'name' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.length > 0 ? (
                                                        currentItems.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                <td>{item.city_name}</td>
                                                                <td>
                                                                    {item.id ? (
                                                                        <Link to={`/edit_city/${item.id}`}>
                                                                            <i className="fa fa-edit" style={{ fontSize: '12px', color: 'green' }}></i>
                                                                        </Link>
                                                                    ) : (
                                                                        <button type="button" className="btn btn-secondary btn-sm" disabled>
                                                                            No ID
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="3">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <nav>
                                        <ul className="pagination" style={{ float: 'right', fontSize: '12px' }}>
                                            <li className="page-item">
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </button>
                                            </li>

                                            {currentPage > 2 && (
                                                <>
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                                                    </li>
                                                    {currentPage > 3 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                                                </>
                                            )}

                                            {[...Array(totalPages).keys()]
                                                .slice(Math.max(0, currentPage - 2), Math.min(currentPage + 1, totalPages))
                                                .map(pageNumber => (
                                                    <li
                                                        key={pageNumber}
                                                        className={`page-item ${pageNumber + 1 === currentPage ? 'active' : ''}`}
                                                    >
                                                        <button
                                                            className="page-link"
                                                            onClick={() => handlePageChange(pageNumber + 1)}
                                                        >
                                                            {pageNumber + 1}
                                                        </button>
                                                    </li>
                                                ))}

                                            {currentPage < totalPages - 1 && (
                                                <>
                                                    {currentPage < totalPages - 2 && (
                                                        <li className="page-item disabled"><span className="page-link">...</span></li>
                                                    )}
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                                            {totalPages}
                                                        </button>
                                                    </li>
                                                </>
                                            )}

                                            <li className="page-item">
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Manage_User;
