import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './useAuth.js';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
//import { BASE_URL } from "./Constants.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);
const Finance_Advance_Reports = () => {
    const [EmpCode, setEmpCode] = useState('');
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [Reportee, setReportee] = useState([]);
    const [RptMgr, setRptMgr] = useState('');
    const [items, setItems] = useState([]);
    const [cities, setCities] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [filteredCities, setFilteredCities] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [is_audit_approval, setis_audit_approval] = useState(false);
    const [is_finance_approval, setis_finance_approval] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [startDate, setStartDate] = useState(null);  // For date picker
    const [endDate, setEndDate] = useState(null);      // For date picker
    const [sortColumn, setSortColumn] = useState('SysNo');
    const [sortOrder, setSortOrder] = useState('desc');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchReporteeData = async () => {
            setLoadingEmployees(true);
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getemployee`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {

                    setTimeout(() => {
                        // Simulated data fetch
                        setReportee(result.data);  // Set the reportee data
                        setLoadingEmployees(false);
                    }, 3000); // 5 seconds delay
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Error: ' + error.message);
            }
        };
        fetchReporteeData();
    }, []);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);

                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("get all cities data:", result);

                if (result.status) {
                    const cityLookup = {};  // Object to map city ID to city name
                    result.data.forEach(city => {
                        cityLookup[city.id] = city.city_name;
                    });
                    setCities(cityLookup);   // Set the cities from API response
                    setFilteredCities(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');

                    setRptMgr(result.profile.report_mngr || '');
                    setis_audit_approval(result.profile.is_audit_approval === 'Y');
                    setis_finance_approval(result.profile.is_finance_approval === 'Y');
                    // fetchItems(result.profile.emp_code, result.profile.report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);
    useEffect(() => {
        const fetchItems = async (EmpCode) => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("RptMgr", EmpCode);
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}getadvance?accounts_manager=${encodeURIComponent(EmpCode)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    console.log("checkdata", result.advance);
                    setItems(result.advance);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };




        if (EmpCode) {
            fetchItems(EmpCode);
        }
    }, [EmpCode, navigate]);

    useEffect(() => {
        const filtered = items.filter(item => {
            // Ensure that each value is safely accessed before calling `.toLowerCase()`
            const matchesSearch = (item.comp_name?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.trans_code?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.emp_code?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.city?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.days?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.rpt_mgr_status?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.account_status?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.created_on?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.report_mngr?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.accounts_approved_amount?.toLowerCase().includes(searchQuery.toLowerCase()) || "") ||
                (item.manager_approved_amount?.toLowerCase().includes(searchQuery.toLowerCase()) || "");

            // Filter based on selected employees
            const matchesEmployee = selectedEmployee.length === 0 || selectedEmployee.includes(item.emp_code);
            const matchesCity = selectedCities.length === 0 || selectedCities.includes(item.city);

            // Date range matching logic
            const matchesDateRange = (
                (!startDate || new Date(item.created_on).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0)) &&
                (!endDate || new Date(item.created_on).setHours(23, 59, 59, 999) <= new Date(endDate).setHours(23, 59, 59, 999))
            );

            // Return the combined filter result
            return matchesSearch && matchesCity && matchesDateRange && matchesEmployee;
        });

        setFilteredItems(filtered);
        setCurrentPage(1);  // Reset to the first page when data or filters change
    }, [searchQuery, items, selectedEmployee, selectedCities, startDate, endDate]);


    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query);

        // Filter based on both comp_name and emp_code
        const filtered = Reportee.filter((employee) =>
            employee.comp_name.toLowerCase().includes(query) || // Search by comp_name
            employee.emp_code.toLowerCase().includes(query)       // Search by emp_code
        );

        setReportee(filtered);  // Update the reportee list as per the search query
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    // Handle search query changes and filter cities
    const handleSearchChange2 = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery1(query);

        const filtered = filteredCities.filter(city =>
            city.city_name?.toLowerCase().includes(query) // Safely check if city_name exists
        );
        console.log("Filtered cities:", filtered); // Debugging filtered cities
        setFilteredCities(filtered);
    };

    const handleSearchChange1 = (e) => {
        setSearchQuery(e.target.value);
    };

    // Handle checkbox changes to update the selected cities
    const handleCheckboxChange = (cityName) => {
        setSelectedCities(prevSelectedCities => {
            if (prevSelectedCities.includes(cityName)) {
                return prevSelectedCities.filter(city => city !== cityName);  // Remove if already selected
            } else {
                return [...prevSelectedCities, cityName];  // Add if not selected
            }
        });
    };

    const handleCheckboxChange1 = (emp_code) => {
        setSelectedEmployee((prevSelectedEmployee) => {
            if (prevSelectedEmployee.includes(emp_code)) {
                return prevSelectedEmployee.filter(emp => emp !== emp_code);  // Remove if already selected
            } else {
                return [...prevSelectedEmployee, emp_code];  // Add if not selected
            }
        });
    };

    const handleSort = (column) => {
        let sortedItems;
        if (column === 'sno') {
            // Sort by the order in which records appear, using array indices
            sortedItems = [...items].sort((a, b) => {
                const indexA = items.indexOf(a);
                const indexB = items.indexOf(b);
                return sortOrder === 'asc' ? indexA - indexB : indexB - indexA;
            });
        } else {
            // General sorting based on column value
            sortedItems = [...items].sort((a, b) => {
                if (a[column] < b[column]) return sortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setItems(sortedItems);
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };
    const toggleDropdown1 = () => {
        setIsOpen1(prevState => !prevState);
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            CompleteName: item.comp_name,
            Employeecode: item.emp_code,
            TransCode: item.trans_code,
            days: item.days,
            travel_advance_amount: item.travel_advance_amount,
            rm: item.report_mngr,
            ReportingManagerstatus: item.rpt_mgr_status,
            City: item.city,
            Finance_status: item.account_status,
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Advance');
        XLSX.writeFile(workbook, 'Finance_Manager_Advance_Reports.xlsx');
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Finance Advance Reports</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="/dashboard">Home</a></li>

                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Tour/Travel Advance Finance Approval Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                        <div className="card-header">

                                            <span style={{ float: 'right' }}>

                                                <button type="button" className="btn btn-success" onClick={exportToExcel}>
                                                    Export to Excel
                                                </button>
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            {loading ? (
                                                <Loader /> // Show loader if loading is true
                                            ) : (
                                                <>
                                                    <div className="row">
                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <select
                                                                    className="form-control custom-select-sm"
                                                                    value={itemsPerPage}
                                                                    onChange={handleItemsPerPageChange}
                                                                >
                                                                    <option value={10}>10</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select>
                                                            </div></div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    selectsStart
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    className="form-control"
                                                                    placeholderText='Start Date'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">

                                                                <DatePicker
                                                                    selected={endDate}
                                                                    onChange={(date) => setEndDate(date)}
                                                                    selectsEnd
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    minDate={startDate}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    className="form-control"
                                                                    placeholderText='End Date'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 p-0">
                                                            <div className="form-group">
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-secondary dropdown-toggle form-control"
                                                                        type="button"
                                                                        id="dropdownMenuButton"
                                                                        aria-expanded="false"
                                                                        onClick={toggleDropdown}
                                                                    >
                                                                        {selectedEmployee.length === 0
                                                                            ? 'Select Employees'
                                                                            : `${selectedEmployee.length} Employees Selected`}
                                                                    </button>
                                                                    {isOpen && (
                                                                        <div
                                                                            className="dropdown-menu show"
                                                                            style={{
                                                                                maxHeight: '300px',
                                                                                overflowY: 'auto',
                                                                                width: '100%',
                                                                            }}
                                                                            aria-labelledby="dropdownMenuButton"
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Search Employees"
                                                                                value={searchQuery1}
                                                                                onChange={handleSearchChange}
                                                                                style={{ marginBottom: '10px' }}
                                                                            />
                                                                            {Reportee.length > 0 ? (
                                                                                Reportee.filter(item =>
                                                                                    item.comp_name.toLowerCase().includes(searchQuery1.toLowerCase()) ||   // Search by comp_name
                                                                                    item.emp_code.toLowerCase().includes(searchQuery1.toLowerCase())       // Search by emp_code
                                                                                ).map((reportee) => (
                                                                                    <div key={reportee.emp_code} className="form-check">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id={`emp-${reportee.emp_code}`}
                                                                                            value={reportee.emp_code}
                                                                                            checked={selectedEmployee.includes(reportee.emp_code)}
                                                                                            onChange={() => handleCheckboxChange1(reportee.emp_code)}
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={`emp-${reportee.emp_code}`}>
                                                                                            {`${reportee.comp_name} (${reportee.emp_code})`}
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div>Loading employees...</div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 p-0">
                                                            <div className="form-group">
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-secondary dropdown-toggle form-control"
                                                                        type="button"
                                                                        id="dropdownMenuButton"
                                                                        aria-expanded="false"
                                                                        onClick={toggleDropdown1}
                                                                    >
                                                                        {selectedCities.length === 0
                                                                            ? 'Select Cities'
                                                                            : `${selectedCities.length} Cities Selected`}
                                                                    </button>
                                                                    {isOpen1 && (
                                                                        <div className="dropdown-menu show" style={{
                                                                            maxHeight: '300px', // Max height to show only 10 items
                                                                            overflowY: 'auto', // Enables scroll if items exceed 10
                                                                            width: '100%'
                                                                        }} aria-labelledby="dropdownMenuButton">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Search Cities"
                                                                                value={searchQuery1}
                                                                                onChange={handleSearchChange2}
                                                                                style={{ marginBottom: '10px' }}
                                                                            />
                                                                            {filteredCities.length > 0 ? (
                                                                                filteredCities.map((city) => (
                                                                                    <div key={city.id} className="form-check">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id={`city-${city.id}`}
                                                                                            value={city.city_name}
                                                                                            checked={selectedCities.includes(city.city_name)}
                                                                                            onChange={() => handleCheckboxChange(city.city_name)}
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={`city-${city.id}`}>
                                                                                            {city.city_name}
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div>Loading cities...</div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">

                                                                <label>
                                                                    <input
                                                                        type="search"
                                                                        value={searchQuery}
                                                                        onChange={handleSearchChange1}
                                                                        className="form-control form-control-sm"
                                                                        placeholder="Search"
                                                                        aria-controls="example1"
                                                                    />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-striped" style={{ fontSize: '13px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        onClick={() => handleSort("sno")}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            backgroundColor: sortColumn === "sno" ? '#f8f9fa' : 'transparent',
                                                                            position: 'relative'
                                                                        }}
                                                                    >
                                                                        S.No {sortColumn === "sno" && (sortOrder === "asc" ? '▲' : '▼')}
                                                                        {/* Adding arrows for sorting indication */}
                                                                        <span
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                color: '#007bff',
                                                                                fontSize: '12px',
                                                                                textDecoration: 'underline',
                                                                            }}
                                                                        >
                                                                        </span>
                                                                    </th>
                                                                    <th>
                                                                        Emp Code
                                                                    </th>
                                                                    <th>
                                                                        Trans Code
                                                                    </th>
                                                                    <th>
                                                                        Emp Name
                                                                    </th>

                                                                    <th>
                                                                        Advance Amount
                                                                    </th>

                                                                    <th>
                                                                        Reporting Manager
                                                                    </th>
                                                                    <th>
                                                                        Days
                                                                    </th>
                                                                    <th>
                                                                        City
                                                                    </th>
                                                                    <th>
                                                                        Reporting Manager Status
                                                                    </th>

                                                                    <th>
                                                                        Finance Status
                                                                    </th>

                                                                    <th>
                                                                        Date
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {is_finance_approval && currentItems.length > 0 ? (
                                                                    currentItems.some(item => item.rpt_mgr_status !== 'Rejected') ? (
                                                                        currentItems.map((item, index) => (
                                                                            (item.rpt_mgr_status == 'Approved') ? (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                                    <td>{item.emp_code}</td>
                                                                                    <td>{item.trans_code}</td>
                                                                                    <td>{item.comp_name}</td>
                                                                                  
                                                                                    <td>
                                                                                        {item.accounts_approved_amount ? item.accounts_approved_amount : "Need to approval by finance"}
                                                                                    </td>                                                                                <td>{item.report_mngr}</td>
                                                                                    <td>{item.days}</td>
                                                                                    <td>{item.city}</td>

                                                                                    <td style={{
                                                                                        color: item.rpt_mgr_status === 'Pending' ? 'orange' :
                                                                                            item.rpt_mgr_status === 'Rejected' ? 'red' :
                                                                                                item.rpt_mgr_status === 'Approved' ? 'green' :
                                                                                                    'inherit'
                                                                                    }}>
                                                                                        {item.rpt_mgr_status}
                                                                                    </td>

                                                                                    <td style={{ color: item.account_status === 'Pending' ? 'orange' : item.account_status === 'Rejected' ? 'red' : item.account_status === 'Approved' ? 'green' : 'inherit' }}>
                                                                                        {item.account_status}
                                                                                    </td>

                                                                                    <td>{item.created_on}</td>


                                                                                </tr>
                                                                            ) : null // Skip rendering if the conditions are not met
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td>
                                                                        </tr>
                                                                    )
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <nav>
                                        <ul className="pagination" style={{ float: 'right' }}>
                                            <li className="page-item">
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </button>
                                            </li>
                                            {[...Array(totalPages).keys()].map(pageNumber => (
                                                <li key={pageNumber} className={`page-item ${pageNumber + 1 === currentPage ? 'active' : ''}`}>
                                                    <button
                                                        className="page-link"
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                    >
                                                        {pageNumber + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className="page-item">
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="mb-3">
                                        Showing {startItem} to {endItem} of {filteredItems.length} entries
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default Finance_Advance_Reports;
