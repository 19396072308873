import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import default CSS for Toastify
import "./CustomToastStyles.css"; // Import custom styles
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
import Statelist from "./Statelist.js";

import { token } from "./Constants.js";
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AddDIEM = () => {
	const [formData, setFormData] = useState({
		diem: "",
		city_class: "",
		hotel: "",
		grade: "",
	});
	const navigate = useNavigate();
	const [statelistData, setStatelistData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");

	const validateFormData = () => {
		const { diem, city_class, hotel, grade } = formData;
		if (!diem || !city_class || !hotel || !grade) {
			toast.error("All fields are required");
			return false;
		}
		return true;
	};

	const handleChange = e => {
		const { name, value, type, files } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: type === "file" ? files[0] : value,
		}));
	};
	const handleFetchedStateListData = useCallback(data => {
		if (data.length === 0) {
			setError("No data available.");
		} else {
			setStatelistData(data);
			setError(""); // Clear any previous error
		}
		setLoading(false);
	}, []);

	const handleSubmit = async e => {
		e.preventDefault();

		if (!validateFormData()) return; // Stop submission if validation fails

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach(key => {
			if (formData[key]) {
				// Ensure that only non-empty values are added
				formDataToSubmit.append(key, formData[key]);
			}
		});

		// Log form data to ensure it is correct before submission
		for (let [key, value] of formDataToSubmit.entries()) {
			console.log(key, value);
		}

		try {
			const response = await fetch(`${API_BASE_URL}addDiem`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`, // Only include authorization header
				},
				body: formDataToSubmit,
			});

			const result = await response.json();

			if (result.status) {
				toast.success("DIEM successfully added");
				// Reset form data after success
				setFormData({
					diem: "",
					city_class: "",
					hotel: "",
					grade: "",
				});
				navigate("/manage_diem");
			} else {
				toast.error(
					result.message || "An error occurred while adding the DIEM.",
				);
			}
		} catch (error) {
			console.error("Fetch error:", error);
			toast.error("Failed to submit form");
		}
	};

	return (
		<>
			<Statelist onStatesFetched={handleFetchedStateListData} />
			<div className='hold-transition sidebar-mini layout-fixed'>
				<div className='wrapper'>
					<Header />
					<Sidebar />

					<div className='content-wrapper'>
						<div className='content-header'>
							<div className='container-fluid'>
								<div className='row mb-2'>
									<div className='col-sm-6'>
										<h1 className='m-0'>Add DIEM</h1>
									</div>
									<div className='col-sm-6'>
										<ol className='breadcrumb float-sm-right'>
											<li className='breadcrumb-item'>
												<a href='#'>Home</a>
											</li>
											<li className='breadcrumb-item active'>Add DIEM</li>
										</ol>
									</div>
								</div>
							</div>
						</div>

						<section className='col-lg-12'>
							<div
								className='card'
								style={{ borderTop: "3px solid #00c0ef" }}>
								<div className='box box-info'>
									<div className='card-header'>
										<h3 className='card-title'>Add DIEM</h3>
									</div>

									<div className='card-body'>
										<form
											onSubmit={handleSubmit}
											method='post'
											acceptCharset='utf-8'>
											<div className='box-body'>
												<div className='row'>
													<div className='col-md-3'>
														<div className='form-group'>
															<label htmlFor='name'>
																City Class
																<span style={{ color: "red" }}>*</span>
															</label>
															<select
																name='city_class'
																value={formData.city_class}
																onChange={handleChange}
																className='form-control'>
																<option value=''> Select Class </option>
																<option value='A'> A </option>
																<option value='B'> B </option>
																<option value='C'> C </option>
																<option value='D'> D </option>
															</select>
														</div>
													</div>

													<div className='col-md-3'>
														<div className='form-group'>
															<label htmlFor='name'>
																BAND
																<span style={{ color: "red" }}>*</span>
															</label>
															<select
																name='grade'
																value={formData.grade}
																onChange={handleChange}
																className='form-control'>
																<option value=''> Select Band</option>
																<option value='A'> A </option>
																<option value='B'> B </option>
																<option value='C'> C </option>
																<option value='D'> D </option>
																<option value='E'> E </option>
																<option value='F'> F </option>
															</select>
														</div>
													</div>
													<div className='col-md-3'>
														<div className='form-group'>
															<label htmlFor='name'>
																DIEM<span style={{ color: "red" }}>*</span>
															</label>
															<input
																type='text'
																name='diem'
																value={formData.diem}
																onChange={handleChange}
																className='form-control'
																placeholder='DIEM'
															/>
														</div>
													</div>
													<div className='col-md-3'>
														<div className='form-group'>
															<label htmlFor='name'>
																Hotel<span style={{ color: "red" }}>*</span>
															</label>
															<input
																type='text'
																name='hotel'
																value={formData.hotel}
																onChange={handleChange}
																className='form-control'
																placeholder='Hotel / Lodge Charges'
															/>
														</div>
													</div>
												</div>
											</div>

											<div className='box-footer'>
												<button
													type='submit'
													className='btn btn-success pull-right'>
													Submit
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</section>
					</div>

					<Footer />

					<aside className='control-sidebar control-sidebar-dark'></aside>
				</div>

				{/* Toast Container */}
				<ToastContainer />
			</div>
		</>
	);
};

export default AddDIEM;
