import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CustomStyles1.css"; // Import custom styles for this page
//import { BASE_URL } from "./Constants.js"; // Import BASE_URL constant
import MD5 from "crypto-js/md5";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ChangePassword1 = () => {
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();
  const [Password, setPassword] = useState("");

  // Form validation function
  const validateFormData = () => {
    const { current_password, new_password, confirm_password } = formData;
    if (!current_password || !new_password || !confirm_password) {
      toast.error("All fields are required");
      return false;
    }
    if (MD5(current_password).toString() !== Password) {
      toast.error("Current Password and old password not matching");
      return false;
    }
    if (new_password.length < 8 || confirm_password.length < 8) {
      toast.error("Passwords must be at least 8 characters long");
      return false;
    }
    if (new_password.length !== confirm_password.length) {
      toast.error("New password and confirm password must be the same length");
      return false;
    }
    if (new_password !== confirm_password) {
      toast.error("New password and confirm password do not match");
      return false;
    }
    if (/vdc/i.test(new_password) || /vijaya/i.test(new_password)) {
      toast.error('Password should not contain "vdc" or "vijaya"');
      return false;
    }

    // Regular Expression for Password validation:
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    if (!passwordRegex.test(new_password)) {
      toast.error(
        "Password must contain at least one uppercase letter, one lowercase letter, and one special character"
      );
      return false;
    }
    return true;
  };

  // Fetch profile data to pre-populate the form
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(`${API_BASE_URL}profile`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        if (result.status) {
          setFormData({
            emp_code: result.profile.emp_code || "",
            mail_id: result.profile.mail_id || "",
            password: "", // Do not display the password
          });
          setPassword(result.profile.password);
        } else {
          setResponseMessage("Error: " + result.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setResponseMessage("Error: " + error.message);
      }
    };

    fetchProfileData(); // Call the function to fetch profile data
  }, []);

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(`${API_BASE_URL}logout`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();
      if (result.status) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("exp");
        navigate("/");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFormData()) return;

    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && formData[key] !== "") {
        formDataToSubmit.append(key, formData[key]);
      }
    });

    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(`${API_BASE_URL}changePassword`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSubmit,
      });

      const result = await response.json();

      if (result.status) {
        toast.success("Password successfully changed");
        setFormData({
          current_password: "",
          new_password: "",
          confirm_password: "",
        });

        handleLogout();
      } else {
        toast.error(
          result.messages.error || "An error occurred while changing the password."
        );
      }
    } catch (error) {
      console.error("Fetch error:", error);
      toast.error("Failed to submit form");
    }
  };

  return (
    <div className="changepassword1-page">
      <div className="change-password-container">
        <div className="form-card">
          <h3 className="form-title">Change Password</h3>
          <form onSubmit={handleSubmit} method="post">
            <div className="form-group">
              <label htmlFor="current_password" className="label">
                Current Password <span className="required">*</span>
              </label>
              <input
                type="password"
                name="current_password"
                id="current_password"
                value={formData.current_password}
                onChange={handleChange}
                className="input-field"
                placeholder="Enter your current password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="new_password" className="label">
                New Password <span className="required">*</span>
              </label>
              <input
                type="password"
                name="new_password"
                id="new_password"
                value={formData.new_password}
                onChange={handleChange}
                className="input-field"
                placeholder="Enter your new password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm_password" className="label">
                Confirm Password <span className="required">*</span>
              </label>
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                className="input-field"
                placeholder="Confirm your new password"
              />
            </div>
            <div className="form-footer">
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChangePassword1;
