import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Modal from './Modal.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import Pagination from './Pagination.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const Logged_InUsers = () => {
    // State and effect hooks
    const [items, setItems] = useState([]);
    const [selectedEmpCode, setSelectedEmpCode] = useState('');
    const [formData, setFormData] = useState({
        emp_code: ''
    });
    const [filteredItems, setFilteredItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('SysNo');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}logged_in_users`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log(result);
                if (result.status) {
                    console.log(result.data);
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);

    useEffect(() => {
        const filtered = items.filter(item =>
            (item.bmid?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.user_name?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.ip_address?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.logged_in_time?.toLowerCase().includes(searchQuery.toLowerCase()))
        );

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            EmployeeCode: item.bmid || 'N/A',
            FullName: item.user_name || 'N/A',
            Email: item.user_name || 'N/A', // Update as needed if you have email info
            IPAddress: item.ip_address || 'N/A',
            LoginTime: item.logged_in_time || 'N/A'
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'Logged_In_Users.xlsx');
    };

    const handleResetPassword = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}reset_password/${selectedEmpCode}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status) {
                alert('Password reset successfully!');
            } else {
                alert('Error: ' + result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            alert('Failed to reset password');
        } finally {
            setIsModalOpen(false); // Close the modal
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Logged In Users</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item active">Logged In Users</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader /> // Show loader if loading is true
                    ) : (
                        <>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <section className="col-lg-12 connectedSortable">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Logged In Users</h3>
                                                    <span style={{ float: 'right' }}>
                                                        <button type="button" className="btn btn-secondary ml-2" onClick={exportToExcel}>
                                                            Export to Excel
                                                        </button>
                                                    </span>
                                                </div>
                                                <div className="card-body">
                                                    <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                        <label>
                                                            <input
                                                                type="search"
                                                                value={searchQuery}
                                                                onChange={handleSearchChange}
                                                                className="form-control form-control-sm"
                                                                placeholder="Search"
                                                                aria-controls="example1"
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th onClick={() => handleSort('bmid')}>BMID</th>
                                                                    <th onClick={() => handleSort('user_name')}>Full Name</th>
                                                                    <th onClick={() => handleSort('ip_address')}>IP Address</th>
                                                                    <th onClick={() => handleSort('logged_in_time')}>Login Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {currentItems.map(item => (
                                                                    <tr key={item.id}>
                                                                        <td>{item.bmid || 'N/A'}</td>
                                                                        <td>{item.user_name || 'N/A'}</td>
                                                                        <td>{item.ip_address || 'N/A'}</td>
                                                                        <td>{item.logged_in_time || 'N/A'}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                        <Pagination
                                                            currentPage={currentPage}
                                                            totalPages={totalPages}
                                                            onPageChange={handlePageChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Logged_InUsers;
